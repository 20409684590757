<script>
    import Btn from '../../../components/Btn.svelte'
    import ImageEditor from '../components/ImageEditor.svelte'
    import { mediaLibrary } from '../stores/media-library.store.js'
    import { onMount } from 'svelte'
    import { isNull } from '../../../utils/site-editor.js'
    import { v4 as uuid } from 'uuid'

    export let content = null;

    export let properties;
    export let label = false;
    export let minimal = false

    let id = uuid()

    let imageEditor = false

    $: content = isNull(content, {
        file: {
            media: null,
            alt: null
        }
    })

    $: properties = isNull(properties, {})    
    $: setSelectedMedia($mediaLibrary.selected, $mediaLibrary.attached)
    $: preview = updatePreview(content.file) 
	
	function updatePreview () {
        if (content.file.media === null) return
        return content.file.media.url       
    }

    function openMediaLibrary () {
        mediaLibrary.setMaxSelection(1)
        mediaLibrary.open(id, content.file.media !== null ? [content.file.media] : undefined, 'file')
    }

    function setSelectedMedia (selected, attached) {
        if (attached === false) return
        if ($mediaLibrary.attaching !== id) return         
        if (selected[0] === undefined) return
        
        content.file.media = selected[0]
        content.file.alt = selected[0].name
    }

    function clear() {
        content = null
    }
</script>


{#if label !== false}
    <div class="label">
        <label>
            {label}
        </label>    
    </div>
{/if}


<div class="banner" class:minimal={minimal}>
    {#if content.file.media === null}
    <div class="find">
        <Btn block={minimal} green on:click={openMediaLibrary}>
            Select File
        </Btn>
    </div>
    {:else}
    {#if minimal == false}
    <div class="thumbnail">
        <img src={preview} alt={content.file.alt} />
    </div>
    {/if}
    <div class="info">
        <div class="name">
            {content.file.media.name}
        </div>

        {#if minimal === false}
        <div class="clear" on:click={clear}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 14 4 C 13.476563 4 12.941406 4.183594 12.5625 4.5625 C 12.183594 4.941406 12 5.476563 12 6 L 12 7 L 5 7 L 5 9 L 6.09375 9 L 8 27.09375 L 8.09375 28 L 23.90625 28 L 24 27.09375 L 25.90625 9 L 27 9 L 27 7 L 20 7 L 20 6 C 20 5.476563 19.816406 4.941406 19.4375 4.5625 C 19.058594 4.183594 18.523438 4 18 4 Z M 14 6 L 18 6 L 18 7 L 14 7 Z M 8.125 9 L 23.875 9 L 22.09375 26 L 9.90625 26 Z M 12 12 L 12 23 L 14 23 L 14 12 Z M 15 12 L 15 23 L 17 23 L 17 12 Z M 18 12 L 18 23 L 20 23 L 20 12 Z"/></svg>
        </div>
        {/if}
        
        {#if minimal === false}
        <div class="metas">
            {#each content.file.media.meta as item}
                <div class="meta">
                    {item.label}: {item.value}
                </div>
            {/each}
        </div>
        {/if}

        <div class="actions">
            {#if minimal === false}
            <Btn green on:click={openMediaLibrary}>
                Replace
            </Btn>
            {:else}
            <Btn block red on:click={clear}>
                Remove
            </Btn>
            {/if}
        </div>
    </div>
    {/if}
</div>

<style>
.label {
    font-size: 17px;     
    color: #2c3e50;
    margin-bottom: 0.25em;   
}

.banner {
    display: grid;
    grid-column-gap: 15px;
    grid-template-columns: 150px auto;
}

.banner.minimal {
    grid-template-columns: auto;
}

.thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f6f6;
    border: 1px solid #ddd;
}

.thumbnail img {
    max-width: 100%;
    max-height: 150px;
}

.info {
    display: grid;
    grid-template-columns: auto 30px;
    grid-template-rows: fit-content(1em) auto fit-content(1em);
}

.name {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 10px;
}

.clear {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    cursor: pointer;
}

.clear svg {
    width: auto;
}

.clear svg path {
    fill: #ccc;
    transition: 150ms all ease-in;
}

.clear:hover svg path {
    fill: #f44336;
}

.metas {
    grid-column: 1 / 3;
    font-size: 16px;
    color: #666;
    font-weight: 300;
    margin-bottom:  10px;
}

.actions {
    grid-column: 1 / 3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
}

.banner.minimal .info {
    grid-template-columns: auto;
    grid-template-rows: auto;
}

.banner.minimal .name {
    font-size: 16px;
}

.banner.minimal .name,
.banner.minimal .clear,
.banner.minimal .meta,
.banner.minimal .actions {
    grid-column: auto;
    grid-row: auto;
}

.banner.minimal .actions {
    grid-template-columns: auto;
}</style>