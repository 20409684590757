<script>
    import Avatar from './Avatar.svelte'
    import { user } from '../stores/user.store'
    import { clickOutside } from '../utils/click-outside.js'
    import { goto } from "@sveltech/routify";

    $: name = $user !== null ? $user.first_name + ' ' + $user.last_name : ''

    let open = false

</script>
<div class="user-menu">
    <Avatar name={name} on:click={() => open = !open } />
    {#if open}
        <div class="popout" use:clickOutside on:clickOutside={() => open = false}>
            <div class="item">
                <div on:click|preventDefault={() => $goto('/account')}>
                    Account
                </div>
            </div> 
            <div class="item">
                <div on:click|preventDefault={() => $goto('/logout')}>
                    Logout
                </div>
            </div>
            <div class="item disabled">
              <div>Build: {{"build":"140","isProd":true,"ROUTIFY_API":"https://dashboard.ocomputers.co.uk/api/v1/","ROUTIFY_ENVIROMENT":"production","ROUTIFY_BUILD":"$CODEBUILD_BUILD_NUMBER"}.build}</div>
            </div> 
        </div>    
    {/if}
    
</div>

<style>
.user-menu {
  position: relative;
}

.popout {
  position: absolute;
  top: calc(100% - 10px);
  right: 5px;
  background: #FFF;
  border: 1px solid #ddd;
  padding: 10px 15px;
  display: grid;
  grid-row-gap: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #737373;
  cursor: pointer;
}

.popout .item {
  white-space: nowrap;
}

.popout .item:hover {
  color: #fd7e14;
}

.popout a {
  color: #737373;
}

.popout a:hover {
  color: #fd7e14;
}

.popout .item.disabled,
.popout .item.disabled:hover {
  color: #aaa;
  cursor: initial;
}</style>