<script>
    import { parse, parseISO, format, formatISO, formatRelative, formatDistance, getDaysInMonth, isEqual, toDate, isBefore, isAfter, isToday, startOfMonth, startOfWeek, endOfWeek, addDays, addMonths, addYears, subDays, subMonths, subYears, isSameDay } from 'date-fns'
    import { onMount } from 'svelte'
    import { scale } from 'svelte/transition'
    import { sineIn } from 'svelte/easing'
    import { clickOutside } from '../utils/click-outside.js'

    export let value
    export let label = false
    export let relative = false
    export let distance = false
    export let dateFormat = 'HH:mm:ss dd/MM/yyyy'
    export let min = false
    export let max = false
    export let readonly = false    
    export let placeholder = false

    let today = new Date()
    let picker = false
    let pickerDate
    let pickerBrowsingDate
    let mode = 'month'

    $: defaultText = placeholder === false ? 'Select a date' : placeholder

    $: notBefore = min === false ? false : toDate(min)
    $: notAfter = max === false ? false : toDate(max)

    $: date = value === null ? today : parseISO(value)
    $: formattedDate = formatDate(date)
    $: monthDates = pickerBrowsingDate === undefined ? [] : calcMonthDates(pickerBrowsingDate)

    
    function calcMonthDates () {
        let dates = []
        
        let current = startOfMonth(pickerBrowsingDate)
        let start = startOfWeek(current)        
        
        while (isBefore(start, current)) {
            dates.push({
                dateObject: start,
                date: format(start, 'd'),
                today: isToday(start),                
                disabled: isDisabled(start),
                lastMonth: true,
                nextMonth: false
            })

            start = addDays(start, 1)
        }

        for (let i = 1; i <= getDaysInMonth(pickerBrowsingDate); i++) {
            dates.push({
                dateObject: current,
                date: i,
                today: isToday(current),
                disabled: isDisabled(current),
                lastMonth: false,
                nextMonth: false
            })
            current = addDays(current, 1)
        }

        let end = endOfWeek(subDays(current, 1))

        while (isBefore(current, end)) {
            dates.push({
                dateObject: current,
                date: format(current, 'd'),
                today: isToday(current),
                disabled: isDisabled(current),
                lastMonth: false,
                nextMonth: true
            })

            current = addDays(current, 1)
        }

        return dates
    }

    function isDisabled (date) {
        if (min === false && max === false) return false
        if (min !== false && isBefore(date, notBefore) && isToday(date) === false) return true
        if (max !== false && isAfter(date, notAfter) && isToday(date)) return true
        return false
    }

    function formatDate () {
        if (value === null) {
            return null
        } else if (relative === true) {
            return formatRelative(date, today)
        } else if (distance === true) {            
            return formatDistance(date, today)
        } else {
            return format(toDate(date), dateFormat)
        }
    }    
    
    function open() {
        if (readonly === true) return
        picker = true
        pickerDate = date
        pickerBrowsingDate = date
    }    

    function prev () {
        if (mode === 'months') {
            pickerBrowsingDate = subYears(pickerBrowsingDate, 1)
        } else {
            pickerBrowsingDate = subMonths(pickerBrowsingDate, 1)
        }
    }

    function next () {
        if (mode === 'months') {
            pickerBrowsingDate = addYears(pickerBrowsingDate, 1)
        } else {
            pickerBrowsingDate = addMonths(pickerBrowsingDate, 1)
        }
    }

    function selectDate (date) {
        if (date.disabled === true) return        
        pickerDate = date.dateObject
        close()
    }

    function close () {
        value = formatISO(pickerDate)
        picker = false
    }
</script>

<div class="date-picker">
    <div class="display" class:readonly on:click={open}>
        <slot></slot>
        {#if label !== false}{label}:{/if}
        {formattedDate === null ? defaultText : formattedDate} 
    </div>
    {#if picker === true}
        <div use:clickOutside on:clickOutside={close} class="popout" transition:scale|local={{duration: 200, delay: 0, opacity: 0.5, start: 0.5, easing: sineIn}}>
            <div class="header">
                <div class="previous" on:click={prev}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 19.03125 4.28125 L 8.03125 15.28125 L 7.34375 16 L 8.03125 16.71875 L 19.03125 27.71875 L 20.46875 26.28125 L 10.1875 16 L 20.46875 5.71875 Z"/></svg>
                </div>

                <div class="month-year">
                    <div class="month" on:click={() => mode='months'}>
                        {format(pickerBrowsingDate, 'MMMM')}
                    </div>

                    <div class="year">
                        {format(pickerBrowsingDate, 'yyyy')}
                    </div>
                </div>

                <div class="next" on:click={next}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 12.96875 4.28125 L 11.53125 5.71875 L 21.8125 16 L 11.53125 26.28125 L 12.96875 27.71875 L 23.96875 16.71875 L 24.65625 16 L 23.96875 15.28125 Z"/></svg>
                </div>
            </div>

            <div class="calendar">
                {#each ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] as day}
                <div class="cell day">
                    { day }
                </div>
                {/each}
                {#each monthDates as item}
                <div on:click={() => selectDate(item) } class="cell date" class:disabled={item.disabled} class:selected={isSameDay(item.dateObject, pickerDate)} class:today={item.today} class:last-month={item.lastMonth} class:next-month={item.nextMonth}>
                    { item.date }
                </div>
                {/each}
            </div>
        </div>    
    {/if}    
</div>

<style>
.date-picker {
    position: relative;
}

.display {
    cursor: pointer;
}

.display.readonly {
    cursor: initial;
}

.popout {
    position: absolute;
    top: -150px;
    right: 0;
    background: #FFF;
    border: 1px solid #ddd;
    width: auto;
    padding: 15px;
    z-index: 10000;
}

.popout .header {
    display: grid;
    grid-template-columns: 4ch auto 4ch;
    align-items: center;
    margin-bottom: 30px;
}

.popout .header .month-year {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 300;
    line-height: 1;
}

.popout .header .month-year .month {
    padding-right: 10px;
}

/* .popout .header .month-year .month,
.popout .header .month-year .year {
    transition: all 150ms ease-in;
    cursor: pointer;
}

.popout .header .month-year .month:hover, 
.popout .header .month-year .year:hover {
    color: #ee7b00;
} */

.popout .header .next,
.popout .header .previous {
    display: flex;
    align-items: center;
    cursor: pointer;      
}

.popout .header .next svg, 
.popout .header .previous svg {
    flex-grow: 1;
}

.popout .header .next svg path,
.popout .header .previous svg path{
    transition: all 150ms ease-in;
    fill: #a9a9a9; 
    cursor: pointer;  
}

.popout .header .next:hover svg path,
.popout .header .previous:hover svg path{
    fill: #ee7b00;   
}

.popout .calendar {
    display: grid;
    grid-template-columns: repeat(7, 4ch);
    grid-column-gap: 10px;
    grid-row-gap: 10px
}

.popout .calendar .cell {
    text-align: center;
    width: 4ch;
    font-size: 16px;
    font-weight: 300;    
}

.popout .calendar .cell.day {
    font-weight: 400;
}

.popout .calendar .cell.date {
    display: flex;    
    height: 4ch;
    align-items: center;
    justify-content: center;
    background: #FFF;
    border-radius: 100%;
    border: 1px solid transparent;
    transition: all 150ms ease-in;
    cursor: pointer;
}

.popout .calendar .cell.date.today {
    border-radius: 100%;
    border: 1px solid #ee7b00;
}

.popout .calendar .cell.date:hover {
    border-radius: 100%;
    background: #ddd;
}

.popout .calendar .cell.date.disabled {
    cursor: not-allowed;
    color: #a9a9a9;
    text-decoration: line-through;
}

.popout .calendar .cell.date.last-month,
.popout .calendar .cell.date.next-month {
    color: #a9a9a9
}

.popout .calendar .cell.date.selected {
    background: #ee7b00;
    color: #FFF;
}</style>