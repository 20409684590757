<script>
    import { onMount } from 'svelte'

    export let content = null;    
</script>

<textarea bind:value={content} rows="10"></textarea>
<style>
textarea {
    width: 100%;
}</style>