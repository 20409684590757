<script>
    import { isActive, url, params, page, layout, goto } from "@sveltech/routify";
    import { onMount } from 'svelte'
    import { fly, slide } from 'svelte/transition';
    import { sineOut } from 'svelte/easing';
    import { tweened } from 'svelte/motion';
    import UserMenu from '../components/UserMenu.svelte'
    import ApiService from '../services/api.service.js'
    import { modules } from '../stores/app.store.js'
    import LoaderIcon from '../components/LoaderIcon.svelte'
    import SiteSelector from '../components/SiteSelector.svelte'
    import MediaLibrary from '../modules/site-editor/components/MediaLibrary.svelte'
    import { mediaLibrary } from '../modules/site-editor/stores/media-library.store.js'
    import { user } from '../stores/user.store'

    let viewportWidth
    let showSidebar = true
    let sidebar
    let sidebarWidth
    let body
    let logo
    let topbarHeight		
    let content
    let sidebarStylesWidth    
    let duration = 350
    let offestLeft = tweened(0, {
      duration,
      easing: sineOut
    })
    let sidebarLeft = tweened(0, {
      duration,
      easing: sineOut
    })
    let opened = []

    $: {
        if ($user === null && $page.path !== '/login/index') {
            $goto('/login')
        } else if ($user !== null && ['/login/index', '/index'].includes($page.path)) {
            $goto('/dashboard')
        }
        
    }
    
    $: resize(viewportWidth)
    $: routeChange($page)

    $: menu = [
        {
            route: 'dashboard',
            name: 'Home',
            sections: []            
        }, 
        ...$modules.menu
    ]
        
    $: if (sidebarWidth !== undefined && sidebar !== undefined && sidebar !== null) {        
        let styles = getComputedStyle(sidebar)
        sidebarStylesWidth = parseInt(styles['border-left-width']) + parseInt(styles['border-right-width']) + sidebarWidth
    }

    $: topbarHeight !== undefined && logo !== undefined ? logo.style.height = topbarHeight + 'px' : undefined

    $: if (sidebarWidth !== undefined) {
        offestLeft.set(showSidebar ? sidebarStylesWidth : 0)        
        sidebarLeft.set(showSidebar ? 0 : 0 - sidebarStylesWidth)  
    }

    $: sidebar !== undefined ? sidebar.style.left = $sidebarLeft + 'px' : undefined
    $: body !== undefined ? body.style['margin-left'] = $offestLeft + 'px' : 0

    onMount(() => {
        resize()
    })

    function resize (viewportWidth) {
        if (viewportWidth < 768) {
            showSidebar = false
        }
    }
    
    function routeChange () {
        if (viewportWidth < 768) {
            showSidebar = false
        }
    }

    function expand(item) {
      if (opened.includes(item)) {
        opened = opened.filter(i => i !== item)
      } else {
        opened = [
          ...opened,
          item
        ]
      }
    }
</script>

<svelte:window bind:innerWidth={viewportWidth}/>

<div id="sidebar" class:open={showSidebar} bind:this={sidebar} bind:clientWidth={sidebarWidth}>
    <div class="logo" bind:this={logo}>
        Dashboard
    </div>

    <div class="menu" style="height: calc(100vh - {topbarHeight}px);">        
        {#if $modules.loaded === false}
            <div class="loader">
                <LoaderIcon />
            </div>     
        {:else}
            {#each menu as item}
                <div class="item" class:active={$isActive('/' + item.route)}>
                  <a href={$url('/' + item.route)}>
                    {item.name}
                  </a>
                </div>
                {#if $isActive('/' + item.route) && item.sections && item.sections.length > 0}
                    <div class="sub-menu">
                        {#each item.sections as section}
                            <div class="item" transition:slide class:active={$isActive('/' + item.route + '/' + section.slug)}>   
                                {#if section.children && section.children.length > 0}
                                <a href="/{item.route}/{section.slug}">
                                  {section.label}
                                </a>
                                <div class="expander" on:click={() => expand('/' + item.route + '/' + section.slug)}>
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z"/></svg>
                                </div>
                                {:else}
                                <a href="/{item.route}/{section.slug}">
                                  {section.label}
                                </a>  
                                {/if}
                            </div>
                            {#if section.children && section.children.length > 0 && ($isActive('/' + item.route + '/' + section.slug) || opened.includes('/' + item.route + '/' + section.slug))}
                            <div class="children-menu">
                              {#each section.children as child}
                              <div class="item" transition:slide class:active={$isActive('/' + item.route + '/' + section.slug + '/' + child.slug)}>
                                <a href="/{item.route}/{section.slug}/{child.slug}">
                                  {child.label}
                                </a>
                              </div>
                              {/each}
                            </div>
                            {/if}
                        {/each}
                    </div>
                {/if}
            {/each}
        {/if}    
    </div>
</div>
<div id="body" bind:this={body}>
    <div class="topbar" bind:clientHeight={topbarHeight}>        
        <div class="menu-toggle" on:click={() => showSidebar = !showSidebar} title="{showSidebar ? 'Hide' : 'Show'} Menu">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 4 7 L 4 9 L 28 9 L 28 7 L 4 7 z M 4 15 L 4 17 L 28 17 L 28 15 L 4 15 z M 4 23 L 4 25 L 28 25 L 28 23 L 4 23 z"/></svg>            
        </div>

        <SiteSelector />
        <div class="spacer"></div>
        <UserMenu />
    </div>
    <div id="content" bind:this={content}>
        <slot></slot>
    </div>
</div>

{#if $mediaLibrary.open}
    <MediaLibrary on:close={mediaLibrary.close} />    
{/if}

<style>
#sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  background: #58595b;
  width: 220px;
  color: #FFF;
}

#sidebar .logo {
  padding: 20px;
  box-sizing: border-box;
}

#sidebar .menu {
  display: grid;
  grid-row-gap: 0;
  overflow: auto;
  align-content: flex-start;
}

#sidebar .menu .item {
  display: flex;
  align-items: center;
  column-gap: 7px;
  transition: all 150ms ease-in;
  cursor: pointer;
  border-right: 5px solid transparent;
}

#sidebar .menu .item a,
#sidebar .menu .item .name {
  flex-grow: 1;
  color: #FFF;
  display: block;
  padding: 9px 20px;
}

#sidebar .menu .item:hover a,
#sidebar .menu .item:hover .name {
  color: #fd7e14;  
}

#sidebar .menu .item:hover, #sidebar .menu .item.active {
  background: #FFF;
  border-right: 5px solid #fd7e14;
}

#sidebar .menu .item:hover a, #sidebar .menu .item.active a,
#sidebar .menu .item:hover .name, #sidebar .menu .item.active .name {
  color: #fd7e14;
}

#sidebar .menu .item:hover .expander svg path, #sidebar .menu .item.active .expander svg path {
  fill: #fd7e14;
}

#sidebar .menu .sub-menu {
  display: grid;
  grid-row-gap: 0;
}

#sidebar .menu .sub-menu .item {
  padding-left: 10px;
}

#sidebar .menu .sub-menu .item:hover, #sidebar .menu .sub-menu .item.active {
  background: #fd7e14;
  border-right: 5px solid #000;
}

#sidebar .menu .sub-menu .item.active .expander svg path {
  fill: #000;
}

#sidebar .menu .sub-menu .item:hover {
  border-right-color: #FFF;
}

#sidebar .menu .sub-menu .item:hover a, #sidebar .menu .sub-menu .item.active a {
  color: #FFF;
}

#sidebar .menu .sub-menu .item:hover .expander svg path {
  fill: #FFF;
}

#sidebar .menu .children-menu {
  display: grid;
  grid-row-gap: 0;
}

#sidebar .menu .children-menu .item {
  padding-left: 20px;
}

#sidebar .menu .children-menu .item.active {
  background: #000;
}

#sidebar .menu .expander.open {
  transform: rotate(180deg);
}

#sidebar .menu .expander svg {
  height: 1.2em;
  width: auto;
  padding-right: 5px;
}

#sidebar .menu .expander svg path {
  transition: all 150ms ease-in;
  fill: #FFF;
}

#sidebar .menu .loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

#sidebar.open {
  display: block;
}

#sidebar .build {
  position: absolute;
  top: auto;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 15px 20px 5px 20px;
  color: #ddd;
}

#body {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}

#body #content {
  padding: 30px 40px;
  flex-grow: 1;
  flex-shrink: 1;
  background: #f9f9f9;
}

#body .topbar {
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 10000;
}

.spacer {
  flex-grow: 1;
}

.menu-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
}

.menu-toggle svg {
  height: 25px;
  width: 25px;
}

.menu-toggle svg path {
  transition: all 150ms ease-in-out;
  fill: #0e0e0e;
}

.menu-toggle:hover svg path {
  fill: #fd7e14;
}</style>