<script>
    import Btn from '../../../components/Btn.svelte'
    import Card from '../../../components/Card.svelte'    
    import TextInput from '../../../components/form/TextInput.svelte'
    import ApiService from '../../../services/api.service.js'
    import { validator, required, minLength } from '../../../validators'
    import TemplateSelector from '../../../modules/site-editor/components/TemplateSelector.svelte'
    import { slide } from 'svelte/transition'
    import DatePicker from '../../../components/DatePicker.svelte'
    import { url, goto } from "@sveltech/routify";
    import Modal from '../../../components/Modal.svelte'
    import LoaderIcon from '../../../components/LoaderIcon.svelte'
    import Preview from '../../../modules/site-editor/components/Preview.svelte'
    import Image from '../../../modules/site-editor/blocks/Image.svelte'
    import File from '../../../modules/site-editor/blocks/File.svelte'
    import ContentSection from '../../../modules/site-editor/blocks/ContentSection.svelte'
    import LinkedModel from '../../../modules/site-editor/blocks/LinkedModel.svelte'
    import { site } from '../../../stores/sites.store.js'
    import Slug from '../../../modules/site-editor/components/Slug.svelte'

    export let id

    let model = false    
    let previous = JSON.stringify(false)
    let now = new Date()
    let saving = false
    let previewing = false
    let deleteing = false
    let restoring = false
    let unpublishing = false
    let deleteModal = false
    let previewModal = false
    let previewUrl = false
    let nameTouched = false
    let rules = {
        name: [
            { 
                rule: required
            },
            {
                rule: minLength,
                params: {
                    length: 2
                }
            }
        ]
    }

    let errorMessages = {
        required: 'This is a required field',
        minLength: 'This field must be longer than %length%'
    }

    let blockErrors = []

    $: create = id === 'add' || id === 'new'
    $: getModel(id)
    $: unsaved = previous != JSON.stringify(toSave(model))    
    $: loading = model === false || model === undefined
    $: nameErrors = validator(model.name, rules.name, errorMessages)
    $: isValid = nameErrors === false
    $: checkBlocks(model.template)
    $: disabled = saving === true || deleteing === true || restoring === true || unpublishing === true || blockErrors.length > 0    
    $: slugPrefix = 'cable-range' + (model.parent === null || model.parent === undefined ? '' : '/' + model.parent.fullslug)
    function checkBlocks() {
        if (model === false) return
        let errors = []
        for (let block of model.blocks) {
            if (model.template.blocks.find(b => b.id === block.block_id) === undefined) {
                errors.push(block.block_id)
            }
        }
        blockErrors = [
            ...errors
        ]
    }

    async function getModel(id) {
        if (id === undefined) return        
        let url = 'site-editor/cable-ranges/' + (create === true ? 'create' : id)

        try {
            let { data } = await ApiService.get(url)

            model = {
                ...data,
                properties: data.properties === null || data.properties.length === 0 ? {} : data.properties
            }

            setPrevious(model)
        } catch (error) {
            console.error(error)
        }
    }

    function toSave (object) {
        if (typeof object !== 'object') return object
        return {
            parent: object.parent === null ? null : object.parent.id,
            template: object.template.id,
            name: object.name,
            publish_at: object.published_at,
            properties: object.properties,
            linked: object.linked,
            slug: object.slug,
            blocks: object.blocks
        }
    }

    async function save() {
        if (disabled === true) return
        if (saving === true) return

        if (isValid === false) {
            forceValidation()        
            return
        }

        if (unpublishing !== true) saving = true

        let url = 'site-editor/cable-ranges'
        if (create === false) url += '/' + id

        try {            
            let response = await ApiService.post(url, toSave(model))
            
            model = response.data
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            saving = false
        }
    }

    async function unpublish() {
        if (disabled === true) return
        unpublishing = true
        model.published_at = null
        await save()        
        unpublishing = false
    }

    async function bin() {
        if (disabled === true) return
        if (deleteing === true) return
        deleteing = true

        try {            
            let response = await ApiService.delete('site-editor/cable-ranges' + '/' + id)
            
            model = response.data
            deleteModal = false
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            deleteing = false            
        }
    }

    async function restore() {
        if (disabled === true) return
        if (restoring === true) return
        restoring = true

        try {            
            let response = await ApiService.get('site-editor/cable-ranges' + '/' + id + '/restore')
            
            model = response.data
            
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            restoring = false            
        }
    }

    async function preview() {
        if (disabled === true) return
        if (previewing === true) return
        previewing = true

        try {
            let { data } = await ApiService.post('site-editor/preview', {
                'name': model.name,
                'blocks': model.blocks,
                'properties': model.properties,
                'linked': model.linked
            })

            previewModal = true
            previewUrl = data.site + '/cable-range/' + model.slug + '/?preview=' + data.uuid
        } catch {

        } finally {
            previewing = false
        }
    }

    function forceValidation () {
        nameTouched = true
    }

    function reset(id) {
        if (create === true) {
            $goto($url('../:id', { id }))
        }
        nameErrors = false
        nameTouched = false
        setPrevious(model)
    }

    function setPrevious(current) {
        setTimeout (() => {
            previous = JSON.stringify(toSave(current))
        }, 250)
    }

    function updateFilterOption (id, option) {
        let index = model.linked.filters.findIndex(f => f.id === id)
        if (index === -1) return
        if (model.linked.filters[index] === undefined) return
        if (model.linked.filters[index].selected.includes(option)) {
            model.linked.filters[index].selected = model.linked.filters[index].selected.filter(o => o !== option)
        } else {
            model.linked.filters[index].selected = [
                ...model.linked.filters[index].selected,
                option
            ]
        }
    }
</script>
<div class="grid">

    <div class="main-col">
        <div class="content-card">
            <Card title="Basic Information" loading={loading === true}>                     
                <div class="form">
                    <div class="form-group">
                        <TextInput label="Title" name="name" bind:value={model.name} errors={nameErrors} touched={nameTouched} />
                    </div>  

                    <div class="form-group">
                        <Slug bind:slug={model.slug} url={$site.domain} prefix={slugPrefix} />
                    </div> 

                    {#each model.template.properties as item, key}          
                    <div class="form-group">   
                        <label>{item.label}</label>                 
                        {#if item.component === 'Image'}                            
                            <Image bind:content={model.properties[item.name]} />
                        {:else if item.component === 'File'}
                            <File bind:content={model.properties[item.name]} />
                        {:else if item.component === 'ContentSection'}
                            <ContentSection bind:content={model.properties[item.name]} options={item.options} />
                        {:else}
                            <div>Unsupported type</div>
                        {/if}
                    </div>   
                    {/each}
                </div>    
            </Card>
        </div>

        <div class="content-card">
            <Card title="Sub Cable Ranges" loading={loading === true}>
                <LinkedModel 
                    bind:selected={model.linked.ranges} 
                    unselected="No cable ranges selected" 
                    placeholder="Search for cable ranges"
                    nameLabel="fullname"
                    model="cable-ranges"
                    exclude={[model.id]}
                />
            </Card>
        </div>

        <div class="content-card">
            <Card title="Cables" loading={loading === true}>
                <LinkedModel 
                    bind:selected={model.linked.cables} 
                    unselected="No cables selected" 
                    placeholder="Search for cables"
                    model="cables"
                    ordered
                />
            </Card>
        </div>

        <div class="content-card filters">
            <Card title="Filters" loading={loading === true}>
                <LinkedModel 
                    bind:selected={model.linked.filters} 
                    unselected="No filters selected" 
                    placeholder="Search for filters"
                    model="filters"
                    ordered
                    let:item let:nameLabel let:remove
                >
                    <div class="dragger">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 3.59375 L 15.28125 4.28125 L 7.28125 12.28125 L 5.59375 14 L 26.40625 14 L 24.71875 12.28125 L 16.71875 4.28125 Z M 16 6.4375 L 21.5625 12 L 10.4375 12 Z M 5.59375 18 L 7.28125 19.71875 L 15.28125 27.71875 L 16 28.40625 L 16.71875 27.71875 L 24.71875 19.71875 L 26.40625 18 Z M 10.4375 20 L 21.5625 20 L 16 25.5625 Z"/></svg>
                    </div>

                    <div class="name">
                        {item[nameLabel]}
                    </div>

                    <div class="options">
                        {#if item.type !== 'boolean'}
                            {#each item.options as option}
                                <div class="option" on:click={() => updateFilterOption(item.id, option.id)}>
                                    <div class="box">
                                        {#if item.selected.includes(option.id)}
                                        <svg class="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
                                        {/if}
                                    </div>  
                                    <div class="value">
                                        {option.value}
                                    </div>                            
                                </div>
                            {/each}
                        {/if}
                    </div>

                    <div class="remove" on:click={() => remove(item)}>                
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>                
                    </div>
                </LinkedModel>
            </Card>
        </div>

        <div class="content-card">
            <Card title="Related Links" loading={loading === true}>                
                <LinkedModel 
                    bind:selected={model.linked.related_links} 
                    unselected="No related links" 
                    placeholder="Search for links"
                    model="links"                    
                    ordered
                />    
            </Card>
        </div>
    </div>

    <div class="sidebar">
        <div class="sidebar-card">           
            <div class="actions">          
                <Btn block blue on:click={preview} {disabled}>
                    Preview
                </Btn> 

                <Btn block green on:click={save} {disabled} loading={saving === true}>
                    Save
                </Btn>   
            </div>     
        </div>

        

        <div class="sidebar-card">
            <Card loading={loading === true}>
                <div class="form">       
                    {#if model.created_at !== null}
                    <div class="form-group">
                        <DatePicker label="Created" readonly value={model.created_at} />                           
                    </div>
                    {/if}
                    
                    {#if model.updated_at !== null}
                    <div class="form-group">
                        <DatePicker label="Updated" readonly value={model.updated_at} />
                    </div> 
                    {/if}  

                    <div class="form-group">
                        Status: {model.status === null ? 'Unsaved Draft' : model.status.value}
                    </div>    
                    
                    {#if model.status !== null}
                    <div class="form-group">
                        <DatePicker label={model.status && model.status.key === 'published' ? 'Published on' : 'Publish on'} bind:value={model.published_at} min={now} dateFormat="dd/MM/yyyy" />
                    </div>                     
                    {/if}

                    {#if model.deleted_at !== null}
                    <div class="form-group">
                        <DatePicker label="Deleted" readonly value={model.deleted_at} />
                    </div> 
                    {/if}  

                    
                </div>
                <div class="actions" slot="actions">                    
                    {#if model.status && model.status.key === 'published'}         
                    <Btn block orange on:click={unpublish} {disabled} loading={unpublishing}>
                        Unpublish 
                    </Btn>
                    {/if} 
                    {#if model.status !== null && model.deleted_at === null}
                    <Btn block red on:click={() => deleteModal = true} {disabled}>
                        Delete
                    </Btn> 
                    {/if}
                    {#if model.status !== null && model.deleted_at !== null}
                    <Btn block orange on:click={restore} {disabled} loading={restoring}>
                        Restore
                    </Btn> 
                    {/if}
                </div>
            </Card>
        </div>  

        <div class="sidebar-card">           
            <Card title="Parent" loading={loading === true}>
                <LinkedModel 
                    bind:selected={model.parent} 
                    unselected="No parent" 
                    placeholder="Search for parent"
                    model="cable-ranges"
                    nameLabel="fullname"
                    limit={1}
                    exclude={model.id === null ? [] : [model.id]}
                />
            </Card>     
        </div>

        <div class="sidebar-card">           
            <Card title="Template" loading={loading === true}>
                <TemplateSelector templates={model.templates} bind:template={model.template} />
            </Card>     
        </div>

        <div class="sidebar-card">
            <Card title="Approvals" loading={loading === true}>
                <LinkedModel 
                    bind:selected={model.linked.approvals} 
                    unselected="No approvals selected" 
                    placeholder="Search for approvals"
                    model="approvals"
                    ordered
                />
            </Card>
        </div>
    </div>    
</div>

{#if unsaved === true}
    <div class="sidebar-card warning unsaved" transition:slide|local={{delay: 200}}>
        <Card>
            You have unsaved changed
        </Card>            
    </div>
{/if}

{#if deleteModal === true}
    <Modal>
        <Card title="Delete Range">
            <div class="delete-modal">
                {#if loading === false}
                <div class="message">
                    Are you sure you want to delete this range?
                </div>
                {:else}
                    <LoaderIcon />
                {/if}
            </div>
            <div class="actions" slot="actions">
                <Btn block red on:click={() => deleteModal = false}>
                    No
                </Btn> 
                <Btn block green on:click={bin}>
                    Yes
                </Btn> 
            </div>
        </Card>
    </Modal>
{/if}

{#if previewModal === true}
    <Preview url={previewUrl} loading={previewing} on:close={() => previewModal = false } />
{/if}
<style>
.grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 40px;
    align-items: flex-start;
}

.main-col {
    display: grid;
    grid-row-gap: 40px;
    padding-bottom: 40px;
}

.content-card,
.sidebar-card {
    border: 1px solid #ddd;    
}

.sidebar-card {
    margin-bottom: 40px;
}

.actions {
    display: flex;
}

.actions .btn {
    width: 50%;
}

.form {
    display: grid;
    grid-row-gap: 20px;
}

.sidebar-card.warning {
    border-color: #ee7b00;
}

.sidebar-card.warning :global(.card .body) {    
    background: #ee7b00!important;
    color: #FFF;
}

.unsaved {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    text-align: center;
    z-index: 1;
}

.message {
    font-size: 18px;
    font-weight: 300;    
}

.delete-modal {
    display: flex;
    min-height: 6em;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.delete-modal .message {    
    padding: 0 20px;
}

label {
    font-size: 17px;
    color: #2c3e50;
    margin-bottom: 0.25em;
}

.filters :global(.linked-model .selected .item) {
    grid-template-columns: fit-content(2em) 20ch auto fit-content(2em)!important;
    align-items: center;
    row-gap: 15px;
}

.filters :global(.linked-model .selected) {
    row-gap: 20px;
}

.filters .box {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
}

.filters .options {
    display: flex;
    row-gap: 7px;
    grid-auto-flow: column;
    justify-items: flex-start;
    justify-content: flex-start;
    column-gap: 25px;
    flex-wrap: wrap;
}

.filters .option {
    display: grid;
    grid-template-columns: 20px auto;
    column-gap: 5px;
    cursor: pointer;
    align-items: center;
}</style>