<script>
    import TextInput from '../../../components/form/TextInput.svelte'
    import CheckboxInput from '../../../components/form/CheckboxInput.svelte'
    
    export let content = null;
    export let properties = {};

    $: content = isNull(content, {
        showLabel: true,
        required: false,
        label: '',
        placeholder: ''
    })

    $: properties = isNull(properties, {})

    let hasLabel = false

    function isNull (current, fallback) {
        return current === null ? fallback : current
    }
</script>

<div class="grid">    
    <div class="row">
        <div class="col">
            <TextInput label="Label" bind:value={content.label} noValidation />
        </div>        
        <div class="col">
            <TextInput label="Placeholder" bind:value={content.placeholder} noValidation />
        </div>        
    </div>
    <div class="row">
        <div class="col">
            <CheckboxInput label="Show Label" bind:value={content.showLabel} />
        </div>
        <div class="col">
            <CheckboxInput label="Required" bind:value={content.required} />
        </div>
    </div>
    <div class="row">
        <div class="col">
            <CheckboxInput label="Use as reply to?" bind:value={content.replyTo} />
        </div>
    </div>
</div>

<style>
.grid {
    display: grid;
    row-gap: 15px;
}

.row {
    display: grid;
    row-gap: 15px;
    column-gap: 30px;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
}</style>