<script>
  import { createEventDispatcher } from "svelte";

  export let multiple = false;

  const dispatch = createEventDispatcher();

  let fileSelector;
  let hovered = false;

  function droppedFiles(event) {
    event.preventDefault();

    if (event.dataTransfer === undefined) return

    dispatch('selected', [...event.dataTransfer.files])
  }

  function fileSelect(event) {
    if (fileSelector.files.length === 0) return
        
    dispatch('selected', [...fileSelector.files])
  }
</script>

<div
  class="dropzone"
  class:hovered
  on:dragenter|preventDefault={() => (hovered = true)}
  on:dragover|preventDefault={() => (hovered = true)}
  on:dragleave|preventDefault={() => (hovered = false)}
  on:drop|preventDefault={droppedFiles}
  on:mouseenter={() => (hovered = true)}
  on:mouseleave={() => (hovered = false)}
  on:click={() => fileSelector.click()}>
  <input
    bind:this={fileSelector}
    type="file"
    on:input={fileSelect}
    {multiple}
    style="visibility:hidden; position: absolute;" />
  <slot />
</div>

<style>
.dropzone {
  cursor: pointer;
  border: 2px dashed transparent;
  transition: all 150ms ease-in;
  border-radius: 40px;
}

.dropzone.hovered {
  background: rgba(255, 255, 255, 0.4);
  border: 2px dashed #8c8c8c;
}</style>