<script>
    import { onMount, onDestroy } from 'svelte'
    import UploadFile from './UploadFile.svelte'
    import ApiService from '../../../services/api.service.js'
    import Btn from '../../../components/Btn.svelte'

    export let allowedTypes = [ 'image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'application/pdf', 'image/svg+xml' ]
    export let acceptedTypes = [ 'jpg', 'jpeg', 'png', 'webp', 'pdf', 'svg' ]
    
    let fileSelector

    let hovered = false
    let files = []
    let addMore = false
    
    $: processed = files.length > 0 && addMore === false

    onMount(() => {
        ApiService.mountManager()
    })

    onDestroy(() => {
        ApiService.unmountManager()
    })

    function fileSelect (event) {
        if (fileSelector.files.length === 0) return
        addMore = false
        process([...fileSelector.files])
    }

    function droppedFiles (event) {
        event.preventDefault()
        if (event.dataTransfer === undefined) return

        process([...event.dataTransfer.files])

        hovered = false
    }

    function process (fileList) {        
        for (let file of fileList) {
            if(files.find(f => f.file.name === file.name && f.file.lastModified === file.lastModified && f.file.size === file.size) !== undefined) continue

            let rejected = allowedTypes.includes(file.type) === false
            let newFile = {
                name: file.name,
                progress: 0,
                uploading: false,
                completed: false,
                conflict: false,
                file: file,
                rejected: allowedTypes.includes(file.type) ?  false : 'accepted file types ' + acceptedTypes.join(', ')
            }
                        
            files = [
                ...files,
                newFile
            ]

            if (rejected === false) {
                upload(files.findIndex(f => f === newFile))
            }
        }
    }

    async function upload (index, replace = false) {
        let file = files[index]        
        let formData = new FormData()
        let type = 'media'
        
        if (['application/pdf'].includes(file.file.type) === true) {
            type = 'file'
            file.type = 'file'
        }

        formData.append('type', type)
        formData.append('upload', file.file)

        if (replace) {
            formData.append('replace', 'yes')
        }

        files[index].completed = false
        files[index].progress = 0

        try {
            let response = await ApiService.post('site-editor/media', formData, {
                onUploadProgress: (progressEvent) => {                
                    files[index].uploading = true
                    files[index].progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                }
            })     

            files[index].completed = true
            files[index].progress = 100

            if (response.data.status === 'conflict') {
                files[index].conflict = response.data.status
            }
        } catch (error) {       
            if (error.response && error.response.status === 409) {
                files[index].conflict = {
                    status: error.response.data.status,
                    message: error.response.data.message,
                    media: error.response.data.resource
                }
            } else {
                console.error(error)
            }
        } finally {
            files[index].uploading = false
        }   
    }

    async function replaceFile (index) {
        upload(index, true)
    }
</script>

<div class="wrapper">
    {#if processed === false}
    <div class="upload">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"/></svg>								

        <div class="message">
            Click to add or drag files here to upload.
        </div>

        <div class="accepted message">
            Accepted file types {acceptedTypes.join(', ')}
        </div>

        <input bind:this={fileSelector} type="file" on:input={fileSelect} files multiple style="visibility:hidden" />
    </div>

    <div 
        class="dropzone" 
        class:hovered={hovered} 
        on:dragenter|preventDefault={() => hovered = true} 
        on:dragover|preventDefault={() => hovered = true} 
        on:dragleave|preventDefault={() => hovered = false} 
        on:drop|preventDefault={droppedFiles}
        on:click={() => fileSelector.click()}
    ></div>
    {/if}

    {#if processed}
        <div class="files">
            {#each files as file, index}
            <UploadFile 
                name={file.name} 
                type={file.type}
                file={file.file} 
                rejected={file.rejected} 
                uploading={file.uploading}
                completed={file.completed}
                progress={file.progress}
                conflict={file.conflict}
                on:replaceFile={() => replaceFile(index)}
            />
            {/each}
        </div>

        <div class="addMore">
            <Btn blue on:click={() => addMore = true}>
                Upload more
            </Btn>
        </div>
    {/if}    
</div>

<style>
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    flex-wrap: wrap;
}

.upload {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 100px;
}

.upload svg {
    width: 300px;
    height: auto;
}

.upload svg path {
    fill: rgba(0,0,0,0.54);
}

.message {
    font-size: 22px;
    color: #8c8c8c;
    font-weight: 300;
    margin-bottom: 7px;
}

.message.accepted {
    font-size: 18px;
}

.dropzone {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
}

.dropzone.hovered {
    background: rgba(255,255,255,0.4);
}

.files {
    display: grid;
    width: 100%;
    grid-row-gap: 15px;
    padding: 15px;
    align-self: flex-start;
}

.addMore {
    width: 100%;
    display: flex;
    justify-content: center;
}</style>