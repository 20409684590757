<script>
    import { onMount, createEventDispatcher } from 'svelte'
    import Sortable from 'sortablejs'

    export let items
    export let empty = 'No items'

    const dispatch = createEventDispatcher()

    let el
    let sorter
    
    function reorder (old) {
        let state = []
        let order = 1
        for (let item of old) {
            item.order = order
            state.push(item)
            order++
        }
        return state
    }

    onMount(() => {
        sorter = new Sortable(el, {
			draggable: '.item',
			handle: '.dragger',
			store: {
				/**
				 * Get the order of elements. Called once during initialization.
				 * @param   {Sortable}  sortable
				 * @returns {Array}
				 */
				get: function (sortable) {					
					return reorder(items);
				},

				/**
				 * Save the order of elements. Called onEnd (when the item is dropped).
				 * @param {Sortable}  sortable
				 */
				set: function (sortable) {					
                    let state = []
                    let item
                                        
					for (let key of sortable.toArray()) {						
                        state.push(items.find(i => i.id === key))                    
                    }
					
					items = reorder(state)
				}
			}
		})
    })
</script>

<div class="items" bind:this={el}>
    {#each items as item}
    <div class="item" data-id={item.id}>
        <div class="dragger">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 3.59375 L 15.28125 4.28125 L 7.28125 12.28125 L 5.59375 14 L 26.40625 14 L 24.71875 12.28125 L 16.71875 4.28125 Z M 16 6.4375 L 21.5625 12 L 10.4375 12 Z M 5.59375 18 L 7.28125 19.71875 L 15.28125 27.71875 L 16 28.40625 L 16.71875 27.71875 L 24.71875 19.71875 L 26.40625 18 Z M 10.4375 20 L 21.5625 20 L 16 25.5625 Z"/></svg>
        </div>
        <div class="name">
            {item.name}
        </div>
        <div class="remove" on:click={() => dispatch('remove', item)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>                
        </div>
    </div>
    {:else}
        {empty}
    {/each}
</div>

<style>
.items {
    display: grid;
    row-gap: 15px;
}

.items .item {
    display: grid;
    column-gap: 15px;
    row-gap: 15px;
    grid-template-columns: 1em auto 1em;
    align-items: center;
}

.items .item svg {
    width: 1em;
    height: auto;
}

.items .item .dragger {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: move;
}

.items .item .remove {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.items .item .remove svg path {
    transition: 150ms all ease-in;
    fill: #cccccc;
}

.items .item .remove:hover svg path {
    fill: #dc3545;
}</style>