<script>
    import LinkedModel from './LinkedModel.svelte'
    import NumberInput from '../../../components/form/NumberInput.svelte'
    import SelectInput from '../../../components/form/SelectInput.svelte'

    export let content = [];
    export let properties = {};

    $: content = isNull(content, [])
    $: properties = isNull(properties, {})

    function isNull (current, fallback) {
        return current === null ? fallback : current
    }
</script>

<div class="grid">
    <div class="show">
        <NumberInput label="Slides to show" bind:value={properties.show} type="number" />        
    </div>

    <div class="autoplay">
        <SelectInput label="Autoplay" bind:value={properties.autoplay} full choices={[{ name: 'No', value: 'no' }, { name: 'Yes', value: 'yes' }]} />        
    </div>

    <div class="speed">
        <NumberInput label="Speed" bind:value={properties.speed} type="number" />        
    </div>
    
    <div class="linked">
        <LinkedModel 
            bind:selected={content} 
            unselected="No items selected" 
            placeholder="Search for items"
            model="links"                    
            ordered
        />
    </div>
</div>

<style>
.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
}

.linked {
    grid-column: 1 / 4;
}</style>