<script>
    import Btn from '../../../components/Btn.svelte'
    import Card from '../../../components/Card.svelte'    
    import TextInput from '../../../components/form/TextInput.svelte'
    import TextAreaInput from '../../../components/form/TextAreaInput.svelte'
    import MarkdownEditor from '../../../components/form/MarkdownEditor.svelte'
    import ApiService from '../../../services/api.service.js'
    import { validator, required, minLength } from '../../../validators'
    import PageBuilder from '../../../modules/site-editor/components/PageBuilder.svelte'
    import TemplateSelector from '../../../modules/site-editor/components/TemplateSelector.svelte'
    import { slide } from 'svelte/transition'
    import DatePicker from '../../../components/DatePicker.svelte'
    import { url, goto } from "@sveltech/routify";
    import { isAfter } from 'date-fns'
    import Modal from '../../../components/Modal.svelte'
    import LoaderIcon from '../../../components/LoaderIcon.svelte'
    import Preview from '../../../modules/site-editor/components/Preview.svelte'
    import LinkedModel from '../../../modules/site-editor/blocks/LinkedModel.svelte'
    import { site } from '../../../stores/sites.store.js'
    import { titleCase, pluralise } from '../../../utils/helpers.js'
    import FeatureTable from '../../../modules/site-membership/components/FeatureTable.svelte'
    import { formatISO } from 'date-fns'
    import { v4 as uuid } from 'uuid'
    import Slug from '../../../modules/site-editor/components/Slug.svelte'

    export let id

    const title = 'plan'
    const apiRoute = 'site-membership/plans'

    let model = false    
    let previous = JSON.stringify(false)
    let now = new Date()
    let saving = false
    let previewing = false
    let deleteing = false
    let restoring = false
    let publishing = false
    let unpublishing = false
    let sending = false
    let deleteModal = false
    let previewModal = false
    let previewUrl = false
    let nameTouched = false
    let contentTouched = false
    let topicTouched = false
    let rules = {
        name: [
            { 
                rule: required
            },
            {
                rule: minLength,
                params: {
                    length: 2
                }
            }
        ]
    }

    let blockErrors = []

    let errorMessages = {
        required: 'This is a required field',
        minLength: 'This field must be longer than %length%'
    }

    $: create = id === 'add' || id === 'new'
    $: getModel(id)
    $: unsaved = previous != JSON.stringify(toSave(model))    
    $: loading = model === false || model === undefined
    $: nameErrors = validator(model.name, rules.name, errorMessages)    
    $: isValid = nameErrors === false
    $: disabled =  saving === true || deleteing === true || restoring === true || unpublishing === true || sending === true || publishing === true

    $: updateFeatures(model && model.linked && model.linked.sub ? model.linked.subscription_plans : undefined)

    function checkBlocks() {
        if (cable === false) return
        if (cable.template.row !== null) return
        let errors = []
        for (let block of cable.blocks) {
            if (cable.template.blocks.find(b => b.id === block.block_id) === undefined) {
                errors.push(block.block_id)
            }
        }
        blockErrors = [
            ...errors
        ]
    }

    function updateFeatures() {
        if (model === undefined || model.linked === undefined || model.linked.subscription_plans === undefined ) return
        for (let sub_plan of model.linked.subscription_plans) {
            if (model.features.columns.map(c => c.name).includes(sub_plan.id) === false) {
                model.features.columns = [
                    ...model.features.columns,
                    {
                        name: sub_plan.id,
                        label: sub_plan.name,
                        order: sub_plan.order
                    }
                ]
            }
        }

        let ids = model.linked.subscription_plans.map(p => p.id)

        model.features.columns = model.features.columns.filter(c => ids.includes(c.name) && c.label !== 'Feature' || c.label === 'Feature')
    }

    async function getModel(id) {
        if (id === undefined) return        
        let url = apiRoute + '/' + (create === true ? 'create' : id)

        try {
            let { data } = await ApiService.get(url)

            if (data.features === null || data.features.length === 0) {
                data.features = {
                    columns: [
                        {
                            name: uuid(),
                            label: 'Feature'
                        }
                    ],
                    rows: []
                }
            }

            model = data

            setPrevious(model)
        } catch (error) {
            console.error(error)
        }
    }

    function toSave (object) {
        if (object === undefined) return undefined
        if (typeof object !== 'object') return object
        return {
            name: object.name,
            slug: object.slug,
            info: object.info,
            publish_at: object.published_at,
            linked: object.linked,
            features: object.features,
            blocks: object.blocks,
            template: object.template.id
        }
    }

    async function save() {
        if (saving === true) return

        if (disabled === true) return

        if (isValid === false) {
            forceValidation()        
            return
        }

        if (unpublishing !== true) saving = true

        let url = apiRoute
        if (create === false) url += '/' + id

        try {            
            let response = await ApiService.post(url, toSave(model))
            
            model = response.data
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            saving = false
        }
    }

    async function unpublish() {
        unpublishing = true
        model.published_at = null
        await save()        
        unpublishing = false
    }

    async function publish() {
        publishing = true
        model.published_at = formatISO(now)
        await save()        
        publishing = false
    }

    async function bin() {
        if (disabled === true) return
        if (deleteing === true) return
        deleteing = true

        try {            
            let response = await ApiService.delete(apiRoute + '/' + id)
            
            model = response.data
            deleteModal = false
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            deleteing = false            
        }
    }

    async function restore() {
        if (disabled === true) return
        if (restoring === true) return
        restoring = true

        try {            
            let response = await ApiService.get(apiRoute + '/' + id + '/restore')
            
            model = response.data
            
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            restoring = false            
        }
    }

    function forceValidation () {
        nameTouched = true
        topicTouched = true
        contentTouched = true
    }

    function reset(id) {
        if (create === true) {
            $goto($url('../:id', { id }))
        }
        
        nameTouched = false
        topicTouched = false
        contentTouched = false
        setPrevious(model)
    }

    function setPrevious(current) {
        setTimeout (() => {
            previous = JSON.stringify(toSave(current))
        }, 250)
    }

    function updatePrice (id, price) {
        let item = model.linked.subscription_plans.find(p => p.id === id)
        if (item === undefined) return

        item.price = event.target.value
    }
</script>
<div class="grid">

    <div class="main-col">
        <div class="content-card">
            <Card title="Basic Information" loading={loading === true}>                     
                <div class="form">
                    <div class="form-group">
                        <TextInput label="Name" name="name" bind:value={model.name} errors={nameErrors} touched={nameTouched} />
                    </div>    

                    <div class="form-group">
                        <Slug bind:slug={model.slug} prefix="signup" url={$site.domain} />
                    </div>   
                </div>    
            </Card>            
        </div>     

        <div class="content-card sub-plans">
            <Card title="Subscription Plans" loading={loading === true}>
                <LinkedModel 
                    bind:selected={model.linked.subscription_plans} 
                    unselected="No subscription plans selected" 
                    placeholder="Search for subscription plans"
                    model="subscription-plans"
                    moduleRoute="site-membership"
                    ordered
                    let:item let:nameLabel let:remove
                >                    
                    <div class="dragger">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 3.59375 L 15.28125 4.28125 L 7.28125 12.28125 L 5.59375 14 L 26.40625 14 L 24.71875 12.28125 L 16.71875 4.28125 Z M 16 6.4375 L 21.5625 12 L 10.4375 12 Z M 5.59375 18 L 7.28125 19.71875 L 15.28125 27.71875 L 16 28.40625 L 16.71875 27.71875 L 24.71875 19.71875 L 26.40625 18 Z M 10.4375 20 L 21.5625 20 L 16 25.5625 Z"/></svg>
                    </div>
                    <div class="name">
                        {item[nameLabel]}
                    </div>

                    <div class="price">
                        <input on:input={(event) => updatePrice(item.id, event)} placeholder="price" type="number" value={item.price} />
                    </div>

                    <div class="remove" on:click={() => remove(item)}>                
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>                
                    </div>
                </LinkedModel>
            </Card>
        </div>   

        <div class="content-card">
            <Card title="Features" loading={loading === true}>
                <FeatureTable bind:columns={model.features.columns} bind:rows={model.features.rows} />                
            </Card>
        </div>

        <div class="content-card">
            <Card title="Success Page" loading={loading === true}>
                <PageBuilder 
                    row={model.template.row_block_id} 
                    title={model.title} 
                    errors={blockErrors} 
                    blockTypes={model.template.blocks} 
                    bind:blocks={model.blocks} 
                />                
            </Card>
        </div>
    </div>

    <div class="sidebar">        
        <div class="sidebar-card">                       
            <div class="actions">                 
                <Btn block green on:click={save} {disabled} loading={saving === true}>
                    Save
                </Btn>  
            </div>     
        </div>        

        <div class="sidebar-card">
            <Card loading={loading === true}>
                <div class="form">       
                    {#if model.created_at !== null}
                    <div class="form-group">
                        <DatePicker label="Created" readonly value={model.created_at} />                           
                    </div>
                    {/if}
                    
                    {#if model.updated_at !== null}
                    <div class="form-group">
                        <DatePicker label="Updated" readonly value={model.updated_at} />
                    </div> 
                    {/if}  

                    <div class="form-group">
                        Status: {model.status === null ? 'Unsaved Draft' : model.status.value}
                    </div>    

                    {#if model.status !== null}
                    <div class="form-group">
                        <DatePicker label={model.status && model.status.key === 'published' ? 'Published on' : 'Publish on'} bind:value={model.published_at} min={now} dateFormat="dd/MM/yyyy" />
                    </div>                     
                    {/if}
                    
                    {#if model.deleted_at !== null}
                    <div class="form-group">
                        <DatePicker label="Deleted" readonly value={model.deleted_at} />
                    </div> 
                    {/if}                        
                </div>
                <div class="actions" slot="actions">                                        
                    {#if model.status && ['published', 'scheduled'].includes(model.status.key) === true}         
                    <Btn block orange on:click={unpublish} {disabled} loading={unpublishing}>
                        Un-{model.status.key === 'published' ? 'publish' : 'schedule'}
                    </Btn>
                    {:else if model.published_at === null}
                        <Btn block green on:click={publish} {disabled} loading={publishing}>
                            Publish Now 
                        </Btn>
                    {/if} 
                    {#if model.status !== null && model.deleted_at === null}
                    <Btn block red on:click={() => deleteModal = true} {disabled}>
                        Delete
                    </Btn> 
                    {/if}
                    {#if model.status !== null && model.deleted_at !== null}
                    <Btn block orange on:click={restore} {disabled} loading={restoring}>
                        Restore
                    </Btn> 
                    {/if}
                </div>
            </Card>
        </div>  
    </div>    
</div>

{#if unsaved === true}
    <div class="sidebar-card warning unsaved" transition:slide|local={{delay: 200}}>
        <Card>
            You have unsaved changed
        </Card>            
    </div>
{/if}

{#if deleteModal === true}
    <Modal>
        <Card title="Delete {titleCase(title)}">
            <div class="delete-modal">
                {#if loading === false}
                <div class="message">
                    Are you sure you want to delete this {title}?
                </div>
                {:else}
                    <LoaderIcon />
                {/if}
            </div>
            <div class="actions" slot="actions">
                <Btn block red on:click={() => deleteModal = false}>
                    No
                </Btn> 
                <Btn block green on:click={bin}>
                    Yes
                </Btn> 
            </div>
        </Card>
    </Modal>
{/if}

{#if previewModal === true}
    <Preview url={previewUrl} loading={previewing} on:close={() => previewModal = false } />
{/if}
<style>
.grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 40px;
    align-items: flex-start;
}

.main-col {
    display: grid;
    grid-row-gap: 40px;
    padding-bottom: 40px;
}

.content-card,
.sidebar-card {
    border: 1px solid #ddd;    
}

.sidebar-card {
    margin-bottom: 40px;
}

.actions {
    display: flex;
}

.actions .btn {
    width: 50%;
}

.form {
    display: grid;
    grid-row-gap: 20px;
}

.sidebar-card.warning {
    border-color: #ee7b00;
}

.sidebar-card.warning :global(.card .body) {    
    background: #ee7b00!important;
    color: #FFF;
}

.unsaved {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    text-align: center;
    z-index: 1;
}

.message {
    font-size: 18px;
    font-weight: 300;    
}

.delete-modal {
    display: flex;
    min-height: 6em;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.delete-modal .message {    
    padding: 0 20px;
}

.sub-plans :global(.linked-model .selected .item) {
    grid-template-columns: fit-content(2em) auto fit-content(10ch) fit-content(2em)!important;
    align-items: center;
}</style>