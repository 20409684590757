<script>
    export let disabled = false
    export let block = false
    export let green = false
    export let blue = false
    export let warning = false
    export let red = false
    export let orange = false
    export let yellow = false

    import LoaderIcon from './LoaderIcon.svelte'
</script>

<div class="pill" class:yellow={yellow} class:red={red} class:orange={orange} class:warning={warning} class:green={green} class:blue={blue} class:block={block} class:disabled={disabled}>
    <slot></slot>
</div>

<style>
.pill {
    background: #58595b;
    color: #FFF;
    padding: 5px 10px;
    line-height: 1;
    border-radius: 1.1em;
    display: inline-flex;
    justify-content: center;
    font-size: 16px;
    line-height: 1;
    box-sizing: border-box;
}

.pill.disabled {
    opacity: 0.65;
    cursor: not-allowed;
}

.pill.green {
    background: #4CAF50;        
}

.pill.blue {
    background: #2196f3;
}

.pill.red {
    background: #f44336;
}

.pill.warning {
    background: #ffa72d;
}

.pill.orange {
    background: #ee7b00;        
}

.pill.yellow {
    background: #ffeb3b;
    color: #58595b;
}</style>