<script>
    import Btn from './Btn.svelte'
    import Pill from './Pill.svelte'
    import Pagination from './Pagination.svelte'
    import LoaderIcon from './LoaderIcon.svelte'    
    import { url } from "@sveltech/routify";

    export let loading
    export let pagination
    export let filters
    export let records
    export let statuses = [ { key: null, value: 'All' }, { key: 'deleted', value: 'Deleted' } ]
    export let paginationShow = 9
</script>

<div class="crud">
    {#if loading === true}
        <div class="loader">
            <LoaderIcon />
        </div>
    {/if}
    <div class="toolbar">
        <div class="statuses">
            {#each statuses as item}
                <div class="status" on:click={() => filters.status = item.key} class:active={filters.status === item.key}>
                    {item.value}
                </div>
            {/each}
        </div>
        <div class="spacer"></div>
        {#if pagination !== false}
            <Pagination first={pagination.first_page} last={pagination.last_page} current={pagination.current} show={paginationShow} let:pages={items} let:current>
                <span slot="first" let:first let:current>
                    <Btn orange={first === current} on:click={() => filters.page = first }>{first}</Btn>
                </span>
                {#each items as item}
                    <Btn orange={item === current} on:click={() => filters.page = item }>{item}</Btn>
                {/each}
                <span slot="last" let:first let:last let:current>
                    {#if first !== last}
                    <Btn orange={last === current} on:click={() => filters.page = last }>{last}</Btn>
                    {/if}
                </span>
            </Pagination>
        {/if}
    </div>
    
    
    <slot items={records}>
    {#each records as item}
        <div class="record">
            <div class="id">
                <a href={$url('../:id', { id: item.id })}>
                    #{item.id}
                </a>  
            </div>
            <div class="name">
                <a href={$url('../:id', { id: item.id })}>
                    {item.name}
                </a>                        
            </div> 
            <div class="edit">
                <Btn tag="a" href={$url('../:id', { id: item.id })} blue block>
                    Edit
                </Btn>  
            </div>         
            {#if item.deleted_at === null}
            <div class="delete">
                <Btn red block>
                    Delete
                </Btn>  
            </div>
            {:else}
            <div class="restore">
                <Btn green block>
                    Restore
                </Btn>  
            </div>                 
            {/if}
        </div>
    {:else}
        <div class="no-results">
            No results found{filters.search !== null ? ' for "' + filters.search + '"' : ''}.
        </div>
    {/each}
    </slot>

    <div class="toolbar">
        <div class="spacer"></div>
        {#if pagination !== false}
            <Pagination first={pagination.first_page} last={pagination.last_page} current={pagination.current} show={paginationShow} let:pages={items} let:current>
                <span slot="first" let:first let:current>
                    <Btn orange={first === current} on:click={() => filters.page = first }>{first}</Btn>
                </span>
                {#each items as item}
                    <Btn orange={item === current} on:click={() => filters.page = item }>{item}</Btn>
                {/each}
                <span slot="last" let:first let:last let:current>
                    {#if first !== last}
                    <Btn orange={last === current} on:click={() => filters.page = last }>{last}</Btn>
                    {/if}
                </span>
            </Pagination>
        {/if}
    </div>
</div>

<style>
.crud {
    display: grid;
    grid-row-gap: 20px;
}

.crud .loader {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;        
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.4);
    z-index: 5000;
}

.crud .toolbar {
    display: flex;
    align-items: center;
    min-height: 40px;
}

.crud .statuses {
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;
    margin: 0 -10px;
}

.crud .statuses .status {
    padding: 0 10px 0 10px;
    border-right: 1px solid #ddd;
    color: #c5c3c3;
    transition: all 150ms ease-in;
    cursor: pointer;
}

.crud .statuses .status:last-of-type {
    border-right: none;
}

.crud .statuses .status:hover {
    color: #fd7e14;
}

.crud .statuses .status.active {
    color: #333;
}

.crud  :global(.record) {
    display: grid;
    background: #FFF;
    border: 1px solid #ddd;
    padding: 10px;
    grid-template-columns: fit-content(4ch) auto fit-content(6ch) fit-content(11ch) fit-content(9ch) fit-content(8ch);
    grid-column-gap: 10px;  
    align-items: center;
}

.crud :global(.record .id),
.crud :global(.record .name) {
    height: 100%;
}

.crud :global(.record .id a),
.crud :global(.record .name a) {
    display: flex;
    height: 100%;
    align-items: center;
}

.crud :global(.record .id a) {
    color: #c5c3c3;
}

.crud :global(.record .name a) {
    color: #333;    
}

.crud :global(.record .btn) {
    padding: 9px 12px;
}

.crud :global(.record .id a:hover),
.crud :global(.record .name a:hover) {
    color: #fd7e14;
}

.crud :global(.no-results) {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
}

@media (max-width: 767px) {
    .crud :global(.record) {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
    }

    .crud :global(.record .id) {
        width: auto;
        padding-right: 10px;
    }

    .crud :global(.record .name) {            
        flex-grow: 1;
        padding-right: 10px;
        min-width: calc(100% - 14ch);
    }
    
    .crud :global(.record .publish),
    .crud :global(.record .unpublish) {
        grid-column: 4 / 5;
        grid-row: 1 / 2;     
        display: flex;
        justify-content: flex-end;
        align-items: center;       
    }

    .crud :global(.record .publish .btn),
    .crud :global(.record .unpublish .btn) {
        padding: 5px 10px;
        line-height: 1;
        border-radius: 1.1em;
    }

    .crud :global(.record .edit) {
        width: calc(50% - 5px);
        margin-top: 10px;
        margin-right: 5px;
    }
    
    .crud :global(.record .delete) {
        margin-top: 10px;
        width: calc(50% - 5px);
        margin-left: 5px;
    }

}</style>