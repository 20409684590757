<script>
    export let error = 'undefined'
</script>

<div class="error">
    {error}
</div>

<style>
.error {
    border: 1px solid #dc3545;
    color: #dc3545;
    background: #f8d7da;        
    padding: 10px 15px;
}</style>