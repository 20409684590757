<script>
    import TextInput from '../../../components/form/TextInput.svelte'
    export let prefix = false
    export let slug = undefined
    export let url = undefined

    $: link = generateLink(url, slug)

    function generateLink () {
        if (url === undefined || typeof slug !== 'string' || slug === '') return false
        let parts = [url]
        if (prefix !== false) parts.push(prefix)
        parts.push(slug)
        return parts.join('/')
    }
</script>

<TextInput label="Slug" name="slug" bind:value={slug} noValidation>    
    <div class:prefix={prefix !== false} slot="prepend">
        {#if prefix !== false}
            {prefix}/
        {/if}
    </div>                            
    <div class:append={link !== false} slot="append">
    {#if link !== false}
        <a href={link} target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 18 5 L 18 7 L 23.5625 7 L 11.28125 19.28125 L 12.71875 20.71875 L 25 8.4375 L 25 14 L 27 14 L 27 5 Z M 5 9 L 5 27 L 23 27 L 23 14 L 21 16 L 21 25 L 7 25 L 7 11 L 16 11 L 18 9 Z"/></svg>
        </a>
    {/if}
    </div>                            
</TextInput>

<style>
.prefix {
    padding: 5px 7px;
    background: #e6e6e6;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ccc;
    line-height: 1;
}

.append {
    display: flex;
    background: #e6e6e6;
    border-left: 1px solid #ccc;
    line-height: 1;
    height: auto;
    align-self: stretch;   
    box-sizing: border-box; 
    cursor: pointer;
    transition: 150ms all ease-in;
}

.append a {   
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px 0 10px;
    height: 100%;
    width: 100%;
}

.append svg {    
    width: 1em;    
}

.append svg path {    
    transition: 150ms all ease-in;
}

.append:hover {
    background: #ee7b00;
}

.append:hover svg path {
    fill: #FFF;
}</style>
