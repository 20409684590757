<script>
    import Btn from '../../components/Btn.svelte'
    import Pill from '../../components/Pill.svelte'
    import Card from '../../components/Card.svelte'
    import ApiService from '../../services/api.service.js'
    import { onMount } from 'svelte'
    import { url } from "@sveltech/routify";

    export let pages = []  
    let loading = []

    getPages()
    
    async function getPages () {
        if (loading.includes('pages') === true) return
        
        loading.push('pages')

        try {
            let response = await ApiService.get('site-editor/pages/?per_page=3')

            pages = response.data.data
        } catch (error) {
            console.error(error)
        } finally {
            loading = loading.filter(l => l !== 'pages')
        }
    }
</script>

<div class="grid">
    <Card title="Pages" loading={loading.includes('pages')}>
        <div class="recent">
            {#each pages as item (item)}
                <div class="item">
                    <div class="name">
                        <a href={$url('/site-editor/pages/:id', { id: item.id })}>
                            {item.name}
                        </a>                    
                    </div>
                    <div class="status">
                        <Pill>
                            {item.published_at === null ? 'Draft' : 'Published'}
                        </Pill>
                    </div>
                </div>
            {/each}
        </div>

        <div class="more" slot="actions">
            <Btn tag="a" href={$url('/site-editor/pages/')} block>View all</Btn>
        </div>
    </Card>
</div>

<style>
.grid {
    display: grid;
    grid-row-gap: 15px;
    grid-column-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr;
}

.recent {
    display: grid;
    grid-row-gap: 10px;
    margin-bottom: 10px;    
}

.recent .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.recent .item .name {
    flex-grow: 1;
}</style>