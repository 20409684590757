
<div class="bg">    
    <slot></slot>
</div>

<style> 
.bg {
    background: #e2d1c3;
    background-image: linear-gradient(20deg, #fdfcfb 0%, #e2d1c3 100%);
    min-height: 100vh;
    height: fit-content;
    width: 100%;       
    display: flex;
    justify-content: center; 
}</style>
