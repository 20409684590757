<script>
    export let value
    export let name = undefined
    export let errors = false
    export let disabled = false
    export let placeholder = ''
    export let label = false
    export let type = 'text'
    export let touched = false    
    export let noValidation = false
    export let autocomplete = undefined

    let valid = null
    $: hasErrors = errors !== false && touched
    $: valid = errors === false && noValidation === false && touched
  
    $: displayErrors = Array.isArray(errors) ? errors : [errors]
</script>

{#if label !== false}
    <div class="label">
        <label for={name}>
            {label}
        </label>    
    </div>
{/if}

<div class="input-group" class:is-disabled={disabled} class:is-valid={valid} class:has-errors={hasErrors}>
    <slot name="prepend"></slot>
    {#if type === 'password'}
        <input {autocomplete} type="password" {disabled} on:blur={() => touched = true} bind:value|trim={value} {placeholder} {name}>
    {:else}
        <input {autocomplete} type="text" {disabled} on:blur={() => touched = true} bind:value|trim={value} {placeholder} {name}>
    {/if}
    
    <slot name="append"></slot>
    {#if valid}
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
        </div>
    {/if}

    {#if hasErrors}
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>
        </div>
    {/if}
</div>

{#if errors !== false && touched === true}
    <div class="errors">
        {#each displayErrors as error}
            <div class="error">
                {error}
            </div>
        {/each}
    </div>
{/if}

<style>
.label {
    font-size: 17px;     
    color: #2c3e50;
    margin-bottom: 0.25em;   
}

.input-group {
    display: flex;
    border: 1px solid #cccccc;
    background: #FFF;
    font-size: 18px;
    align-items: center;
}

.input-group input {
    flex-grow: 1;
    border-radius: 0;
    border: none;
    margin-bottom: 0;
    box-shadow: none;
}

.input-group input:focus {
        outline: none;
}

.input-group .icon {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-group.is-valid {
    border-color: #28a745;
}

.input-group.is-valid svg {
    height: 1em;
    width: 1em;
}

.input-group.is-valid svg path {
    fill: #28a745;
}

.input-group.has-errors {
    border-color: #dc3545;
    color: #dc3545;
}

.input-group.has-errors svg {
    height: 1em;
    width: 1em;
}

.input-group.has-errors svg path {
    fill: #dc3545;
}

.errors {
    color: #dc3545;
    margin: 0.2em 0 0.3em 0;
    display: grid;
    row-gap: 5px;
}</style>