<script>
    import Btn from '../../../components/Btn.svelte'
    import Card from '../../../components/Card.svelte'    
    import TextInput from '../../../components/form/TextInput.svelte'
    import MarkdownEditor from '../../../components/form/MarkdownEditor.svelte'
    import ApiService from '../../../services/api.service.js'
    import { validator, required, minLength } from '../../../validators'
    import { slide } from 'svelte/transition'
    import DatePicker from '../../../components/DatePicker.svelte'
    import { url, goto } from "@sveltech/routify";
    import { isAfter } from 'date-fns'
    import Modal from '../../../components/Modal.svelte'
    import LoaderIcon from '../../../components/LoaderIcon.svelte'
    import Image from '../../../modules/site-editor/blocks/Image.svelte'
    import File from '../../../modules/site-editor/blocks/File.svelte'
    import ContentSection from '../../../modules/site-editor/blocks/ContentSection.svelte'
    import LinkedModel from '../../../modules/site-editor/blocks/LinkedModel.svelte'
    import { v4 as uuid } from 'uuid'
    let theme = false    
    let previous = JSON.stringify(false)
    let now = new Date()
    let saving = false
    let rules = {}
    let version = uuid()
    let isValid = true

    let errorMessages = {
        required: 'This is a required field',
        minLength: 'This field must be longer than %length%'
    }

    getTheme()
    
    $: unsaved = previous != JSON.stringify(toSave(theme))    
    $: loading = theme === false || theme === undefined
    $: disabled = saving === true
    
    async function getTheme() {       
        let url = 'site-editor/theme'

        try {
            let { data } = await ApiService.get(url)

            theme = data

            previous = JSON.stringify(toSave(theme))
        } catch (error) {
            console.error(error)
        }
    }

    function toSave (object) {
        if (object === undefined) return undefined
        if (typeof object !== 'object') return object
        return object
    }

    async function save() {
        if (saving === true) return

       saving = true

        let url = 'site-editor/theme'

        try {            
            let { data } = await ApiService.post(url, toSave(theme))
            
            theme = data
            reset()
        } catch (error) {
            console.error(error)
        } finally {
            saving = false
        }
    }

    function reset() {
        previous = JSON.stringify(toSave(theme))
        version = uuid()
    }

    function getOption (options, key, fallback) {
        let item = options.find(o => o.key === key)
        if (item === undefined) return fallback
        return item.value
    }
</script>
<div class="grid">
    {#if loading === true} 
        <div class="loading">
            <LoaderIcon />
        </div>
    {:else}
    <div class="main-col">        
        {#each theme as section, key (key) }
        <div class="content-card">
            <Card title={section.title} loading={loading === true}>                     
                <div class="form">
                    {#each section.properties as item, index}
                    <div class="form-group">                    
                        {#if item.component === 'Image'}
                            <label class="label">{item.label}</label>
                            <Image 
                                bind:content={theme[key].properties[index].content} 
                                bind:properties={theme[key].properties[index].properties} 
                            />
                        {:else if item.component === 'File'}
                            <label class="label">{item.label}</label>
                            <File 
                                bind:content={theme[key].properties[index].content} 
                                bind:properties={theme[key].properties[index].properties} 
                            />
                        {:else if item.component === 'TextInput'}
                            <TextInput 
                                name={theme[key].properties[index].name}
                                label={theme[key].properties[index].label}
                                bind:value={theme[key].properties[index].content} 
                                noValidation
                            />
                        {:else if item.component === 'ContentSection'}
                            <ContentSection 
                                reset={version}
                                bind:content={theme[key].properties[index].content} 
                                bind:properties={theme[key].properties[index].properties}                                 
                            />
                        {:else if item.component === 'LinkedModel'}
                            <LinkedModel   
                                label={item.label}
                                bind:selected={theme[key].properties[index].content}
                                moduleRoute={getOption(item.options, 'moduleRoute', 'site-editor')}
                                model={getOption(item.options, 'model')}
                                limit={getOption(item.options, 'limit', false)}
                            />
                        {:else if item.component === 'ContentSection'}
                            <ContentSection bind:content={theme[key].properties[index].content} properties={theme[key].properties[index].properties} options={theme[key].properties[index].options} />
                        {/if}
                    </div>   
                    {/each}    
                </div>    
            </Card>
        </div>
        {/each}
    </div>

    <div class="sidebar">
        <div class="sidebar-card">           
            <div class="actions">     
                <Btn block green on:click={save} {disabled} loading={saving === true}>
                    Save
                </Btn>   
            </div>     
        </div>
    </div>  
    {/if}  
</div>

{#if unsaved === true}
    <div class="sidebar-card warning unsaved" transition:slide|local={{delay: 200}}>
        <Card>
            You have unsaved changed
        </Card>            
    </div>
{/if}

<style>
.grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 40px;
}

.label {
    font-size: 17px;
    color: #2c3e50;
    margin-bottom: 0.25em;
}

.loading {
    grid-column: 1 / -1;
}

.main-col {
    display: grid;
    grid-row-gap: 40px;
    padding-bottom: 40px;
}

.content-card,
.sidebar-card {
    border: 1px solid #ddd;    
}

.sidebar-card {
    margin-bottom: 40px;
}

.actions {
    display: flex;
}

.actions .btn {
    width: 50%;
}

.form {
    display: grid;
    grid-row-gap: 20px;
}

.sidebar-card.warning {
    border-color: #ee7b00;
}

.sidebar-card.warning :global(.card .body) {    
    background: #ee7b00!important;
    color: #FFF;
}

.unsaved {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    text-align: center;
    z-index: 1;
}

.message {
    font-size: 18px;
    font-weight: 300;    
}

.delete-modal {
    display: flex;
    min-height: 6em;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.delete-modal .message {    
    padding: 0 20px;
}

.slug-prefix {
    padding: 5px 7px;
    background: #e6e6e6;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ccc;
    line-height: 1;
}</style>