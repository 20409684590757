<script>
    import LinkedModel from './LinkedModel.svelte'
    import CheckboxInput from '../../../components/form/CheckboxInput.svelte'

    export let content = '';
    export let properties = {};

    $: content = isNull(content, [])

    $: properties = isNull(properties, {})

    function isNull (current, fallback) {
        return current === null ? fallback : current
    }
</script>
<div class="form">
    <div class="form-group">
        <CheckboxInput
            label="Accordion"
            bind:value={properties.accordion}
        />
    </div>
    <div class="form-group">
        <LinkedModel 
            bind:selected={content} 
            unselected="No services selected" 
            placeholder="Search for services"
            model="services"
            ordered
        />
    </div>
</div>

<style>
.form {
    display: grid;
    grid-row-gap: 15px;
}</style>