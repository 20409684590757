<script>
  import Btn from "../../../components/Btn.svelte";
  import ImageEditor from "../components/ImageEditor.svelte";
  import { mediaLibrary } from "../stores/media-library.store.js";
  import { onMount } from "svelte";
  import { isNull } from "../../../utils/site-editor.js";
  import { v4 as uuid } from "uuid";
  import TextInput from "../../../components/form/TextInput.svelte";
  import LinkedModel from "./LinkedModel.svelte";
  import Button from "./Button.svelte";

  export let content = null;
  export let properties = null;
  export let linkable = false;
  export let label = false;
  export let noSizes = false;

  let id = uuid();

  let imageEditor = false;

  $: content = isNull(content, {
    image: {
      media: null,
      alt: null,
      crop: null,
    },
  });

  $: properties = isNull(properties, {
    style: {},
    link: null,
    customLink: null,
    type: "linkedModel",
  });
  $: console.log(properties)
  $: {
    if (properties && properties.link === undefined) properties.link = null;
    if (properties && properties.customLink === undefined) properties.customLink = null;
    if (properties && properties.type === undefined) properties.type = "linkedModel";
  }
  $: setSelectedMedia($mediaLibrary.selected, $mediaLibrary.attached);
  $: preview = updatePreview(content.image);
  $: hasOptions = properties && noSizes === false;

  function updatePreview() {
    if (content.image.media === null) return;
    let params = [];

    if (content.image.crop !== null && content.image.crop !== undefined) {
      let crop = content.image.crop;

      params.push("tl_px=" + crop.x + "," + crop.y);
      params.push("w=" + crop.width);
      params.push("h=" + crop.height);
      params.push("h=" + crop.height);
    }

    params.push("hash=" + content.image.media.hash);

    return (
      content.image.media.url +
      (params.length > 0 ? "?" : "") +
      params.join("&")
    );
  }

  function openMediaLibrary() {
    mediaLibrary.setMaxSelection(1);
    mediaLibrary.open(
      id,
      content.image.media !== null ? [content.image.media] : undefined
    );
  }

  function setSelectedMedia(selected, attached) {
    if (attached === false) return;
    if ($mediaLibrary.attaching !== id) return;
    if (selected[0] === undefined) return;

    content.image.media = selected[0];
    content.image.alt = selected[0].name;
    if (properties && properties.link === undefined) properties.link = null;
  }

  function openImageEditor() {
    imageEditor = content.image;
  }

  function closeImageEditor(event) {
    content.image = event.detail;
    imageEditor = false;
  }

  function clear() {
    content = null;
  }

  function switchTo(type) {
    console.log('switch to')
    properties.type = type;
    properties.customLink = null;
    properties.link = null;
  }
</script>

{#if label !== false}
  <div class="label">
    <label>
      {label}
    </label>
  </div>
{/if}

<div class="banner">
  {#if content.image.media === null}
    <div class="find">
      <Btn green on:click={openMediaLibrary}>Select Image</Btn>
    </div>
  {:else}
    <div class="thumbnail">
      <img src={preview} alt={content.image.alt} />
    </div>
    <div class="info">
      <div class="name">
        {content.image.media.name}
      </div>

      <div class="clear" on:click={clear}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"
          ><path
            d="M 14 4 C 13.476563 4 12.941406 4.183594 12.5625 4.5625 C 12.183594 4.941406 12 5.476563 12 6 L 12 7 L 5 7 L 5 9 L 6.09375 9 L 8 27.09375 L 8.09375 28 L 23.90625 28 L 24 27.09375 L 25.90625 9 L 27 9 L 27 7 L 20 7 L 20 6 C 20 5.476563 19.816406 4.941406 19.4375 4.5625 C 19.058594 4.183594 18.523438 4 18 4 Z M 14 6 L 18 6 L 18 7 L 14 7 Z M 8.125 9 L 23.875 9 L 22.09375 26 L 9.90625 26 Z M 12 12 L 12 23 L 14 23 L 14 12 Z M 15 12 L 15 23 L 17 23 L 17 12 Z M 18 12 L 18 23 L 20 23 L 20 12 Z"
          /></svg
        >
      </div>

      <div class="metas">
        {#each content.image.media.meta as item}
          <div class="meta">
            {item.label}: {item.value}
          </div>
        {/each}
      </div>

      {#if hasOptions}
        <div class="options">
          {#if noSizes === false}
            <div class="width">
              <TextInput
                label="Width"
                noValidation
                bind:value={properties.width}
                autocomplete="off"
              />
            </div>
            <div class="height">
              <TextInput
                label="Height"
                noValidation
                bind:value={properties.height}
                autocomplete="off"
              />
            </div>
          {/if}
        </div>
      {/if}

      <div class="actions">
        <Btn blue on:click={openImageEditor}>Crop</Btn>

        <Btn green on:click={openMediaLibrary}>Replace</Btn>
      </div>
    </div>
    {#if linkable}
      <div class="link">
        <div class="custom">
          {#if properties.type == "customLink"}
            <div on:click={() => switchTo("linkedModel")}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path
                  d="M 14 4 C 13.476563 4 12.941406 4.183594 12.5625 4.5625 C 12.183594 4.941406 12 5.476563 12 6 L 12 7 L 5 7 L 5 9 L 6.09375 9 L 8 27.09375 L 8.09375 28 L 23.90625 28 L 24 27.09375 L 25.90625 9 L 27 9 L 27 7 L 20 7 L 20 6 C 20 5.476563 19.816406 4.941406 19.4375 4.5625 C 19.058594 4.183594 18.523438 4 18 4 Z M 14 6 L 18 6 L 18 7 L 14 7 Z M 8.125 9 L 23.875 9 L 22.09375 26 L 9.90625 26 Z M 12 12 L 12 23 L 14 23 L 14 12 Z M 15 12 L 15 23 L 17 23 L 17 12 Z M 18 12 L 18 23 L 20 23 L 20 12 Z"
                />
              </svg>
            </div>
          {:else}
            <Btn green on:click={() => switchTo("customLink")}>Custom Link</Btn>
          {/if}
        </div>
        <div class="linker">
          {#if properties.type == "customLink"}
            <TextInput
              label="Custom Link"
              placeholder="Example: https://google.co.uk"
              noValidation
              bind:value={properties.customLink}
              name="link"
              autocomplete="off"
            />
          {:else}
            <LinkedModel
              label="Link"
              bind:selected={properties.link}
              unselected="No items selected"
              placeholder="Search for items"
              model="links"
              limit={1}
            />
          {/if}
        </div>
      </div>
    {/if}
  {/if}
</div>

{#if imageEditor}
  <ImageEditor bind:image={imageEditor} on:close={closeImageEditor} />
{/if}

<style>
.label {
  font-size: 17px;
  color: #2c3e50;
  margin-bottom: 0.25em;
}

.banner {
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: 150px auto;
}

.thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f6f6;
  border: 1px solid #ddd;
}

.thumbnail img {
  max-width: 100%;
  max-height: 150px;
}

.info {
  display: grid;
  grid-template-columns: auto 30px;
  grid-template-rows: fit-content(1em) auto fit-content(1em);
  row-gap: 10px;
}

.name {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  font-size: 18px;
  font-weight: 300;
}

.clear {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  cursor: pointer;
}

.clear svg {
  width: auto;
}

.clear svg path {
  fill: #ccc;
  transition: 150ms all ease-in;
}

.clear:hover svg path {
  fill: #f44336;
}

.metas {
  grid-column: 1 / 3;
  font-size: 16px;
  color: #666;
  font-weight: 300;
}

.options {
  grid-column: 1 / 3;
  font-size: 16px;
  color: #666;
  font-weight: 300;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
}

.actions {
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
}

.link {
  grid-column: 1 / 3;
  display: grid;
  grid-template-areas: "link custom";
  grid-template-columns: auto fit-content(20ch);
  column-gap: 30px;
  row-gap: 30px;
}

.link .linker {
  grid-area: link;
}

.link .custom {
  grid-area: custom;
}

.custom svg {
  width: 2em;
}

.custom svg path {
  fill: #ccc;
  transition: 150ms all ease-in;
}

.custom:hover svg path {
  fill: #f44336;
}</style>
