<script>
    export let name
    export let size = '50px'
    let avatar
    $: initial = typeof name === 'string' ? name.charAt(0) : ''
    $: avatar !== undefined ? avatar.style.width = size : undefined
    $: avatar !== undefined ? avatar.style.height = size : undefined
</script>

<div class="avatar" bind:this={avatar} title={name} on:click>
    <div class="initial">
        {initial}
    </div>
</div>

<style>
.avatar {
    border-radius: 100%;
    background: #fd7e14;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.avatar .initial {
    font-weight: 400;
    font-size: 27px;
    line-height: 1;
}</style>