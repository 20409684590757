<script>
    import { v4 as uuid } from 'uuid'
    import Btn from '../../../components/Btn.svelte'
    import Sortable from 'sortablejs'
    import { onMount } from 'svelte'
    import TextInput from '../../../components/form/TextInput.svelte'

    export let options = []
    export let type

    let optionsEl

    function addOption () {
        let id = uuid()

        reorder([
            ...options,
            {
                id,
                value: null,
                order: 99
            }
        ])
    }

    function removeOption (id) {
        reorder(options.filter(o => o.id !== id))
    }

    function reorder (newOptions) {
		let i = 1
		
		for (let index in newOptions) {
			newOptions[index].order = i
			i++
		}

		options = newOptions
	}

    onMount(() => {
		Sortable.create(optionsEl, {
			draggable: '.option',
			handle: '.option .handle',
			store: {
				/**
				 * Get the order of elements. Called once during initialization.
				 * @param   {Sortable}  sortable
				 * @returns {Array}
				 */
				get: function (sortable) {					
					return options.map(i => i.order);
				},

				/**
				 * Save the order of elements. Called onEnd (when the item is dropped).
				 * @param {Sortable}  sortable
				 */
				set: function (sortable) {					
					let newOrder = sortable.toArray()
					let newOptions = []
					for (let index in newOrder) {			
						newOptions.push(options.find(b => b.id === newOrder[index]))
					}

					reorder(newOptions)
				}
			}
		})
	})
</script>

<div class="options" bind:this={optionsEl}>
    {#each options as option, index (option.id)}
        <div class="option" data-id={option.id}>
            <div class="handle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 3.59375 L 15.28125 4.28125 L 7.28125 12.28125 L 5.59375 14 L 26.40625 14 L 24.71875 12.28125 L 16.71875 4.28125 Z M 16 6.4375 L 21.5625 12 L 10.4375 12 Z M 5.59375 18 L 7.28125 19.71875 L 15.28125 27.71875 L 16 28.40625 L 16.71875 27.71875 L 24.71875 19.71875 L 26.40625 18 Z M 10.4375 20 L 21.5625 20 L 16 25.5625 Z"/></svg>
            </div>

            <div class="input">
                <TextInput
                    bind:value={options[index].value}
                    noValidation
                />
            </div>

            <div class="delete" on:click={() => removeOption(option.id)} title="Remove option"> 
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>
            </div>
        </div>
    {/each}
    <Btn green on:click={addOption}>
        Add Option
    </Btn>
</div>

<style>
.options {
    display: grid;
    row-gap: 10px;
}

.option {
    display: grid;
    grid-template-columns: 20px auto 20px;
    align-items: center;
    column-gap: 10px;
}

.option .delete {
    cursor: pointer;
}</style>