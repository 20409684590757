<script>
    export let value
    export let name = undefined
    export let disabled = false
    export let label = false

    function toggle () {
        if (typeof value !== 'boolean') {
            value = true
        } else {
            value = !value
        }
    }
</script>

{#if label !== false}
    <div class="label">
        <label for={name}>
            {label}
        </label>    
    </div>
{/if}

<div class="input-group" class:is-disabled={disabled}>
    <slot name="prepend"></slot>
    
    <div class="box" on:click={toggle}>
        {#if value === true}
            <svg class="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
        {/if}
    </div>
    
    <slot name="append"></slot>
</div>

<style>
.label {
    font-size: 17px;     
    color: #2c3e50;
    margin-bottom: 0.25em;   
}

.input-group {
    display: flex;
    font-size: 18px;
    align-items: center;
}

.box {
    height: 1em;
    width: 1em;
    border: 1px solid #ccc;
    cursor: pointer;
}

.box svg {
    height: 1em;
    width: 1em;
}

.box svg path {
    fill: #28a745;
}</style>