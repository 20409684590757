<script>
	import { onMount, createEventDispatcher } from 'svelte'
  import Uploader from './Uploader.svelte'
  import MediaBrowser from './MediaBrowser.svelte'      
  import TextInput from '../../../components/form/TextInput.svelte'
  
  export let variant = true
  export let height = '85vh'
  export let width = '80vw'
  export let mode = 'browse'
  
  const dispatch = createEventDispatcher()	
  
  let modal
  let search = null
	
	onMount(() => {
		modal.style.height = height
		modal.style.width = width
	})
	
	function close (event) {
		dispatch('close')
	}
</script>

<div class="blocker" on:click|self={close}>
	<div class="modal" bind:this={modal} class:default={variant === true}>
        <div class="header">
            <div class="title">Media Library</div>
            <div class="nav">
                <div class="item" class:active={mode === 'browse'} on:click={() => mode = 'browse' }>
                    Browse
                </div>

                <div class="item" class:active={mode === 'upload'} on:click={() => mode = 'upload' }>
                    Upload
                </div>
            </div>
            <div class="search">
              <TextInput name="search" bind:value={search} noValidation placeholder="Search" />
            </div>
            <div class="close" on:click={close}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>
            </div>
        </div>

        <div class="body">
            {#if mode === 'browse'}
                <MediaBrowser searchTerm={search} />
            {:else if mode === 'upload'}
                <Uploader />
            {/if}
        </div>
	</div>
</div>

<style>
.blocker {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.modal {
  position: relative;
  background: #FFF;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal .close {
  width: 30px;
  height: 30px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  margin-right: 15px;
}

.modal .close svg {
  width: 1em;
  height: 1em;
}

.modal .close svg path {
  fill: #fff;
}

.modal .close:hover {
  background: #fd7e14;
}

.header {
  background: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFF;
}

.header .title {
  padding: 15px;
}

.header .search {
  margin-right: 15px;
}

.body {
  flex-grow: 1;
  overflow: hidden;
}

.nav {
  display: flex;
  flex-grow: 1;
  padding: 0 15px;
  align-self: stretch;
}

.nav .item {
  padding: 10px 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav .item.active,
.nav .item:hover {
  background: #fd7e14;
}</style>