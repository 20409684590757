<script>
    import Btn from '../../../components/Btn.svelte'
    import MenuAddItem from '../components/MenuAddItem.svelte'
    import TextInput from '../../../components/form/TextInput.svelte'
    import SelectInput from '../../../components/form/SelectInput.svelte'
    import { onMount } from 'svelte'

    export let content = null;

    export let properties = null;

    let targets = [
        {
            value: null,
            name: 'Current Tab/Window'
        },
        {
            value: '_blank',
            name: 'New Tab/Window'
        }
    ]

    $: properties = isNull(properties, {})

    $: label = content !== null ? (content.label !== undefined ? '#' + content.id : '') : ''
    $: id = content !== null ? (content.class !== 'Modules\\Modules\\Entities\\CustomLink' ? '#' + content.id : 'Custom Link: ') : ''
    $: name = content !== null ? (content.class !== 'Modules\\Modules\\Entities\\CustomLink' ? content.originalName : content.slug) : ''

    function isNull (current, fallback) {
        return current === null ? fallback : current
    }

    function selectItem (item) {
        if (item.detail === undefined) return
        
        content = {
            id: item.detail.item.id,
            name: item.detail.item.name,
            target: targets[0],
            class: item.detail.item.class,
            label: item.detail.item.label,
            originalName: item.detail.item.name,
            slug: item.detail.item.url
        }
    }
</script>

{#if content === null}
<MenuAddItem on:add={selectItem} />
{:else}
<div class="grid">
    <div class="form">
        <div class="row">
            {label}{id} {name}
        </div>
        <div class="row">
            <TextInput label="Text" name="name" bind:value={content.name} noValidation />
        </div>
        <div class="row">
            <SelectInput 
                label="Target" 
                name="target" 
                bind:value={content.target} 
                choices={targets} 
                noValidation 
                placeholder={targets[0].name}
            />
        </div>
    </div>
    <div class="reset" on:click={() => content = null}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 15 4 C 14.476563 4 13.941406 4.183594 13.5625 4.5625 C 13.183594 4.941406 13 5.476563 13 6 L 13 7 L 7 7 L 7 9 L 8 9 L 8 25 C 8 26.644531 9.355469 28 11 28 L 23 28 C 24.644531 28 26 26.644531 26 25 L 26 9 L 27 9 L 27 7 L 21 7 L 21 6 C 21 5.476563 20.816406 4.941406 20.4375 4.5625 C 20.058594 4.183594 19.523438 4 19 4 Z M 15 6 L 19 6 L 19 7 L 15 7 Z M 10 9 L 24 9 L 24 25 C 24 25.554688 23.554688 26 23 26 L 11 26 C 10.445313 26 10 25.554688 10 25 Z M 12 12 L 12 23 L 14 23 L 14 12 Z M 16 12 L 16 23 L 18 23 L 18 12 Z M 20 12 L 20 23 L 22 23 L 22 12 Z"/></svg>
    </div>
</div>
{/if}

<style>
.grid {
    display: grid;
    grid-template-columns: auto fit-content(10ch);
    grid-column-gap: 15px;
}

.form {
    display: grid;
    grid-row-gap: 15px;
}

.reset svg {
    width: auto;
    height: 1.5em;
}

.reset svg path {
    transition: 150ms all ease-in;
}

.reset:hover svg {
    cursor: pointer;
}

.reset:hover svg path {
    fill: #f44336;
}</style>