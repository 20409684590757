<script>
    import { onMount, onDestroy } from 'svelte'
    import EasyMde from 'easymde'
    export let value
    export let name = null
    export let errors = false
    export let disabled = false
    export let placeholder = ''
    export let label = false
    let valid = null
    export let touched = false    
    let hasErrors = false
    $: hasErrors = errors !== false && touched
    $: valid = errors === false && touched
  
    let displayErrors
    let textarea
    let editor = null

    $: displayErrors = typeof errors === 'object' ? errors.join('<br>') : errors

    onMount (async () => {
        editor = new EasyMde({ 
            autoDownloadFontAwesome: true,
            element: textarea,
            forceSync: true,
            toolbar: [
                'bold',
                'italic',
                'strikethrough',
                '|',
                'heading-smaller',
                'heading-bigger',    
                '|',
                'code',
                'quote',
                '|',
                'unordered-list',
                'ordered-list',
                '|',
                'clean-block',
                'link',                
                '|',
                'preview',
                'side-by-side',
                'fullscreen',
                'guide'
            ]
        })

        editor.codemirror.on('change', function () {
            value = editor.value()
        })
    })

    onDestroy( () => {
        editor.toTextArea()
        editor = null
    })    
</script>

{#if label !== false}
    <div class="label">
        <label for={name}>
            {label}
        </label>    
    </div>
{/if}

<svelte-head>
    <link rel="stylesheet" href="/easymde.min.css" />
</svelte-head>

<div class="input-group" class:is-disabled={disabled} class:is-valid={valid} class:has-errors={hasErrors}>
    <textarea {placeholder} {disabled} bind:this={textarea} bind:value={value}></textarea>
</div>

{#if errors !== false && touched === true}
    <div class="errors">
        {@html displayErrors}
    </div>
{/if}

<style>
.label {
    font-size: 17px;     
    color: #2c3e50;
    margin-bottom: 0.25em;   
}

.input-group {
    font-size: 18px;
    align-items: center;
}

.input-group.is-valid {
    border-color: #28a745;
}

.input-group.has-errors {
    border-color: #dc3545;
    color: #dc3545;
}

.errors {
    color: #dc3545;
    margin: 0.2em 0 0.3em 0;
}

.input-group :global(.editor-toolbar button) {
    position: relative;
}

.input-group :global(.editor-toolbar button:after) {
    position: absolute;
}

.input-group :global(.editor-toolbar button.heading-smaller:after),
.input-group :global(.editor-toolbar button.heading-bigger:after) {
    
    right: 0;
}

.input-group :global(.editor-toolbar button.heading-smaller:after) {
    content: "-";
    font-weight: bold;
}

.input-group :global(.editor-toolbar button.heading-bigger:after) {
    content: "+";
    font-weight: bold;
}</style>