<script>
    import { clickOutside } from '../../utils/click-outside.js'

    export let value
    export let name
    export let errors = false
    export let disabled = false
    export let placeholder = 'Choice option'
    export let label = false
    export let touched = false    
    export let noValidation = false
    export let choices = []
    export let above = false
    export let right = false
    export let full = false

    let valid = null
    let hasErrors = false
    let open = false
    $: hasErrors = errors !== false && touched
    $: valid = errors === false && noValidation === false && touched
    
    $: displayErrors = Array.isArray(errors) ? errors : [errors]

    $: selected = setSelected (value, choices)

    function setSelected () {
        if (value !== null && typeof value === 'object') {
            return value.name
        } else if (value !== null) {
            let item = choices.find(c => c.value === value)
            
            if (item !== undefined) return item.name
        }

        return placeholder
    }

    function update (item) {        
        value = item
        open = false
    }
</script>

{#if label !== false}
    <div class="label">
        <label for={name}>
            {label}
        </label>    
    </div>
{/if}

<div class="input-group" class:is-disabled={disabled} class:is-valid={valid} class:has-errors={hasErrors}>
    <slot name="prepend"></slot>
    
    <div class="selected" on:click={() => open = true}>
        {selected}
    </div>
    {#if open !== false}
    <div class="popout" class:above={above} class:right={right} class:full={full} use:clickOutside on:clickOutside={() => open = false}>        
        {#each choices as item}
            <div class="item" on:click={() => update(item)}>
                {item.name}
            </div>
        {:else}
            <div class="item">
                No items to select
            </div>
        {/each}        
    </div>
    {/if}
    
    <slot name="append"></slot>    
</div>

{#if errors !== false && touched === true}
    <div class="errors">
        {#each displayErrors as error}
            <div class="error">
                {error}
            </div>
        {/each}
    </div>
{/if}

<style>
.label {
    font-size: 17px;     
    color: #2c3e50;
    margin-bottom: 0.25em;   
}

.input-group {
    display: flex;
    position: relative;
    border: 1px solid #cccccc;
    background: #FFF;
    font-size: 18px;
    align-items: center;
}

.input-group input {
    flex-grow: 1;
    border-radius: 0;
    border: none;
    margin-bottom: 0;
    box-shadow: none;
}

.input-group input:focus {
        outline: none;
}

.input-group .icon {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-group.is-valid {
    border-color: #28a745;
}

.input-group.is-valid svg {
    height: 1em;
    width: 1em;
}

.input-group.is-valid svg path {
    fill: #28a745;
}

.input-group.has-errors {
    border-color: #dc3545;
    color: #dc3545;
}

.input-group.has-errors svg {
    height: 1em;
    width: 1em;
}

.input-group.has-errors svg path {
    fill: #dc3545;
}

.errors {
    color: #dc3545;
    margin: 0.2em 0 0.3em 0;
    display: grid;
    row-gap: 5px;
}

.selected {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 15px;
    width: 100%;
}

.popout {
    position: absolute;
    top: calc(100% - 10px);
    left: 0;
    background: #FFF;
    border: 1px solid #ddd;
    width: auto;
    white-space: nowrap;

    padding: 15px 15px;

    display: grid;
    grid-row-gap: 15px;
    font-size: 16px;
    font-weight: 400;

    color: #737373;
    cursor: pointer;
    z-index: 1000;
}

.popout.above {
    top: auto;
    bottom: calc(100% - 10px);
}

.popout.right {
    left: auto;
    right: 0;
}

.popout.full {
    left: 0;
    right: 0;
}

.popout .item:hover {
    color: #fd7e14;
}

.popout :global(a) {
    color: #737373;
}

.popout :global(a:hover) {
    color: #fd7e14;
}

.popout .loader {
    padding: 10px 50px
}</style>