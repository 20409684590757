<script>
    // import JSONEditor from 'jsoneditor'
    import { onMount } from 'svelte'

    export let json

    $: formatted = JSON.stringify(json, null, 4)

    let editor
    let editorEl
    let error = false

    onMount(() => {
        // editor = new JSONEditor(editorEl, {})

        // editor.set(json)

        // editor.onChange(function () {
        //     json = editor.get()
        // })
    })

    function updated(event) {
        try {
            let parse = JSON.parse(editorEl.value)

            json = parse
            error = false
        } catch {
            error = true
        }
    }
</script>

<textarea class:error={error} bind:this={editorEl} value={formatted} on:input={updated} rows="10"></textarea>
<style>
textarea {
    width: 100%;
}

textarea.error {
    border: 1px solid red;
    outline-color: red;
}</style>