<script>
    import { onMount, createEventDispatcher } from 'svelte'
    export let height = 'auto'
    export let width = '25vw'
    export let overflow = false

    const dispatch = createEventDispatcher()	

    function close (event) {
		  dispatch('close')
    }
    
    let modal

    onMount(() => {
		modal.style.height = height
		modal.style.width = width
	})
</script>

<div class="blocker" on:click|self={close}>
	<div class="modal" class:overflow={overflow} bind:this={modal}>
    <slot></slot>
  </div> 
</div>

<style>
.blocker {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.modal {
  position: relative;
  background: #FFF;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal.overflow {
  overflow: visible;
}

.modal :global(.card .body) {
    flex-grow: 1;
}</style>