<script>
    import Modal from '../../../components/Modal.svelte'
    import Card from '../../../components/Card.svelte' 
    import Btn from '../../../components/Btn.svelte'
    import LoaderIcon from '../../../components/LoaderIcon.svelte'
    import { createEventDispatcher } from 'svelte'

    export let url = false

    let loading = true

    const dispatch = createEventDispatcher()	

    function close (event) {
        dispatch('close')
    }
</script>

<Modal width="94vw" on:close={close}>
    <div class="preview">
        <Card title="Preview">
            <div class="flex" slot="header" let:title>
                <div class="title">
                    {title}            
                </div>
                <div class="spacer"></div>
                <div class="close" on:click={close}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>
                </div>
            </div>
            <div class="preview-modal">
                <iframe title="Preview of {url}" on:load={() => loading = false} src={url}></iframe>                
                {#if loading === true}
                    <div class="loader">
                        <LoaderIcon size="150" />
                    </div>                    
                {/if}
            </div>
        </Card>         
    </div>
</Modal>

<style>
.flex {
    display: flex;
}

.close svg {
    height: 1em;
    width: auto;
    cursor: pointer;
}

.close svg path {
    fill: #FFF;
    transition: all 150ms ease-in;
}

.close svg:hover path {
    fill: #fd7e14;
}

iframe {
    width: 100%;
    height: 85vh;
    max-height: 100%;
    border: none;
}

.preview-modal {
    position: relative;
}

.loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.8);
}

.preview :global(.card .body) {
    padding: 0;
}</style>