<script>
    export let thumbnail
    export let name
    export let selected = false
    export let disabled = false
</script>

<div class="tile" class:disabled={disabled} on:click>
    <div class="thumbnail">
        <img src={thumbnail} alt={name} />
    </div>
    {#if selected}
        <div class="selected"></div>
    {/if}
</div>

<style>
.tile {
  cursor: pointer;
  position: relative;  
  border: 1px solid #e9e9e9;
}
.tile.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.tile .thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f6f6;
  height: 100%;
  padding: 3px;
  box-sizing: border-box;
  border: 2px solid #f6f6f6;
  transition: all 150ms ease-in;
}
.tile .thumbnail img {
  max-width: 100%;
}
.tile .selected {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 3px solid #fd7e14;
}
.tile:hover .thumbnail {  
  border-color: #58595b;  
}</style>