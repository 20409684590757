<script>
    import LoaderIcon from './LoaderIcon.svelte'
    import { slide } from 'svelte/transition'

    export let showHeader = false
    export let title = false
    export let loading = false

    if (title) {
        showHeader = true
    }

</script>

<div class="card">
    {#if showHeader}                
        <div class="header">
            <slot name="header" {title}>
                {title}
            </slot>
        </div>        
    {/if}
    <div class="body">
        {#if loading}
            <div class="loader">
                <LoaderIcon />
            </div>
        {:else}
            <div transition:slide|local>
                <slot ></slot>
            </div>  
        {/if}
    </div>
    <slot name="actions"></slot>
</div>

<style>
.card {
    border-radius: 0;
}

.card .header {
    border-radius: 0;
    background: #58595b;
    color: #FFF;
    padding: 15px 15px;
    font-size: 18px;
    font-weight: 300;
    line-height: 1;
}

.card .body {
    padding: var(--card-body-padding, 15px);
    background: #FFF;
}

.loader {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}</style>