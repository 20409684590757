<script>
    import TextInput from '../../../components/form/TextInput.svelte'
    import CheckboxInput from '../../../components/form/CheckboxInput.svelte'
    import Btn from '../../../components/Btn.svelte'
    import { v4 as uuid } from 'uuid'
    import SortableList from '../../../components/SortableList.svelte'

    export let content = null;
    export let properties = {};

    $: content = isNull(content, {
        showLabel: true,
        required: false,
        label: '',
        placeholder: '',
        options: []
    })

    $: properties = isNull(properties, {})

    let hasLabel = false
    let option = ''

    function isNull (current, fallback) {
        return current === null ? fallback : current
    }

    function addOption () {
        if (Array.isArray(content.options) === false) content.options = []
        content = {
            ...content, 
            options: [
                ...content.options,
                {
                    id: uuid(),
                    name: option,
                    order: content.options.length + 1
                }
            ]
        }

        option = ''
    }

    function removeOption (id) {
        content.options = content.options.filter(o => o.id !== id)
    }

</script>

<div class="grid">    
    <div class="row">
        <div class="col">
            <TextInput label="Label" bind:value={content.label} noValidation />
        </div>        
        <div class="col">
            <TextInput label="Placeholder" bind:value={content.placeholder} noValidation />
        </div>        
    </div>
    <div class="row">
        <div class="col">
            <CheckboxInput label="Show Label" bind:value={content.showLabel} />
        </div>
        <div class="col">
            <CheckboxInput label="Required" bind:value={content.required} />
        </div>
    </div>
    <div class="row">
        <div class="col">
            <form on:submit|preventDefault={addOption}>
                <TextInput type="number" label="Options" bind:value={option} noValidation>
                    <span slot="append">
                        <Btn type="submit" green>
                            Add
                        </Btn>
                    </span>
                </TextInput>
            </form>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="options">
                <SortableList 
                    bind:items={content.options} 
                    on:remove={(event) => removeOption(event.detail.id)} 
                />
            </div>
        </div>
    </div>
</div>

<style>
.grid {
    display: grid;
    row-gap: 15px;
}

.row {
    display: grid;
    row-gap: 15px;
    column-gap: 30px;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
}

.options {
    display: grid;
    row-gap: 15px;
}

.options .option {
    display: grid;
    column-gap: 10px;
    grid-template-columns: auto 1em;
    align-items: center;
}

.options .option svg {
    width: 1em;
    height: 1em;
}</style>