<script>
	import { createEventDispatcher } from 'svelte'
	export let first = 1
	export let last
	export let current
	export let show = 3
	
	const dispatch = createEventDispatcher()
	let pages = []
	
	$: internalShow = (parseInt(show) < 3) ? 3 : parseInt(show)
	
	$: pages = buildPagination(first, last, current, internalShow)
	
	function buildPagination() {	
		let items = [...Array(last).keys()].map(i => i + 1)			
		let remove = internalShow 
		
		if (items.length > internalShow) {
			let split = internalShow % 2 === 0 ? internalShow / 2 : (internalShow - 1) / 2
			let start = (current - split) - 1
			let end = start + internalShow

			if (start <= 1) {
				start = 1
			} else if (end >= items.length ) {
				start = (items.length - internalShow) - 1
			}		

			if (last === internalShow + 1) {
				remove--
			}

			items = items.splice(start > 1 ? start  : 1, remove)
		}				
		
		return items.filter(i => i !== first && i !== last)
	}	
	
	function setPage (page) {
		dispatch('set-page', page)
	}
</script>

<div class="pagination">                    	
		<slot name="first" {first} {setPage} {current}>
			<button class:active={first === current} on:click={() => { setPage(first) }}>
				{first}	
			</button>			
		</slot>		
		<slot {pages} {setPage} {current}>
			{#each pages as page}
			<button class:active={page === current} on:click={() => { setPage(page) }}>
				{page}	
			</button>		
			{/each}
		</slot>		
		<slot name="last" {last} {first} {setPage} {current}>
            {#if first !== last}
                <button class:active={last === current} on:click={() => { setPage(last) }}>
				    {last}	
			    </button>		    
            {/if}			
		</slot>		
</div>

<style global>
   :global(.pagination) {
       display: flex;
   }

   :global(.pagination) :global(button) {
       width: 4ch;
       text-align: center;
       margin: 0 0 0 1px;
   }

   :global(.pagination) :global(button.active) {
	font-weight: bold;
}</style>