<script>
	import { slide } from 'svelte/transition'
	import { onMount } from 'svelte'
	import Sortable from 'sortablejs'
	import { clickOutside } from '../../../utils/click-outside.js'
	import Banner from '../blocks/Banner.svelte'
	import Image from '../blocks/Image.svelte'
	import ContentSection from '../blocks/ContentSection.svelte'
	import ContentSectionImage from '../blocks/ContentSectionImage.svelte'
	import Table from '../blocks/Table.svelte'
	import TextInput from '../../../components/form/TextInput.svelte'
	import SelectInput from '../../../components/form/SelectInput.svelte'
	import Btn from '../../../components/Btn.svelte'
	import { v4 as uuid } from 'uuid'
	import Card from '../../../components/Card.svelte'
	import Modal from '../../../components/Modal.svelte'
	import { flip } from 'svelte/animate'
	import MenuAddItems from './MenuAddItem.svelte'
	import MenuItems from './MenuItems.svelte'
	import { menuItems } from '../stores/menu.js'

	let addModal = false
	let settingsModal = false	
	let itemsEl
	let targets = [
		{
			value: null,
			name: 'Same Tab/Window'
		},
		{
			value: '_blank',
			name: 'New Tab/Window'
		}
	]
	let parent = null

	$: parents = [
		{
			id: null,
			name: 'No Parent'
		},
		...$menuItems
	]

	function updateItem () {
		menuItems.save(settingsModal)
		settingsModal = false
	}
	
	function addItem (event) {
		let id = menuItems.add(event)
	
		settingsModal = $menuItems.find(i => i.id === id)
		
		addModal = false   
		parent = null
    }

	function openSettings (event) {
		let item = event.detail

		let parent = $menuItems.find(i => i.id === item.parent_id)
	
		settingsModal = {
			...item,
			parent: parent !== undefined ? parent : null
		}
	}

	function addChild(event) {
		addModal = true
		parent = event.detail
	}
</script>

<div class="layout">
	<MenuItems 
		parent={null}
		showAddItem
		on:openSettings={openSettings}
		on:addItem={() => addModal = true}
		on:addChild={addChild}
	/>

</div>

<div class="items"></div>
{#if addModal !== false}
	<Modal on:close={() => addModal = false} width="65vw" height="50vh">
        <Card title="Add menu item">
            <MenuAddItems on:add={addItem} {parent} />
        </Card>
    </Modal>
{/if}

{#if settingsModal !== false}
	<Modal overflow>
        <Card title="Update menu item">
			<div class="form-grid">
				<div class="form-group">
					<TextInput label="Name" name="name" bind:value={settingsModal.name} noValidation />
				</div>
				<div class="form-group">
					<SelectInput 
						label="Target" 
						name="target" 
						bind:value={settingsModal.target} 
						choices={targets} 
						noValidation 
						placeholder="Same Tab/Window" 
					/>
				</div>
				<div class="form-group">
					<SelectInput 
						above 
						label="Parent" 
						name="parent" 
						bind:value={settingsModal.parent} 
						choices={parents.filter(p => p.id !== settingsModal.id)} 
						noValidation 
						placeholder="No Parent" 
					/>
				</div>

				<div class="form-group">
					<Image label="Menu Image" bind:content={settingsModal.properties.image} /> 
				</div>
			</div>
			<div class="actions" slot="actions">
				<Btn block red on:click={() => settingsModal = false}>
					No
				</Btn> 
				<Btn block green on:click={updateItem}>
					Yes
				</Btn> 
			</div>
        </Card>
    </Modal>
{/if}
<style>
.actions {
    display: flex;
}

.form-grid {
	display: grid;
	grid-row-gap: 10px;
}

.layout {
	display: grid;


}</style>