<script>
	import { slide } from 'svelte/transition'
	import { onMount } from 'svelte'
	import Sortable from 'sortablejs'
	import { clickOutside } from '../../../utils/click-outside.js'
	import Btn from '../../../components/Btn.svelte'
	import { v4 as uuid } from 'uuid'
	import Columns from './Columns.svelte'
	import Blocks from './Blocks.svelte'
	import { plans } from '../../../stores/app.store.js'
	import { mediaLibrary } from '../stores/media-library.store.js'
	import CircleStyles from './CircleStyles.svelte';
	import { checkProperties } from '../utils.js';

    export let blocks = []      
	export let blockTypes = []   
	export let errors = []
	export let title = ''
	export let fullwidth = false
	export let row = null

	let rowsEl
	let show = []

	let newRowPopout = false
	let popoutOpen = false
	let protectOpen = false
	let editTitle = false;
	
	$: blocks = checkProperties(blocks, true)

	function addRow (index = null) {			
		let id = uuid()

		let newBlock = {
			id,
			name: 'Row',
			component: 'RowComponent',		
			content: [],
			properties: { style: {}, container: false, meta: {} },
			order: 99,
			block_id: row
		}

		if (index !== null) {
			blocks.splice(index + 1, 0, {
				...newBlock,
				order: Math.max(blocks.map(b => b.order)) + 1
			})

			reorder(blocks)

			popoutOpen = false
		} else {
			blocks.push({
				...newBlock,
				order: Math.max(blocks.map(b => b.order)) + 1
			})
			
			reorder(blocks)

			newRowPopout = false
		}

		if (show.includes(id) === false) {
			show = [
				...show,
				id
			]
		}
	}
	
	function removeRow (id) {
		show = show.filter(s => s !== id)
		reorder(blocks.filter(b => b.id !== id))		
	}

	function reorder (newBlocks) {
		let i = 1
		
		for (let index in newBlocks) {
			newBlocks[index].order = i
			i++
		}

		blocks = newBlocks
	}

	function addRestriction (block, id) {
		let index = blocks.findIndex(b => b.id === block.id)

		if (index === undefined) return

		if (block.properties.protect === undefined) {
			block.properties.protect = []
		}

		if (id === null) {
			block.properties.protect = []
		} else if (block.properties.protect.includes(id)) {
			block.properties.protect = block.properties.protect.filter(p => p !== id)
		} else {
			block.properties.protect = [
				...block.properties.protect,
				id
			]
		}

		blocks[index] = block
	}

	function toggleShow (id) {
		if (show.includes(id)) {
			show = show.filter(s => s !== id)
		} else {
			show = [
				...show,
				id
			]
		}
	}

	function toggleContainer (index) {
		let block = blocks[index]
		
		blocks[index].properties.container = blocks[index].properties.container === true ? false : true
	} 

	onMount(() => {
		if (row === null) return
		Sortable.create(rowsEl, {
			draggable: '.row',
			handle: '.row .header .number',
			store: {
				/**
				 * Get the order of elements. Called once during initialization.
				 * @param   {Sortable}  sortable
				 * @returns {Array}
				 */
				get: function (sortable) {					
					return blocks.map(i => i.order);
				},

				/**
				 * Save the order of elements. Called onEnd (when the item is dropped).
				 * @param {Sortable}  sortable
				 */
				set: function (sortable) {					
					let newOrder = sortable.toArray()
					let newBlocks = []
					for (let index in newOrder) {			
						newBlocks.push(blocks.find(b => b.id === newOrder[index]))
					}

					reorder(newBlocks)
				}
			}
		})
	})
</script>
<div class="layout">
	{#if row === null}
		<Blocks 
			bind:blocks={blocks}
			{blockTypes}
			{errors}
			{title}
		/>	
	{:else}
		<div class="rows" bind:this={rowsEl}>
			{#each blocks as block, index (block.id)}
				<div class="row" data-id={block.id}>
					<div class="header">
						<div class="number" title="reorder block">
							{block.order}
						</div>
						<div class="name">
							{#if editTitle === block.id}
								<form on:submit|preventDefault={() => editTitle = false}>
								<input class="editTitle" bind:value={block.properties.meta.title}  use:clickOutside on:clickOutside={() => editTitle = false} />
								({block.name})
								</form>
							{:else if block.properties.meta.title}
								{block.properties.meta.title} ({block.name})
							{:else}
								{block.name}
							{/if}
						</div>		
						<div class="circle title" title="Edit column alias" on:click={() => editTitle = block.id}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 24.6875 4.03125 C 23.851563 4.03125 23.039063 4.367188 22.40625 5 L 22.3125 5.09375 L 21.6875 4.5 L 5.1875 21 L 5.125 21.3125 L 4.03125 26.8125 L 3.71875 28.28125 L 5.1875 27.96875 L 10.6875 26.875 L 11 26.8125 L 27.5 10.3125 L 26.90625 9.71875 L 26.96875 9.65625 L 27 9.59375 C 28.265625 8.328125 28.265625 6.265625 27 5 C 26.367188 4.367188 25.523438 4.03125 24.6875 4.03125 Z M 24.6875 6 C 25 6 25.328125 6.140625 25.59375 6.40625 C 26.128906 6.941406 26.128906 7.652344 25.59375 8.1875 L 25.5 8.28125 L 23.71875 6.5 L 23.8125 6.40625 C 24.078125 6.140625 24.375 6 24.6875 6 Z M 21.71875 7.3125 L 24.6875 10.28125 L 23.25 11.75 L 20.25 8.75 Z M 18.875 10.1875 L 21.8125 13.125 L 11.375 23.59375 L 10.96875 21.78125 L 10.84375 21.15625 L 10.21875 21.03125 L 8.40625 20.625 Z M 6.96875 22.34375 L 9.15625 22.84375 L 9.65625 25.03125 L 7.625 25.4375 L 6.5625 24.375 Z"/></svg>
						</div>		
						{#if $plans.plans !== false && $plans.loaded === true}
						<div class="circle protect" on:click={() => protectOpen = block.id} title="Content protection">
							{#if block.properties.protect === undefined || block.properties.protect.length === 0 }
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 3 C 12.964844 3 10.414063 4.964844 9.375 7.625 L 11.21875 8.375 C 11.976563 6.433594 13.835938 5 16 5 C 18.753906 5 21 7.246094 21 10 L 21 13 L 6 13 L 6 29 L 26 29 L 26 13 L 23 13 L 23 10 C 23 6.15625 19.84375 3 16 3 Z M 8 15 L 24 15 L 24 27 L 8 27 Z"/></svg>
							{:else}
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 3 C 12.15625 3 9 6.15625 9 10 L 9 13 L 6 13 L 6 29 L 26 29 L 26 13 L 23 13 L 23 10 C 23 6.15625 19.84375 3 16 3 Z M 16 5 C 18.753906 5 21 7.246094 21 10 L 21 13 L 11 13 L 11 10 C 11 7.246094 13.246094 5 16 5 Z M 8 15 L 24 15 L 24 27 L 8 27 Z"/></svg>
							{/if}
							{#if protectOpen === block.id}
							<div class="popout" use:clickOutside on:clickOutside={() => protectOpen = false}>
								<div class="item" on:click={() => addRestriction(block, null)}>
									<div class="name">
										No restriction
									</div>
									<div class="in-selection">
										{#if block.properties.protect === undefined || block.properties.protect.length === 0}
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>               
										{/if}
									</div>
								</div>
								<div class="item" on:click={() => addRestriction(block, 'guests')}>
									<div class="name">
										Guests only
									</div>
									<div class="in-selection">
										{#if block.properties.protect !== undefined && block.properties.protect.includes('guests')}
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>               
										{/if}
									</div>
								</div>
								{#each $plans.plans as item}
									<div class="item" on:click={() => addRestriction(block, item.id)}>
										<div class="name">
											{item.name}
										</div>
										<div class="in-selection">
											{#if block.properties.protect !== undefined && block.properties.protect.includes(item.id)}
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>               
											{/if}
										</div>
									</div>
								{/each}
							</div>
							{/if}
						</div>
						{/if}		

						{#if fullwidth}					
						<div class="circle fullwidth" title="{block.properties.container !== true ? 'Full width' : 'Contain'}" class:green={block.properties.container !== true} on:click={() => toggleContainer(index)}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 9.28125 6.78125 L 0.78125 15.28125 L 0.09375 16 L 0.78125 16.71875 L 9.28125 25.21875 L 10.71875 23.78125 L 3.9375 17 L 28.0625 17 L 21.28125 23.78125 L 22.71875 25.21875 L 31.21875 16.71875 L 31.90625 16 L 31.21875 15.28125 L 22.71875 6.78125 L 21.28125 8.21875 L 28.0625 15 L 3.9375 15 L 10.71875 8.21875 Z"/></svg>
						</div>				
							{#if block.properties.container}
								<CircleStyles title="Container Styles" bind:properties={blocks[index].properties.containerProperties} />
							{/if}
						{/if}

						<CircleStyles title="Row Styles" bind:properties={blocks[index].properties} />
						
						<div class="circle add" title="Add row below" on:click={addRow(index)}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 15 5 L 15 15 L 5 15 L 5 17 L 15 17 L 15 27 L 17 27 L 17 17 L 27 17 L 27 15 L 17 15 L 17 5 Z"/></svg> 						
						</div>
						<div class="circle remove" on:click={() => removeRow(block.id)} title="Remove row">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>
						</div>
						<div class="circle expander" class:open={show.includes(block.id)} on:click={() => toggleShow(block.id)}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z"/></svg>
						</div>
					</div>
					{#if show.includes(block.id)}
					<div class="body" transition:slide>	
						<Columns 
							bind:blocks={block.content}
							{blockTypes}
							{errors}
							{title}
						/>
					</div>		
					{/if}
				</div>
				{:else}
				<div class="message">
					You don't have any rows 
					<Btn on:click={() => addRow(index)}>
						Add Row	
					</Btn>
				</div>
				{/each}
		</div>
	{/if}
</div>

<style>
.rows {
    background: #FFF;
    border: 1px solid #ddd;
}
.rows .row {
    width: 100%;
    border-bottom: 1px solid #ddd;
}
.rows .row:last-of-type {
    border-bottom: none;
}
.rows .row .header {
    display: flex;
    background: #fafafa;
    padding: 10px 20px;		
    align-items: center;
    justify-content: space-between;
}
.rows .row.error .header {
    background: #f44336;
    color: #FFF;
}
.rows .row .header .number {
    background: #FFF;
    border-radius: 50%;
    height: 1.5em;
    width: 1.5em;
    line-height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    cursor: move;
    color: #333;
}
.rows .row .header .name {
    margin: 0 15px;
    flex-grow: 1;
    line-height: 1em;
}
.rows .row .header .grabber svg {
    width: 1.5em;
    height: 1.5em;
}
.rows .row .header .circle {
    position: relative;
    background: #FFF;
    border-radius: 50%;
    height: 1.5em;
    width: 1.5em;
    line-height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    margin-left: 10px;
    cursor: pointer;	
    transition: all 150ms ease-in;
}
.rows .row .header .circle:hover {
    background: #ececec;
}
.rows .row .header .circle.green {
    background: #28a745;
}
.rows .row .header .circle.green svg {
    fill: #fff;
}
.rows .row .header .circle svg {
    width: 1em;
    height: 1em;
}
.rows .row .header .circle.expander.open {
    transform: rotate(180deg)
}
.rows .row .body {
    border-top: 1px solid #ddd;
    padding: 20px;
}
.rows .message {
    display: grid;
    background: #fafafa;
    padding: 10px 20px;		
    align-items: center;
    justify-content: center;
    line-height: 1em;
    font-size: 1.2em;
    font-weight: 300;
    grid-row-gap: 10px;
}
.rows .message :global(button) {
    position: relative;
    margin-bottom: 0;
}
.popout {
    position: absolute;
    top: calc(100% - 10px);
    right: 0;
    background: #FFF;
    border: 1px solid #ddd;
    width: auto;
    white-space: nowrap;
    padding: 15px 15px;
    display: grid;
    grid-row-gap: 15px;
    font-size: 16px;
    font-weight: 400;
    color: #737373;
    cursor: pointer;
    z-index: 1000;
    text-align: left;
}
.popout .item:hover {
    color: #fd7e14;
}
.popout a {
    color: #737373;
}
.popout a:hover {
    color: #fd7e14;
}
.popout .loader {
    padding: 10px 50px;
}
.protect .popout .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.protect .popout .item .name {
    flex-grow: 1;
    margin: 0;
    padding-right: 5px;
}
.protect .popout .item svg {
    height: 1em;
    width: 1em;
}
.protect .popout .item svg path {
    fill: #28a745;
}</style>