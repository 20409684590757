<script>
    import Btn from '../../../components/Btn.svelte'
    import Card from '../../../components/Card.svelte'    
    import TextInput from '../../../components/form/TextInput.svelte'
    import MarkdownEditor from '../../../components/form/MarkdownEditor.svelte'
    import ApiService from '../../../services/api.service.js'
    import { validator, required, minLength } from '../../../validators'
    import PageBuilder from '../../../modules/site-editor/components/PageBuilder.svelte'
    import TemplateSelector from '../../../modules/site-editor/components/TemplateSelector.svelte'
    import { slide } from 'svelte/transition'
    import DatePicker from '../../../components/DatePicker.svelte'
    import { url, goto } from "@sveltech/routify";
    import { isAfter } from 'date-fns'
    import Modal from '../../../components/Modal.svelte'
    import LoaderIcon from '../../../components/LoaderIcon.svelte'
    import Preview from '../../../modules/site-editor/components/Preview.svelte'
    import Image from '../../../modules/site-editor/blocks/Image.svelte'
    import File from '../../../modules/site-editor/blocks/File.svelte'
    import LinkedModel from '../../../modules/site-editor/blocks/LinkedModel.svelte'
    import { site } from '../../../stores/sites.store.js'
    import Slug from '../../../modules/site-editor/components/Slug.svelte'
    import Dropzone from '../../../modules/site-editor/components/Dropzone.svelte'
    import FilePreview from '../../../modules/site-editor/components/FilePreview.svelte'

    export let id

    let model = false    
    let previous = JSON.stringify(false)
    let now = new Date()
    let saving = false
    let previewing = false
    let deleteing = false
    let restoring = false
    let unpublishing = false
    let deleteModal = false
    let previewModal = false
    let previewUrl = false
    let nameTouched = false
    let replace = false
    let rules = {
        name: [
            { 
                rule: required
            },
            {
                rule: minLength,
                params: {
                    length: 2
                }
            }
        ]
    }

    let errorMessages = {
        required: 'This is a required field',
        minLength: 'This field must be longer than %length%'
    }

    let blockErrors = []
    let errorFileType

    $: create = id === 'add' || id === 'new'
    $: getModel(id)
    $: unsaved = previous != JSON.stringify(toSave(model))    
    $: loading = model === false || model === undefined
    $: nameErrors = validator(model.name, rules.name, errorMessages)
    $: isValid = nameErrors === false
    $: disabled = saving === true || deleteing === true || restoring === true || unpublishing === true || blockErrors.length > 0    
    $: acceptedTypes = model.type === 'file' ? ['pdf'] : [ 'jpg', 'jpeg', 'png', 'webp', 'svg' ]
    $: allowedTypes = model.type === 'file' ? [ 'application/pdf'] : [ 'image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'image/svg+xml' ]

    async function getModel(id) {
        if (id === undefined) return        
        let url = 'site-editor/media/' + (create === true ? 'create' : id)

        try {
            let { data } = await ApiService.get(url)

            model = data

            setPrevious(model)
        } catch (error) {
            console.error(error)
        }
    }

    function toSave (object) {
        if (typeof object !== 'object') return object
        let obj = {
            name: object.name
        }

        if (object.new_file) {
            obj.new_file = object.new_file
        }

        return obj
    }

    async function save() {
        if (disabled === true) return
        if (saving === true) return

        if (isValid === false) {
            forceValidation()        
            return
        }

        if (unpublishing !== true) saving = true

        let url = 'site-editor/media'
        if (create === false) url += '/' + id

        try {            
            let formData = new FormData()

            let payload = toSave(model);

            for (let key in payload) {
                formData.append(key, payload[key])
            }

            let response = await ApiService.post(url, formData, {
                onUploadProgress: (progressEvent) => {
                    if (payload.new_file) {
                        model.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }
                }
            })
            
            model = response.data
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            saving = false
        }
    }

    async function unpublish() {
        if (disabled === true) return
        unpublishing = true
        model.published_at = null
        await save()        
        unpublishing = false
    }

    async function bin() {
        if (disabled === true) return
        if (deleteing === true) return
        deleteing = true

        try {            
            let response = await ApiService.delete('site-editor/media' + '/' + id)
            
            model = response.data
            deleteModal = false
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            deleteing = false            
        }
    }

    async function restore() {
        if (disabled === true) return
        if (restoring === true) return
        restoring = true

        try {            
            let response = await ApiService.get('site-editor/media' + '/' + id + '/restore')
            
            model = response.data
            
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            restoring = false            
        }
    }

    async function preview() {
        if (disabled === true) return
        if (previewing === true) return
        previewing = true

        try {
            let { data } = await ApiService.post('site-editor/preview', {
                'name': model.name,
                'blocks': model.blocks,
                'properties': model.properties,
                'linked': model.linked
            })

            previewModal = true
            previewUrl = data.site + '/models/' + model.slug + '/?preview=' + data.uuid
        } catch {

        } finally {
            previewing = false
        }
    }

    function forceValidation () {
        nameTouched = true
    }

    function reset(id) {
        if (create === true) {
            $goto($url('../:id', { id }))
        }
        nameErrors = false
        nameTouched = false
        setPrevious(model)
    }

    function setPrevious(current) {
        setTimeout (() => {
            previous = JSON.stringify(toSave(current))
        }, 250)
    }

    function processNewFile (event) {
        if (event.detail === undefined) return
        for (let file of event.detail) {
            if (allowedTypes.includes(file.type)) {
                model.new_file = file
                errorFileType = undefined
            } else {
                errorFileType = 'Selected file does not match the accepted types'
            }
            // only proccess 1
            continue
        }
    }
</script>
<div class="grid">

    <div class="main-col">
        <div class="content-card">
            <Card title="Basic Information" loading={loading === true}>                     
                <div class="form">
                    <div class="form-group">
                        <TextInput label="Title" name="name" bind:value={model.name} errors={nameErrors} touched={nameTouched} />
                    </div>  

                    <div class="form-group">
                        <TextInput label="Hash" disabled name="hash" bind:value={model.hash} />
                    </div> 

                    <div class="form-group file">
                        <div class="current">
                            {#if model.type === 'media'}
                                <label>Current Media</label>
                            {:else if model.type === 'file'}
                                <label>Current File</label>
                            {/if}
                            <img class="image" src={model.url + '?hash=' + model.hash} alt={model.name} />
                            {#if model.progress}
                                <div class="progress" style={{width: model.progress + '%'}}></div>
                            {/if}
                        </div>
 
                        <div class="new">
                            {#if model.type === 'media'}
                                <label>Replace Media</label>
                            {:else if model.type === 'file'}
                                <label>Replace File</label>
                            {/if}

                            {#if model.new_file}
                                <div class="preview">
                                    <FilePreview file={model.new_file} /> 
                                </div>
                                <div class="name">
                                    {model.new_file.name}
                                </div>
                                <Btn red on:click={() => model.new_file = undefined}>
                                    Reset
                                </Btn>
                            {:else}
                                <Dropzone
                                    on:selected={processNewFile}
                                >
                                    <div class="upload">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"/></svg>								
                                        
                                        <div class="message">
                                            Click to add or drag files here to upload.
                                        </div>

                                        <div class="accepted message">
                                            Accepted file types {acceptedTypes.join(', ')}
                                        </div>

                                        {#if errorFileType}
                                            <div class="message error">
                                                {errorFileType}
                                            </div>
                                        {/if}
                                    </div>
                                </Dropzone>
                            {/if}
                        </div>
                    </div>
                </div>    
            </Card>
        </div>

    </div>

    <div class="sidebar">
        <div class="sidebar-card">           
            <div class="actions">   
                <Btn block green on:click={save} {disabled} loading={saving === true}>
                    Save
                </Btn>   
            </div>     
        </div>

        

        <div class="sidebar-card">
            <Card loading={loading === true}>
                <div class="form">       
                    {#if model.created_at !== null}
                    <div class="form-group">
                        <DatePicker label="Created" readonly value={model.created_at} />                           
                    </div>
                    {/if}
                    
                    {#if model.updated_at !== null}
                    <div class="form-group">
                        <DatePicker label="Updated" readonly value={model.updated_at} />
                    </div> 
                    {/if}  

                    <div class="form-group">
                        Status: {model.status === null ? 'Unsaved Draft' : model.status.value}
                    </div>    
                
                    {#if model.deleted_at !== null}
                    <div class="form-group">
                        <DatePicker label="Deleted" readonly value={model.deleted_at} />
                    </div> 
                    {/if}  

                    
                </div>
                <div class="actions" slot="actions"> 
                    {#if model.status !== null && model.deleted_at === null}
                    <Btn block red on:click={() => deleteModal = true} {disabled}>
                        Delete
                    </Btn> 
                    {/if}
                    {#if model.status !== null && model.deleted_at !== null}
                    <Btn block orange on:click={restore} {disabled} loading={restoring}>
                        Restore
                    </Btn> 
                    {/if}
                </div>
            </Card>
        </div>  
    </div>    
</div>

{#if unsaved === true}
    <div class="sidebar-card warning unsaved" transition:slide|local={{delay: 200}}>
        <Card>
            You have unsaved changed
        </Card>            
    </div>
{/if}

{#if deleteModal === true}
    <Modal>
        <Card title="Delete media">
            <div class="delete-modal">
                {#if loading === false}
                <div class="message">
                    Are you sure you want to delete this media?
                </div>
                {:else}
                    <LoaderIcon />
                {/if}
            </div>
            <div class="actions" slot="actions">
                <Btn block red on:click={() => deleteModal = false}>
                    No
                </Btn> 
                <Btn block green on:click={bin}>
                    Yes
                </Btn> 
            </div>
        </Card>
    </Modal>
{/if}

{#if previewModal === true}
    <Preview url={previewUrl} loading={previewing} on:close={() => previewModal = false } />
{/if}
<style>
.grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 40px;
    align-items: flex-start;
}

.main-col {
    display: grid;
    grid-row-gap: 40px;
    padding-bottom: 40px;
}

.content-card,
.sidebar-card {
    border: 1px solid #ddd;    
}

.sidebar-card {
    margin-bottom: 40px;
}

.actions {
    display: flex;
}

.actions .btn {
    width: 50%;
}

.form {
    display: grid;
    grid-row-gap: 20px;
}

.sidebar-card.warning {
    border-color: #ee7b00;
}

.sidebar-card.warning :global(.card .body) {    
    background: #ee7b00!important;
    color: #FFF;
}

.unsaved {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    text-align: center;
    z-index: 1;
}

.message {
    font-size: 18px;
    font-weight: 300;    
}

.delete-modal {
    display: flex;
    min-height: 6em;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.delete-modal .message {    
    padding: 0 20px;
}

label {
    font-size: 17px;
    color: #2c3e50;
    margin-bottom: 0.25em;
}

.file {
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: 50px;
}

.image {
    max-width: 100%;
}

.upload {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.upload svg {
    max-width: 300px;
    height: auto;
}

.upload svg path {
    fill: rgba(0,0,0,0.54);
}

.upload .message {
    font-size: 22px;
    color: #8c8c8c;
    font-weight: 300;
    margin-bottom: 7px;
}

.upload .message.accepted {
    font-size: 18px;
}

.upload .message.error {
    font-size: 18px;
    color: #f44336;
}

.progress {
    background: #4CAF50;
    height: 20px;
}</style>