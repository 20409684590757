<script>
    import Btn from '../../../../components/Btn.svelte'
    import Card from '../../../../components/Card.svelte'    
    import TextInput from '../../../../components/form/TextInput.svelte'
    import CheckboxInput from '../../../../components/form/CheckboxInput.svelte'
    import MarkdownEditor from '../../../../components/form/MarkdownEditor.svelte'
    import ApiService from '../../../../services/api.service.js'
    import { validator, required, minLength } from '../../../../validators'
    import PropertiesEditor from '../../../../modules/site-editor/components/PropertiesEditor.svelte'
    import TemplateSelector from '../../../../modules/site-editor/components/TemplateSelector.svelte'
    import { slide } from 'svelte/transition'
    import DatePicker from '../../../../components/DatePicker.svelte'
    import { url, goto } from "@sveltech/routify";
    import { isAfter } from 'date-fns'
    import Modal from '../../../../components/Modal.svelte'
    import LoaderIcon from '../../../../components/LoaderIcon.svelte'
    import Preview from '../../../../modules/site-editor/components/Preview.svelte'
    import LinkedModel from '../../../../modules/site-editor/blocks/LinkedModel.svelte'
    import { site } from '../../../../stores/sites.store.js'
    import { titleCase, pluralise } from '../../../../utils/helpers.js'

    export let id

    const title = 'template'
    const apiRoute = 'site-editor/templates'

    let model = false    
    let previous = JSON.stringify(false)
    let now = new Date()
    let saving = false
    let previewing = false
    let deleteing = false
    let restoring = false
    let unpublishing = false
    let deleteModal = false
    let previewModal = false
    let previewUrl = false
    let nameTouched = false
    let rules = {
        name: [
            { 
                rule: required
            },
            {
                rule: minLength,
                params: {
                    length: 2
                }
            }
        ]
    }

    let blockErrors = []

    let errorMessages = {
        required: 'This is a required field',
        minLength: 'This field must be longer than %length%'
    }

    $: create = id === 'add' || id === 'new'
    $: getModel(id)
    $: unsaved = previous != JSON.stringify(toSave(model))    
    $: loading = model === false || model === undefined
    $: nameErrors = validator(model.name, rules.name, errorMessages)
    $: isValid = nameErrors === false && blockErrors.length === 0
    $: disabled =  saving === true || deleteing === true || restoring === true || unpublishing === true

    async function getModel(id) {
        if (id === undefined) return        
        let url = apiRoute + '/' + (create === true ? 'create' : id)

        try {
            let { data } = await ApiService.get(url)

            model = data

            setPrevious(model)
        } catch (error) {
            console.error(error)
        }
    }

    function toSave (object) {
        if (object === undefined) return undefined
        if (typeof object !== 'object') return object
        return {
            name: object.name,
            linked: object.linked,
            properties: object.properties,
            fullwidth: object.fullwidth
        }
    }

    async function save() {
        if (saving === true) return

        if (disabled === true) return

        if (isValid === false) {
            forceValidation()        
            return
        }

        if (unpublishing !== true) saving = true

        let url = apiRoute
        if (create === false) url += '/' + id

        try {            
            let response = await ApiService.post(url, toSave(model))
            
            model = response.data
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            saving = false
        }
    }

    async function unpublish() {
        unpublishing = true
        model.published_at = null
        await save()        
        unpublishing = false
    }

    async function bin() {
        if (disabled === true) return
        if (deleteing === true) return
        deleteing = true

        try {            
            let response = await ApiService.delete(apiRoute + '/' + id)
            
            model = response.data
            deleteModal = false
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            deleteing = false            
        }
    }

    async function restore() {
        if (disabled === true) return
        if (restoring === true) return
        restoring = true

        try {            
            let response = await ApiService.get(apiRoute + '/' + id + '/restore')
            
            model = response.data
            
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            restoring = false            
        }
    }

    function forceValidation () {
        nameTouched = true
    }

    function reset(id) {
        if (create === true) {
            $goto($url('../:id', { id }))
        }
        nameErrors = false
        nameTouched = false
        setPrevious(model)
    }

    function setPrevious(current) {
        setTimeout (() => {
            previous = JSON.stringify(toSave(current))
        }, 250)
    }
</script>
<div class="grid">

    <div class="main-col">
        <div class="content-card">
            <Card title="Basic Information" loading={loading === true}>                     
                <div class="form">
                    <div class="form-group">
                        <TextInput 
                            label="Title" 
                            name="name" 
                            bind:value={model.name} 
                            errors={nameErrors} 
                            touched={nameTouched} 
                        />
                    </div>
                    <div class="form-group">
                        <CheckboxInput 
                            label="Full width" 
                            name="fullwidth" 
                            bind:value={model.fullwidth} 
                        />
                    </div>
                    <div class="form-group">
                        <LinkedModel 
                            label="Row Block"
                            bind:selected={model.linked.row} 
                            unselected="No row template selected" 
                            placeholder="Search for row"
                            model="blocks"
                            limit={1}
                        />
                    </div>       
                </div>    
            </Card>
        </div>

        <div class="content-card">
            <Card title="Properties" loading={loading === true}>
                <PropertiesEditor 
                    bind:blocks={model.properties} 
                />
            </Card>
        </div>

        <div class="content-card">
            <Card title="Blocks" loading={loading === true}>
                <LinkedModel 
                    bind:selected={model.linked.blocks} 
                    unselected="No blocks selected" 
                    placeholder="Search for blocks"
                    model="blocks"
                    ordered
                    let:item let:nameLabel let:remove
                >  
                    <div slot="result-item" let:item let:selected let:isSelected>
                        <div class="name">
                            {item.name} - {item.component}
                        </div>
                        <div class="in-selection">
                            {#if isSelected(item, selected) === true}
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>               
                            {/if}
                        </div>
                    </div>
                    <div class="dragger">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 3.59375 L 15.28125 4.28125 L 7.28125 12.28125 L 5.59375 14 L 26.40625 14 L 24.71875 12.28125 L 16.71875 4.28125 Z M 16 6.4375 L 21.5625 12 L 10.4375 12 Z M 5.59375 18 L 7.28125 19.71875 L 15.28125 27.71875 L 16 28.40625 L 16.71875 27.71875 L 24.71875 19.71875 L 26.40625 18 Z M 10.4375 20 L 21.5625 20 L 16 25.5625 Z"/></svg>
                    </div>
                    <div class="name">
                        {item.name} - {item.component}
                    </div>

                    <div class="remove" on:click={() => remove(item)}>                
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>                
                    </div>
                </LinkedModel>
            </Card>
        </div>
    </div>

    <div class="sidebar">
        <div class="sidebar-card">           
            <div class="actions"> 
                <Btn block green on:click={save} {disabled} loading={saving === true}>
                    Save
                </Btn>   
            </div>     
        </div>

        <div class="sidebar-card">
            <Card loading={loading === true}>
                <div class="form">       
                    {#if model.created_at !== null}
                    <div class="form-group">
                        <DatePicker label="Created" readonly value={model.created_at} />                           
                    </div>
                    {/if}
                    
                    {#if model.updated_at !== null}
                    <div class="form-group">
                        <DatePicker label="Updated" readonly value={model.updated_at} />
                    </div> 
                    {/if}  
                    
                    <div class="form-group">
                        Status: {model.status === null ? 'Unsaved Draft' : model.status.value}
                    </div>    
                    
                    {#if model.deleted_at !== null}
                    <div class="form-group">
                        <DatePicker label="Deleted" readonly value={model.deleted_at} />
                    </div> 
                    {/if}                      
                </div>
                <div class="actions" slot="actions">                                        
                    {#if model.status !== null && model.deleted_at === null}
                    <Btn block red on:click={() => deleteModal = true} {disabled}>
                        Delete
                    </Btn> 
                    {/if}
                    {#if model.status !== null && model.deleted_at !== null}
                    <Btn block orange on:click={restore} {disabled} loading={restoring}>
                        Restore
                    </Btn> 
                    {/if}
                </div>
            </Card>
        </div>  
    </div>    
</div>

{#if unsaved === true}
    <div class="sidebar-card warning unsaved" transition:slide|local={{delay: 200}}>
        <Card>
            You have unsaved changed
        </Card>            
    </div>
{/if}

{#if deleteModal === true}
    <Modal>
        <Card title="Delete Widget Block">
            <div class="delete-modal">
                {#if loading === false}
                <div class="message">
                    Are you sure you want to delete this widget block?
                </div>
                {:else}
                    <LoaderIcon />
                {/if}
            </div>
            <div class="actions" slot="actions">
                <Btn block red on:click={() => deleteModal = false}>
                    No
                </Btn> 
                <Btn block green on:click={bin}>
                    Yes
                </Btn> 
            </div>
        </Card>
    </Modal>
{/if}

{#if previewModal === true}
    <Preview url={previewUrl} loading={previewing} on:close={() => previewModal = false } />
{/if}
<style>
.grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 40px;
    align-items: flex-start;
}

.main-col {
    display: grid;
    grid-row-gap: 40px;
    padding-bottom: 40px;
}

.content-card,
.sidebar-card {
    border: 1px solid #ddd;    
}

.sidebar-card {
    margin-bottom: 40px;
}

.actions {
    display: flex;
}

.actions .btn {
    width: 50%;
}

.form {
    display: grid;
    grid-row-gap: 20px;
}

.sidebar-card.warning {
    border-color: #ee7b00;
}

.sidebar-card.warning :global(.card .body) {    
    background: #ee7b00!important;
    color: #FFF;
}

.unsaved {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    text-align: center;
    z-index: 1;
}

.message {
    font-size: 18px;
    font-weight: 300;    
}

.delete-modal {
    display: flex;
    min-height: 6em;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.delete-modal .message {    
    padding: 0 20px;
}</style>