<script>
    import Btn from '../../../../components/Btn.svelte'
    import Card from '../../../../components/Card.svelte'    
    import TextInput from '../../../../components/form/TextInput.svelte'
    import MarkdownEditor from '../../../../components/form/MarkdownEditor.svelte'
    import ApiService from '../../../../services/api.service.js'
    import { validator, required, minLength } from '../../../../validators'
    import PropertiesEditor from '../../../../modules/site-editor/components/PropertiesEditor.svelte'
    import TemplateSelector from '../../../../modules/site-editor/components/TemplateSelector.svelte'
    import { slide } from 'svelte/transition'
    import DatePicker from '../../../../components/DatePicker.svelte'
    import { url, goto } from "@sveltech/routify";
    import { isAfter } from 'date-fns'
    import Modal from '../../../../components/Modal.svelte'
    import LoaderIcon from '../../../../components/LoaderIcon.svelte'
    import Preview from '../../../../modules/site-editor/components/Preview.svelte'
    import LinkedModel from '../../../../modules/site-editor/blocks/LinkedModel.svelte'
    import { site } from '../../../../stores/sites.store.js'
    import { titleCase, pluralise } from '../../../../utils/helpers.js'

    const title = 'theme'
    const apiRoute = 'site-editor/theme'

    let model = []    
    let previous = JSON.stringify(false)
    let now = new Date()
    let saving = false
    let previewing = false
    let deleteing = false
    let restoring = false
    let unpublishing = false
    let deleteModal = false
    let previewModal = false
    let previewUrl = false
    let nameTouched = false
    let rules = {
        name: [
            { 
                rule: required
            },
            {
                rule: minLength,
                params: {
                    length: 2
                }
            }
        ]
    }

    let blockErrors = []

    let errorMessages = {
        required: 'This is a required field',
        minLength: 'This field must be longer than %length%'
    }

    $: getModel()
    $: unsaved = previous != JSON.stringify(toSave(model))    
    $: loading = model === false || model === undefined
    $: nameErrors = validator(model.name, rules.name, errorMessages)
    $: isValid = blockErrors.length === 0
    $: disabled =  saving === true || deleteing === true || restoring === true || unpublishing === true
    
    function isEmpty(object) {
        if (typeof object === 'object' && Object.keys(object).length === 0) {
            return []
        } else {            
            return object
        }
    }

    async function getModel() {
          
        let url = apiRoute

        try {
            let { data } = await ApiService.get(url)

            model = isEmpty(data)

            setPrevious(model)
        } catch (error) {
            console.error(error)
        }
    }

    function toSave (object) {
        if (object === undefined) return undefined
        if (typeof object !== 'object') return object
        return object
    }

    async function save() {
        if (saving === true) return

        if (disabled === true) return

        if (isValid === false) return

        if (unpublishing !== true) saving = true

        let url = apiRoute

        try {            
            let response = await ApiService.post(url, toSave(model))
            
            model = isEmpty(response.data)

            reset()            
        } catch (error) {
            console.error(error)
        } finally {
            saving = false
        }
    }

    function reset() {
        setPrevious(model)
    }

    function setPrevious(current) {
        setTimeout (() => {
            previous = JSON.stringify(toSave(current))
        }, 250)
    }

    function addSection () {
        model = [
            ...model,
            {
                section: '',
                title: ' ',
                properties: []
            }
        ]
    }
</script>
<div class="grid">

    <div class="main-col">
        {#if (loading === false)}
            {#each model as section}
            <div class="content-card">
                <Card title={section.title} loading={loading === true}>  
                    <div class="form">
                        <div class="form-group">
                            <TextInput label="Title" bind:value={section.title} noValidation />
                        </div>

                        <div class="form-group">
                            <TextInput label="Section" bind:value={section.section} noValidation />
                        </div>               
                        
                        <PropertiesEditor 
                            bind:blocks={section.properties} 
                        />
                    </div>
                </Card>
            </div>
            {/each}

            <Btn block on:click={addSection}  {disabled}>
                Add section
            </Btn>
        {/if}
    </div>

    <div class="sidebar">
        <div class="sidebar-card">           
            <div class="actions">                 
                <Btn block green on:click={save} {disabled} loading={saving === true}>
                    Save
                </Btn>   
            </div>     
        </div>

    </div>    
</div>

{#if unsaved === true}
    <div class="sidebar-card warning unsaved" transition:slide|local={{delay: 200}}>
        <Card>
            You have unsaved changed
        </Card>            
    </div>
{/if}
<style>
.grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 40px;
    align-items: flex-start;
}

.main-col {
    display: grid;
    grid-row-gap: 40px;
    padding-bottom: 40px;
}

.content-card,
.sidebar-card {
    border: 1px solid #ddd;    
}

.sidebar-card {
    margin-bottom: 40px;
}

.actions {
    display: flex;
}

.actions .btn {
    width: 50%;
}

.form {
    display: grid;
    grid-row-gap: 20px;
}

.sidebar-card.warning {
    border-color: #ee7b00;
}

.sidebar-card.warning :global(.card .body) {    
    background: #ee7b00!important;
    color: #FFF;
}

.unsaved {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    text-align: center;
    z-index: 1;
}

.message {
    font-size: 18px;
    font-weight: 300;    
}

.delete-modal {
    display: flex;
    min-height: 6em;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.delete-modal .message {    
    padding: 0 20px;
}</style>