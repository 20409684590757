<script>
    import TextInput from '../../../components/form/TextInput.svelte'
    import Btn from '../../../components/Btn.svelte'
    
    export let label = false
    export let content = []
    export let term = 'part number'

    $: content = Array.isArray(content) ? content : []

    let value

    function add () {
        if (value.trim() === '') return
        content = [
            ...content,
            value
        ]
        value = ''
    }

    function remove(part) {
        content = content.filter(p => p !== part)
    }
</script>

{#if label !== false}
    <div class="label">
        <label>
            {label}
        </label>    
    </div>
{/if}

<div class="json-array">    
    <form class="input" on:submit|preventDefault={add}>
        <TextInput name="name" placeholder="Enter a new {term}" bind:value={value} noValidation />
        <Btn type="submit">
            Add
        </Btn>
    </form>
    
    <div class="items">
        {#each content as part}
            <div class="part">
                <div class="name">
                    {part}
                </div>  
                <Btn red on:click={() => remove(part)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 15 4 C 14.476563 4 13.941406 4.183594 13.5625 4.5625 C 13.183594 4.941406 13 5.476563 13 6 L 13 7 L 7 7 L 7 9 L 8 9 L 8 25 C 8 26.644531 9.355469 28 11 28 L 23 28 C 24.644531 28 26 26.644531 26 25 L 26 9 L 27 9 L 27 7 L 21 7 L 21 6 C 21 5.476563 20.816406 4.941406 20.4375 4.5625 C 20.058594 4.183594 19.523438 4 19 4 Z M 15 6 L 19 6 L 19 7 L 15 7 Z M 10 9 L 24 9 L 24 25 C 24 25.554688 23.554688 26 23 26 L 11 26 C 10.445313 26 10 25.554688 10 25 Z M 12 12 L 12 23 L 14 23 L 14 12 Z M 16 12 L 16 23 L 18 23 L 18 12 Z M 20 12 L 20 23 L 22 23 L 22 12 Z"/></svg>
                </Btn>
            </div>
        {:else}
            <div class="no-parts">No additional {term}s added</div>
        {/each}
    </div>
</div>

<style>
.label {
    font-size: 17px;     
    color: #2c3e50;
    margin-bottom: 0.25em;   
}

.json-array {
    display: grid;
    row-gap: 15px;
}

.input {
    display: flex;
    column-gap: 10px;
}

.items {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
}

.items .part {
    display: flex;
    column-gap: 5px;
    border: 1px solid #757575;        
}

.items .part .name {
    padding: 5px 10px;
    align-self: center;
}

.items .part :global(.btn) {
    padding: 5px 5px;
}

.items .part svg {
    width: 1em;
    height: auto;
}

.items .part svg path {
    fill: #FFF;
}</style>