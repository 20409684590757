<script>
    import { site, sites } from '../stores/sites.store.js'
    import { user } from '../stores/user.store.js'
    import { clickOutside } from '../utils/click-outside.js'
    import LoaderIcon from '../components/LoaderIcon.svelte'

    let active = undefined
    
    sites.getSites()
    
    $: getActive($sites, $site)

    let open = false

    function getActive ({items}, site) {
        active = items.find(s => s.id === site.id)
    }

    function changeSite (newSite) {
        if (newSite !== $site.id) {            
            sites.changeSite(newSite)        
        }
        
        open = false
    }
</script>
<div class="selector">
    <div class="selected" on:click={() => open = !open}>
        {#if $sites.loaded === false} 
            <div class="loader">
                <LoaderIcon />
            </div>
        {:else}
            {$site.name}
        {/if}        
    </div>

    {#if open}
    <div class="popout" use:clickOutside on:clickOutside={() => open = false}>
        {#if $sites.loaded === false}
            <div class="loader">
                <LoaderIcon />
            </div>
        {:else}
            {#each $sites.items as item}
                <div class="item" on:click={() => changeSite(item.id)}>
                    {item.name} {item.domain !== undefined ? '(' + item.domain + ')' : ''}
                </div>
            {:else}
                <div class="item">
                    You don't have any sites assigned to your account yet
                </div>
            {/each}
        {/if}        
    </div>
    {/if}
</div>

<style>
.selector {
    position: relative;
    display: flex;
    align-items: center;
}

.selected {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.popout {
    position: absolute;
    top: calc(100% - 10px);
    left: 0;
    background: #FFF;
    border: 1px solid #ddd;
    width: auto;
    white-space: nowrap;

    padding: 15px 15px;

    display: grid;
    grid-row-gap: 15px;
    font-size: 16px;
    font-weight: 400;

    color: #737373;
    cursor: pointer;
}

.popout .item:hover {
    color: #fd7e14;
}

.popout :global(a) {
    color: #737373;
}

.popout :global(a:hover) {
    color: #fd7e14;
}

.popout .loader {
    padding: 10px 50px
}</style>