<script>
  import NumberInput from "../../../components/form/NumberInput.svelte";
  import Btn from "../../../components/Btn.svelte";

  export let content = [];
  export let properties = {};

  $: content = isNull(content, []);
  $: properties = checkProperties(properties);

  function checkProperties(props) {
    props = setDefault(props, {});

    props.map = setDefault(props.map, {});
    props.markers = setDefault(props.markers, []);

    return props;
  }

  function setDefault(current, fallback) {
    return current === undefined || current === null ? fallback : current;
  }

  function isNull(current, fallback) {
    return current === null ? fallback : current;
  }

  function addMarker() {
    properties.markers.push({});
    properties.markers = properties.markers;
  }

  function removeMarker(index) {
    properties.markers = properties.markers.filter((_, i) => i !== index);
  }
</script>

<div class="grid">
  <div class="map title">Map</div>

  <div class="map lat">
    <NumberInput label="Lat" bind:value={properties.map.lat} type="number" />
  </div>

  <div class="map lng">
    <NumberInput label="Lng" bind:value={properties.map.lng} type="number" />
  </div>

  <div class="map zoom">
    <NumberInput label="Zoom" bind:value={properties.map.zoom} type="number" />
  </div>

  <div class="markers title">Markers</div>

  {#each properties.markers as _, index}
    <div class="marker lat">
      <NumberInput
        label="Lat"
        bind:value={properties.markers[index].lat}
        type="number"
      />
    </div>

    <div class="marker lng">
      <NumberInput
        label="Lng"
        bind:value={properties.markers[index].lng}
        type="number"
      />
    </div>

    <div class="marker remove">
      <Btn red on:click={() => removeMarker(index)}>Remove</Btn>
    </div>
  {:else}
    <div class="no-markers">No markers have been set</div>
  {/each}

  <div class="add">
    <Btn green on:click={addMarker}>Add</Btn>
  </div>
</div>

<style>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
  align-items: flex-end;
}

.title,
.no-markers,
.add {
  grid-column: 1 / -1;
}</style>
