<script>
    import Quill from '../../../components/form/Quill.svelte'
    
    export let content = '';
    export let properties = {};
    export let options = []
    export let reset
    export let label = false;

    $: content = isNull(content, '')

    $: properties = isNull(properties, {})

    $: readmore = setReadmore(options)

    function setReadmore () {
        let quill = options.find(o => o.key === 'quill')
        if (quill) {
            if (quill.exclude && quill.exclude.includes('readmore')) return false
        }

        return true
    }

    function isNull (current, fallback) {
        return current === null ? fallback : current
    }
</script>

{#if label !== false}
    <div class="label">
        <label>
            {label}
        </label>    
    </div>
{/if}

{#if content !== null}
<Quill {reset} bind:content={content} properties={options} {readmore} />
{/if}

<style>
.label {
    font-size: 17px;     
    color: #2c3e50;
    margin-bottom: 0.25em;   
}</style>