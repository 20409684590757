<script>
    import { onMount, createEventDispatcher } from 'svelte'
    import Cropper from 'cropperjs'
    import debounce from 'lodash/debounce'
    
    export let image
    export let minWidth = 70
    export let minHeight = 35
    export let fixedRatio = NaN
	
    const dispatch = createEventDispatcher()

    let original    
    let crop
    let cropper
    
	function initCropper (event) {
		cropper = new Cropper(original, {
			viewMode: 1,
			dragMode: 'none',
			autoCropArea: 1,
			moveable: false,
			rotatable: false,
			scalable: false,
			zoomable: false,
			crop: updateCrop,
			data: image.crop,
        })
	}
	
	function setCrop () {
        crop = cropper.getData(true)
	}
	
	function updateCrop (event) {
		debounce(setCrop, 100)()
	}	
    
    function close (event) {
		dispatch('close', {
            ...image,
            crop
        })
	}	
</script>

<svelte:head>
	<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.5.6/cropper.min.css" integrity="sha256-cZDeXQ7c9XipzTtDgc7DML5txS3AkSj0sjGvWcdhfns=" crossorigin="anonymous" />
</svelte:head>

<div class="blocker" on:click|self={close}>
	<div class="modal">
        <div class="header">
            <div class="title">Image Editor</div>
            
            <div class="close" on:click={close}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>
            </div>
        </div>

        <div class="body">
            <div class="image">
                <img bind:this={original} on:load={initCropper} src={image.media.url} alt={image.alt} />
            </div> 
        </div>
	</div>
</div>

<style>
.blocker {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0,0,0,0.4);		
	display: flex;
	align-items: center;
	justify-content: center;
       z-index: 1000;
}

.modal {
	position: relative;
       background: #FFF;
       display: flex;
       flex-direction: column;
       overflow: hidden;
       z-index: 2000;
}

.modal .close {		
	width: 30px;
	height: 30px;
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	cursor: pointer;
       margin-right: 15px;
}

.modal .close svg {
	width: 1em;
	height: 1em;
}

.modal .close svg path {
	fill: #fff;
}

.modal .close:hover {
	background: #fd7e14;
}

.modal .body {
       padding: 15px;
   }

.header {
       background: #000;
       display: flex;
       justify-content: space-between;
       align-items: center;
       color: #FFF;

       
}

.header .title {
	padding: 15px;
}

.body {
       flex-grow: 1;
       overflow: hidden;
   }

.image {
	position: relative;
}

.image img { 
	max-width: 80vw;
	max-height: 60vh;
	width: auto;
	height: auto;
       display: block;
}

.cropper {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	overflow: hidden;
}

.cropper .crop {
	position: absolute;
	border: 1px solid #000;
	box-sizing: border-box;
       display: flex;
       justify-content: stretch;
       align-items: stretch;
       background-image: url("data:image/svg+xml,%0AOpen SVG%0APaste markup%0ADemo%0AContribute%0AAbout%0APowered by the incredible SVGO 404 bytes → 238 bytes 58.91%25%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 580 400'%3E%3Cpath fill='%23fff' d='M-1-1h582v402H-1z'/%3E%3Cg%3E%3Cline x2='100%25' y1='33.333%25' y2='33.333%25' fill='none' stroke='%23000' stroke-linecap='undefined' stroke-linejoin='undefined' stroke-width='1.5'/%3E%3Cline x2='100%25' y1='66.666%25' y2='66.666%25' fill='none' stroke='%23000' stroke-linecap='undefined' stroke-linejoin='undefined' stroke-width='1.5'/%3E%3Cline x1='33.333%25' x2='33.333%25' y1='0%25' y2='100%25' fill='none' stroke='%23000' stroke-linecap='undefined' stroke-linejoin='undefined' stroke-width='1.5'/%3E%3Cline x1='66.666%25' x2='66.666%25' y1='0%25' y2='100%25' fill='none' stroke='%23000' stroke-linecap='undefined' stroke-linejoin='undefined' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E");
}

.cropper .crop .crop-lines {
       display: grid;
       grid-template-columns: 1fr 1fr 1fr;
       grid-template-rows: 1fr 1fr 1fr;
       width: 100%;
   }

.cropper .crop .crop-lines div {
       border-bottom: 1px solid #000;
       border-right: 1px solid #000;
       box-sizing: border-box;
   }

.cropper .crop .crop-lines div.last-column {
       border-right: none;
   }

.cropper .crop .crop-lines div.last-row {
       border-bottom: none;
   }

.cropper .handle {
	position: absolute;		
}

.cropper .handle.long {
	width: 30px;
	height: 5px;
	background: #333;		
}

.cropper .handle.tall {
	width: 5px;
	height: 30px;
	background: #333;
}

.cropper .handle.right {
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	cursor: e-resize;
}

.cropper .handle.left {
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	cursor: w-resize;
}

.cropper .handle.top {
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	cursor: n-resize;
}

.cropper .handle.bottom {
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	cursor: s-resize;
}

.cropper .handle.top-left {
	height: 15px;
	width: 15px;
	top: 0;
	left: 0;
	border-left: 5px solid #333;
	border-top: 5px solid #333;		
	cursor: nw-resize;
}

.cropper .handle.top-right {
	height: 15px;
	width: 15px;
	top: 0;
	right: 0;
	border-right: 5px solid #333;
	border-top: 5px solid #333;		
	cursor: ne-resize;
}

.cropper .handle.bottom-left {
	height: 15px;
	width: 15px;
	bottom: 0;
	left: 0;
	border-left: 5px solid #333;
	border-bottom: 5px solid #333;		
	cursor: sw-resize;
}

.cropper .handle.bottom-right {
	height: 15px;
	width: 15px;
	bottom: 0;
	right: 0;
	border-right: 5px solid #333;
	border-bottom: 5px solid #333;		
	cursor: se-resize;
}</style>