<script>
    import TextInput from '../../../components/form/TextInput.svelte'
    import { clickOutside } from '../../../utils/click-outside.js'
    import debounce from 'lodash/debounce'
    import ApiService from '../../../services/api.service.js'
    import { onMount } from 'svelte'
    import Sortable from 'sortablejs'
    import { flip } from 'svelte/animate'
    
    export let selected = []
    export let unselected = 'Nothing has been selected'
    export let placeholder = 'Search'
    export let model
    export let limit = false
    export let exclude = []
    export let nameLabel = 'name'
    export let per_page = 10
    export let ordered = false
    export let moduleRoute = 'site-editor'
    export let noSearch = false
    export let errors = false
    export let validation = false
    export let touched = false
    export let label = false
    export let disabled = false
    export let hideNoSelection = false
    export let route = ''

    let term = ''
    let result = false
    let loading = false
    let initalised = false
    let filters = {                
        per_page,
        sort_by: 'name', 
        sort_direction: 'asc',
        search: null,
        page: 1
    }
    let selectedSort
    let selectedEl
    let totalPages = 1

    $: setTerm(term)
    $: search(filters)
    $: items = limit === 1 ? selected === null ? [] : [selected] : selected
    $: hasOrder(initalised, ordered)
    $: valid = errors === false && validation === true && touched
    $: more = totalPages > filters.page

    onMount(() => {
        initalised = true
    })

    const setTerm = debounce(() => {
        filters = {
            ...filters,
            search: term,
            page: 1
        }
    }, 500)

    function clear () {
        term = null
        result = false
    }

    async function search () { 
        if (initalised === false) return
        if (loading === true) return    
        if (filters.search === undefined || filters.search === '' || filters.search === null) {
            clear()
            return
        }
        loading = true        

        try {
            let { data } = await ApiService.get(moduleRoute + '/' + model + '/' + route, filters)

            result = data.data.filter(d => exclude.includes(d.id) === false)

            totalPages = data.last_page

        } catch (error) {
            console.error(error)
        } finally {
            loading = false
        }
    }

    function toggle (item) {
        if (limit === 1) {
            add(item)
            clear()
            return
        }

        if (selected.find(i => i.id === item.id) === undefined) {
            add(item)            
        }
        
        if (result.length === 1) clear()
    }

    function reorder (old) {
        if (ordered === false) return old
        let state = []
        let order = 1
        for (let item of old) {
            item.order = order
            state.push(item)
            order++
        }
        return state
    }

    function add (item) {
        if (limit === 1) {
            selected = item
            return
        }
        if (ordered) item.order = selected.length + 1
        selected = reorder([
            ...selected,
            item
        ])
    }

    function remove (item) {
        if (limit === 1) {
            selected = null
            return
        }
        
        selected = reorder(selected.filter(i => i.id !== item.id))
    }

    function isSelected (item) {
        if (limit === 1) {
            return selected !== null && selected.id === item.id
        }
        
        return selected.find(i => i.id === item.id) !== undefined
    }

    function hasOrder () {
        if (initalised === false) return
        if (ordered === false && selectedSort === undefined) return
        if (ordered === false) {
            selectedSort.destroy()
            selectedSort = undefined
            return
        }

        selectedSort = new Sortable(selectedEl, {
			draggable: '.item',
			handle: '.dragger',
			store: {
				/**
				 * Get the order of elements. Called once during initialization.
				 * @param   {Sortable}  sortable
				 * @returns {Array}
				 */
				get: function (sortable) {					
					return reorder(selected);
				},

				/**
				 * Save the order of elements. Called onEnd (when the item is dropped).
				 * @param {Sortable}  sortable
				 */
				set: function (sortable) {					
                    let state = []
                    let item
                                        
					for (let key of sortable.toArray()) {	                        
                        state.push(selected.find(i => i.id == key))                                            
                    }
					
					selected = reorder(state)
				}
			}
		})

    }
</script>
{#if label !== false}
    <div class="label">
        <label>
            {label}
        </label>    
    </div>
{/if}

<div class="linked-model">
    {#if noSearch === false}
    <div class="search">
        <TextInput 
            {placeholder} 
            noValidation={validation === false} {errors} 
            touched={result !== false ? false : touched} 
            bind:value={term} 
            name="search"
            autocomplete="off"
        />

        {#if result !== false}
            <div class="popout" use:clickOutside on:clickOutside={clear}>
                {#each result as item}
                    <div class="item" on:click={() => toggle(item)}>
                        <slot name="result-item" {item} {selected} {isSelected}>
                            <div class="name">
                                {item[nameLabel]}
                            </div>
                            <div class="in-selection">
                                {#if isSelected(item, selected) === true}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>               
                                {/if}
                            </div>
                        </slot>
                    </div>
                {:else}                    
                    <div class="no-selection">
                        No results
                    </div>
                {/each}
                {#if more}
                    <div on:click={() => filters.page = filters.page + 1}>
                        {loading ? 'Loading' : 'Load More'}
                    </div>
                {/if}
            </div>    
        {/if}
    </div>
    {/if}    
    
    <div class="selected" class:ordered={ordered} bind:this={selectedEl}>
    {#each items as item(item.id)}
        <div class="item" data-id={item.id} animate:flip>
            <slot {item} {remove} {nameLabel}>            
                {#if selectedSort !== undefined}
                    <div class="dragger">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 3.59375 L 15.28125 4.28125 L 7.28125 12.28125 L 5.59375 14 L 26.40625 14 L 24.71875 12.28125 L 16.71875 4.28125 Z M 16 6.4375 L 21.5625 12 L 10.4375 12 Z M 5.59375 18 L 7.28125 19.71875 L 15.28125 27.71875 L 16 28.40625 L 16.71875 27.71875 L 24.71875 19.71875 L 26.40625 18 Z M 10.4375 20 L 21.5625 20 L 16 25.5625 Z"/></svg>
                    </div>
                {/if}
                <div class="name">
                    {item[nameLabel]}
                </div>
                {#if disabled === false}
                <div class="remove" on:click={() => remove(item)}>                
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>                
                </div>
                {/if}            
            </slot>
        </div>
    {:else}
        {#if hideNoSelection === false}
        <div class="no-selection">
            {unselected}
        </div>
        {/if}
    {/each}
    </div>
</div>

<style>
.label {
    font-size: 17px;     
    color: #2c3e50;
    margin-bottom: 0.25em;   
}
.search {
    position: relative;
}
:global(.linked-model .item),
:global(.linked-model .item [slot=result-item]) {
    display: grid;    
    grid-column-gap: 10px;
    grid-template-columns: auto fit-content(2em);
    grid-auto-columns: auto;
    grid-auto-flow: column;
}
:global(.linked-model .item [slot=result-item]) {
    grid-column: 1 / 3;
}
:global(.linked-model .ordered .item) {
    grid-template-columns: fit-content(2em) auto fit-content(2em);
}
:global(.linked-model .item .name) {
    flex-grow: 1;
    transition: 150ms all ease-in;
}
:global(.linked-model .item .in-selection svg) {
    height: 1em;
    width: 1em;
}
:global(.linked-model .item .in-selection svg path) {
    fill: #28a745;
}
:global(.linked-model .item .remove) {
    cursor: pointer;
}
:global(.linked-model .item .remove svg) {
    height: 1em;
    width: 1em;
}
:global(.linked-model .item .remove svg path) {
    transition: 150ms all ease-in;
    fill: #cccccc;
}
:global(.linked-model .item .remove:hover svg path) {
    fill: #dc3545;
}
.selected {
    display: grid;
    grid-row-gap: 10px;
    margin-top: 15px;
}
:global(.linked-model .dragger) {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: move;
}
:global(.linked-model .dragger svg) {
    width: 1.3em;	
}
.linked-model .popout {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  background: #FFF;
  border: 1px solid #ddd;
  border-top: none;
  padding: 10px 15px;
  display: grid;
  grid-row-gap: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #737373;
  cursor: pointer;
  z-index: 1000;
  max-height: 250px;
  overflow-y: auto;
}</style>