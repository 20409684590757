<script>
    export let size = '50px'
    export let color1 = '#ee7b00'
    export let color2 = '#58595b'
    export let color3 = '#e0e0e0'

    let loader

    $: loader !== undefined ? loader.style.width = size : undefined

</script>

<div class="loader" >
    <svg bind:this={loader} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 20" preserveAspectRatio="xMidYMid" display="block">
      <circle cx="84" cy="10" r="1.012" fill={color1}>
        <animate attributeName="r" repeatCount="indefinite" dur="0.4629629629629629s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"/>
        <animate attributeName="fill" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="{color1};{color3};{color1};{color2};{color1}" begin="0s"/>
      </circle>
      <circle cx="16" cy="10" r="0" fill={color1}>
        <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"/>
        <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"/>
      </circle>
      <circle cx="16" cy="10" r="8.988" fill={color2}>
        <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4629629629629629s"/>
        <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4629629629629629s"/>
      </circle>
      <circle cx="46.558" cy="10" r="10" fill={color1}>
        <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9259259259259258s"/>
        <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9259259259259258s"/>
      </circle>
      <circle cx="80.558" cy="10" r="10" fill={color3}>
        <animate attributeName="r" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3888888888888888s"/>
        <animate attributeName="cx" repeatCount="indefinite" dur="1.8518518518518516s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3888888888888888s"/>
      </circle>
    </svg>
</div>

<style>
.loader {
  display: flex;
  position: relative;    
  align-items: center;
  justify-content: center;
}

.loader svg {
  width: auto;
  height: auto;
}</style>