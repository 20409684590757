<script>
    import TextInput from './form/TextInput.svelte'
    import File from '../modules/site-editor/blocks/File.svelte'
    import Btn from './Btn.svelte'
    import Modal from './Modal.svelte'
    import Card from './Card.svelte'
    import { v4 as uuid } from 'uuid'
    import Sortable from 'sortablejs'
    import { onMount } from 'svelte'
	import Browser from '../modules/site-editor/components/Browser.svelte';

    export let columns = [];
    export let rows = [];

	let headerEl
	let headerSort
	let rowsEl	
	let rowsSort
	let selected = []
	let bulkInsert = false
	let bulk
	let updating = false
	let datasheetModal = false
	let datasheetRow
	
	$: computeCss(headerEl, rowsEl, columns.length, rows.length)
	$: updateBulk(columns, rows, bulkInsert, updating)
	$: maxRows = bulk === undefined ? 2 : (bulk.trim().split("\n").length + 2) > 15 ? 15 : bulk.trim().split("\n").length + 2
		
	function computeCss () {
		if (headerEl === undefined || rowsEl === undefined) return
		let template = '2em repeat(' + columns.length + ', 1fr) 110px 2em'
		headerEl.style.gridTemplateColumns = template;
		rowsEl.childNodes.forEach((node) => {
			node.style.gridTemplateColumns = template
		})
	}

	function updateBulk () {
		if (bulkInsert === false) return
		if (updating === true) return
		bulk = rows.map((r) => {
			let line = []
			for (let col of columns.map(c => c.name)) {
				line.push(r[col].value)
			}
			return line.join("\t")
		}).join("\n")
	}

	function saveBulkInsert () {
		if (bulkInsert === false) return
		let lines = bulk.trim().split("\n")
		
		let colCount = 0
		for (let line of lines) {
			let cols = line.trim().split("\t").length
			if (colCount < cols) colCount = cols
		}

		let newRows = []

		updating = true

		// check for new columns
		if (columns.length < colCount) {
			for (let i = 0; i < (colCount - columns.length); i++) {
				addColumn()
			}			
		} else if (columns.length > colCount) {
			for (let i = 0; i < (columns.length - colCount); i++) {
				columns.pop()				
			}			
		}

		for (let line of lines) {
			let cols = line.trim().split("\t")
			let row = {
				id: uuid(),
				spec: null
			}

			for (let key in columns.map(c => c.name)) {
				row[columns[key].name] = {
					value: cols[key] === undefined ? null : cols[key]
				}
			}
			newRows.push(row)
		}

		rows = newRows

		updating = false
		bulkInsert = false
	}
	
	function addColumn () {
		let col = {
			name: uuid(),
            label: null,
            component: 'TextInput'
		}

		columns = [
			...columns,
			col
		]

		if (rows.length === 0) addRow()

		rows = rows.map(m => {
			m[col.name] = {
				value: null
			}
			return m
		})
	}
	
	
	
	function removeColumn (name) { 
  	columns = columns.filter(c => c.name !== name)   
		rows = rows.map(r => {
			let state = {}
			for (let key of Object.keys(r)) {
				if (key !== name) state[key] = r[key]
			}
			return state
		})
	}
	
	function addRow () {
		let row = {
            id: uuid(),
            spec: null
		}
		for (let key of columns.map(c => c.name)) {
			row[key] = {
				value: null
			}
		}
		
		rows = [
			...rows,
			row
		]
	}	
	
	function removeRow (id) {
		rows = rows.filter(r => r.id !== id)
	}
	
	onMount(() => {
        if (columns.length === 0) addColumn()
		headerSort = Sortable.create(headerEl, {
			draggable: '.cell.moveable',			
			handle: '.dragger',
			store: {
				/**
				 * Get the order of elements. Called once during initialization.
				 * @param   {Sortable}  sortable
				 * @returns {Array}
				 */
				get: function (sortable) {					
					return columns;
				},

				/**
				 * Save the order of elements. Called onEnd (when the item is dropped).
				 * @param {Sortable}  sortable
				 */
				set: function (sortable) {					
					let state = []
					
					for (let key of sortable.toArray()) {						
						state.push(columns.find(c => c.name === key))
                    }
                    
					columns = state
				}
			}
		})
				
		rowsSort = new Sortable(rowsEl, {
			draggable: '.row',
			handle: '.dragger',
			store: {
				/**
				 * Get the order of elements. Called once during initialization.
				 * @param   {Sortable}  sortable
				 * @returns {Array}
				 */
				get: function (sortable) {					
					return rows;
				},

				/**
				 * Save the order of elements. Called onEnd (when the item is dropped).
				 * @param {Sortable}  sortable
				 */
				set: function (sortable) {					
					let state = []
					
					for (let key of sortable.toArray()) {									
						state.push(rows[parseInt(key)])
					}
					
					rows = state
				}
			}
		})
    })

    function rowColumns (row) {
        let output = []
        for (let column in row) {
            if (column !== 'id') {
                output.push(column)
            }
        }

        return output
    }

	function openDatasheetBrowser (key) {
		datasheetRow = key
		datasheetModal = true
	}

	function selectDatasheet (datasheet) {
		rows[datasheetRow].spec = datasheet
		closeDatasheetBrowser()
	}

	function closeDatasheetBrowser () {
		datasheetRow = undefined
		datasheetModal = false
	}
</script>
<div class="table">
	<div class="header" bind:this={headerEl}>
		<div class="blank bulk" on:click={() => bulkInsert = true}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 6 3 L 6 29 L 26 29 L 26 9.5996094 L 25.699219 9.3007812 L 19.699219 3.3007812 L 19.400391 3 L 6 3 z M 8 5 L 18 5 L 18 11 L 24 11 L 24 27 L 8 27 L 8 5 z M 20 6.4003906 L 22.599609 9 L 20 9 L 20 6.4003906 z M 11 13 L 14.800781 18.5 L 11 24 L 13.400391 24 L 16 20.199219 L 18.599609 24 L 21 24 L 17.199219 18.5 L 21 13 L 18.599609 13 L 16 16.800781 L 13.400391 13 L 11 13 z"/></svg>
		</div>
		{#each columns as col, key (col.name)}
		<div class="cell moveable" data-id={col.name}>
			<TextInput bind:value={columns[key].label} name={col.name} placeholder="Column Header" noValidation>
				<div class="dragger" title="Move Column" slot="prepend">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 3.59375 L 15.28125 4.28125 L 7.28125 12.28125 L 5.59375 14 L 26.40625 14 L 24.71875 12.28125 L 16.71875 4.28125 Z M 16 6.4375 L 21.5625 12 L 10.4375 12 Z M 5.59375 18 L 7.28125 19.71875 L 15.28125 27.71875 L 16 28.40625 L 16.71875 27.71875 L 24.71875 19.71875 L 26.40625 18 Z M 10.4375 20 L 21.5625 20 L 16 25.5625 Z"/></svg>
				</div>
				<div class="remove" title="Remove Column" slot="append" on:click={() => removeColumn(col.name)}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 15 4 C 14.476563 4 13.941406 4.183594 13.5625 4.5625 C 13.183594 4.941406 13 5.476563 13 6 L 13 7 L 7 7 L 7 9 L 8 9 L 8 25 C 8 26.644531 9.355469 28 11 28 L 23 28 C 24.644531 28 26 26.644531 26 25 L 26 9 L 27 9 L 27 7 L 21 7 L 21 6 C 21 5.476563 20.816406 4.941406 20.4375 4.5625 C 20.058594 4.183594 19.523438 4 19 4 Z M 15 6 L 19 6 L 19 7 L 15 7 Z M 10 9 L 24 9 L 24 25 C 24 25.554688 23.554688 26 23 26 L 11 26 C 10.445313 26 10 25.554688 10 25 Z M 12 12 L 12 23 L 14 23 L 14 12 Z M 16 12 L 16 23 L 18 23 L 18 12 Z M 20 12 L 20 23 L 22 23 L 22 12 Z"/></svg>
				</div>
			</TextInput>
		</div>
		{/each}
        <div class="cell button">
			Data Sheet
		</div>
		<div class="blank add" title="Add column" on:click={() => addColumn()}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 15 5 L 15 15 L 5 15 L 5 17 L 15 17 L 15 27 L 17 27 L 17 17 L 27 17 L 27 15 L 17 15 L 17 5 Z"/></svg>
		</div>
	</div>	
	<div class="rows" bind:this={rowsEl}>
		{#each rows as row, key (row.id)}
			<div class="row" data-id={key}>
				<div class="cell dragger" title="Move row">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 3.59375 L 15.28125 4.28125 L 7.28125 12.28125 L 5.59375 14 L 26.40625 14 L 24.71875 12.28125 L 16.71875 4.28125 Z M 16 6.4375 L 21.5625 12 L 10.4375 12 Z M 5.59375 18 L 7.28125 19.71875 L 15.28125 27.71875 L 16 28.40625 L 16.71875 27.71875 L 24.71875 19.71875 L 26.40625 18 Z M 10.4375 20 L 21.5625 20 L 16 25.5625 Z"/></svg>
				</div>
				{#each columns.map(c => c.name) as column}                    
                    <div class="cell" class:selected={selected.includes(key)}>
                        <TextInput bind:value={rows[key][column].value}	name={rows[key][column].id} noValidation />                        
                    </div>				                    
				{/each}
                <div class="cell button">
					{#if rows[key].spec}
						{#if rows[key].spec.template && rows[key].spec.template.name.includes('Datasheet')}
							{rows[key].spec.name}
							<Btn red on:click={() => rows[key].spec = null}>
								Remove
							</Btn>
						{:else if rows[key].spec.file}
							{rows[key].spec.file.media.name}
							<Btn flash on:click={() => rows[key].spec = null}>
								Old Media
							</Btn>
						{/if}
						
					{:else}
					<Btn block green on:click={() => openDatasheetBrowser(key)}>
						Select
					</Btn>
					{/if}
                </div>
				<div class="cell remove" title="Delete row" on:click={() => removeRow(row.id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 15 4 C 14.476563 4 13.941406 4.183594 13.5625 4.5625 C 13.183594 4.941406 13 5.476563 13 6 L 13 7 L 7 7 L 7 9 L 8 9 L 8 25 C 8 26.644531 9.355469 28 11 28 L 23 28 C 24.644531 28 26 26.644531 26 25 L 26 9 L 27 9 L 27 7 L 21 7 L 21 6 C 21 5.476563 20.816406 4.941406 20.4375 4.5625 C 20.058594 4.183594 19.523438 4 19 4 Z M 15 6 L 19 6 L 19 7 L 15 7 Z M 10 9 L 24 9 L 24 25 C 24 25.554688 23.554688 26 23 26 L 11 26 C 10.445313 26 10 25.554688 10 25 Z M 12 12 L 12 23 L 14 23 L 14 12 Z M 16 12 L 16 23 L 18 23 L 18 12 Z M 20 12 L 20 23 L 22 23 L 22 12 Z"/></svg>					
				</div>
		</div>
		{/each}
	</div>
</div>
<div class="actions">
    <div class="spacer"></div>
    <Btn green on:click={() => addRow()}>
	    Add Row
    </Btn>
</div>

{#if bulkInsert === true}
<Modal width="50vw">
	<Card title="Bulk Editor">
		<div class="bulk-instructions">
			<ul>
				<li>Only enter rows not column headers are not supported.</li>
				<li>Rows are seperated by new lines.</li>
				<li>Columns just be seperated by tabs.</li>
			</ul>
		</div>

		<div class="bulk-input">
			<textarea rows={maxRows} bind:value={bulk}></textarea>
		</div>
		<div class="actions">
			<Btn red on:click={() => bulkInsert = false}>
				Cancel
			</Btn>
			<div class="spacer"></div>			
			<Btn green on:click={saveBulkInsert}>
				Insert
			</Btn>
		</div>
	</Card>
</Modal>
{/if}

{#if datasheetModal}
<div id="datasheetModal">
	<Modal width="80vw" height="85vh" on:close={closeDatasheetBrowser}>
		<Card>
			<Browser 
				on:selected={({detail}) => selectDatasheet(detail)}
				on:close={closeDatasheetBrowser}
				title="Datasheets"
				model="datasheets"
				noResults="No datasheets found."
			/>
		</Card>
	</Modal>
</div>
{/if}
<style>
   .table {
       margin-bottom: 10px;
   }

   .actions {
       display: flex;
   }

   .blank {
	background: #eee;		
	margin-right: 1px;
}

   .table .cell {
	background: #fff;		
	margin: 0 1px 1px 0;
}

   .table .cell.dragger,
.table .cell.remove,
.table .blank.add,
.table .blank.bulk  {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	cursor: pointer;
}

   .table .cell.dragger {		
	cursor: move;
}

   .table .cell svg.switch {
       width: 1em;
   }

   .table .cell.dragger svg,
.table .cell.remove svg,
.table .blank.add svg,
.table .blank.bulk svg {
       width: 1.3em;		
}

   .table .blank.add,
.table .blank.bulk {
       transition: 150ms all ease-in;
       background: #4CAF50;
   }

   .table .blank.bulk {
	background: #2196f3;
}

   .table .blank.add:hover,
.table .blank.bulk:hover {
       background: #ee7b00;   
   }

   .table .blank.add svg path,
.table .blank.bulk svg path {
       transition: 150ms all ease-in;
       fill: #FFF;
   }

   .table .header .cell .remove {
	cursor: pointer;
	padding-right: 3px;
       transition: 150ms all ease-in;         
}

   .table .cell svg path,
   .table .header .cell .dragger svg path,
   .table .header .cell .remove svg path,
   .table .cell.remove svg path,
   .table .cell.dragger svg path {
       transition: 150ms all ease-in;        
   }

   .table .header .cell .remove:hover svg path {
       fill: #f44336;
   }

   .table .cell.remove {
       background: #f44336;
   }

   .table .cell.remove.remove:hover {
       background: #ee7b00;
   }

   .table .cell.remove svg path {
       fill: #FFF;
   }

   .table .header .cell .dragger:hover svg path,
   .table .cell.dragger:hover svg path {
       fill: #ee7b00;
   }

   .table .header .cell .dragger,
.table .header .cell .remove {		
	display: flex;
	align-items: center;
	justify-content: center;
}

   .table .header .cell .dragger svg,
.table .header .cell .remove svg {		
	width: 1em;
}

   .table .header .cell .dragger {
	padding-left: 3px;
}

   .table .header .cell .dragger svg {
	transform: rotate(90deg);				
}

   .table .header {
	display: grid;	
	background: #888;
	padding: 1px 0 1px 1px;
	grid-column: 1/-1;
}

   .table .header .cell {
	font-weight: bold;
	margin-bottom: 0;
	cursor: move;
}

   .table .cell.selected {
	background: pink;
}

   .table .rows {
	display: grid;
}

   .table .rows .row {
	display: grid;
	background: #888;
	padding: 0px 0px 0px 1px;
}

   .table .cell :global(.input-group) {
	border: none;
}

   .table .cell :global(.input-group input) {
	width: 100%;
}

   .bulk-input textarea {
	width: 100%;
}

   .bulk-instructions ul {
	margin-top: 0;
	padding: 0 0 0 2ch;
}

   .actions {
	display: flex;
   }

   .table .header .cell.button {
       padding: 0.4em;
       text-align: center;
   }

   #datasheetModal {
	--card-body-padding: 0;
}</style>