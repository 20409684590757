<script>
    import TextInput from '../../../components/form/TextInput.svelte'
    import SelectInput from '../../../components/form/SelectInput.svelte'

    export let content = '';
    export let properties = {};

    $: content = isNull(content, {
        text: '',
        tag: 'h1'
    })

    $: properties = isNull(properties, {})

    let tags = [
        {
            name: 'Heading 1',
            value: 'h1'
        },
        {
            name: 'Heading 2',
            value: 'h2'
        },
        {
            name: 'Heading 3',
            value: 'h3'
        },
        {
            name: 'Heading 4',
            value: 'h4'
        },
        {
            name: 'Heading 5',
            value: 'h5'
        },
        {
            name: 'Heading 6',
            value: 'h6'
        },
        {
            name: 'Div',
            value: 'div'
        },
    ]

    function isNull (current, fallback) {
        return current === null ? fallback : current
    }
</script>

<div class="grid">
    <div class="row">
        <TextInput label="Text" bind:value={content.text} noValidation />
    </div>
    <div class="row">
        <SelectInput 
            label="Tag" 
            name="tag" 
            bind:value={content.tag} 
            choices={tags} 
            noValidation 
            placeholder={tags[0].name}
            above
        />
    </div>
    {#if content.tag && content.tag.value === 'div'}
        <div class="row">
            <TextInput label="Class" bind:value={content.class} noValidation />
        </div>
    {/if}
</div>



<style>
.grid {
    display: grid;
    grid-row-gap: 15px;
}</style>