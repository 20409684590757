<script>
    import LoaderIcon from '../../../../components/LoaderIcon.svelte'
    import Btn from '../../../../components/Btn.svelte'    
    import Crud from '../../../../components/Crud.svelte'
    import Pill from '../../../../components/Pill.svelte'
    import { slide } from 'svelte/transition'
    import ApiService from '../../../../services/api.service.js'
    import TextInput from '../../../../components/form/TextInput.svelte'
    import debounce from 'lodash/debounce'
    import { loadFilters, saveFilters, capitalise, titleCase, pluralise } from '../../../../utils/helpers.js'
    import { url } from "@sveltech/routify";
    import Modal from '../../../../components/Modal.svelte'
    import Card from '../../../../components/Card.svelte'  
    
    export let id

    const apiRoute = 'site-notifications/topics/' + id
    const filterKey = 'topics-' + id
    const notificationFilterKey = 'topics-notification' + id

    let loading = false
    let initalised = false
    let pagination = false
    let statuses = [ 
        { key: null, value: 'All' }, 
        { key: 'pending', value: 'Pending' },
        { key: 'unsubscribed', value: 'Unsubscribed' } 
    ]

    let filters = loadFilters(filterKey, {
        page: 1,
        per_page: 10,
        sort_by: null, 
        sort_direction: null,
        search: null,
        status: null
    })

    let notificationLoading = false
    let notificationPagination = false
    let notificationStatuses = [ 
        { key: null, value: 'All' }, 
        { key: 'draft', value: 'Draft' },
        { key: 'sent', value: 'Sent' },
        { key: 'deleted', value: 'Deleted' } 
    ]

    let notificationFilters = loadFilters(notificationFilterKey, {
        page: 1,
        per_page: 10,
        sort_by: null, 
        sort_direction: null,
        search: null,
        status: null
    })

    let model = false
    let search = filters.search
    let records = []
    let notificationRecords = []

    $: saveFilters(filterKey, filters)    
    $: saveFilters(notificationFilterKey, notificationFilters)    
    $: getModel(id)
    $: getEntities(filters)
    $: getNotifications(notificationFilters)

    $: title = model !== false ? model.name : ''

    async function getModel(id) {
        if (id === undefined) return        
        let url = apiRoute

        try {
            let { data } = await ApiService.get(url)

            model = data

            initalised = true
        } catch (error) {
            console.error(error)
        }
    }

    async function getEntities (options = {}) {
        if (loading === true) return
        let { page } = options
        loading = true 

        try {
            let { data } = await ApiService.get(apiRoute + '/subscribers', filters)

            records = data.data

            if (data.last_page < filters.page) {                
                filters.page = 1
                return
            }

            if (data.total === 0) {                
                pagination = false
            } else {                
                pagination = {
                    current: data.current_page,
                    first_page: 1,
                    last_page: data.last_page
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            loading = false
        }
    }

    async function getNotifications (options = {}) {
        if (notificationLoading === true) return
        let { page } = options
        notificationLoading = true 

        try {
            let { data } = await ApiService.get(apiRoute + '/notifications', notificationFilters)

            notificationRecords = data.data

            if (data.last_page < notificationFilters.page) {                
                notificationFilters.page = 1
                return
            }

            if (data.total === 0) {                
                notificationPagination = false
            } else {                
                notificationPagination = {
                    current: data.current_page,
                    first_page: 1,
                    last_page: data.last_page
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            notificationLoading = false
        }
    }
</script>

<header transition:slide>
    <div class="title">
        Topic: {titleCase(pluralise(title))}
    </div>
    <div class="new">
        <Btn pill tag="a" href={$url('../../:id', { id })} blue>Edit</Btn>
    </div>
    <div class="spacer"></div>
    <div class="search">
        <form on:submit|preventDefault={() => filters.search = search}>
            <TextInput placeholder="Search subscribers" name="search" bind:value={search} noValidation />
            <Btn blue type="submit">
                Search
            </Btn>
        </form>
    </div>
</header>

{#if loading === true && initalised === false}
    <div class="loader" transition:slide>
        <LoaderIcon />
    </div>
{/if}

{#if initalised === true}
<div class="grid" transition:slide>
    <div class="subTitle">
        Subscribers
    </div>
    <div class="main-col">
        <Crud {records} {pagination} {loading} {statuses} bind:filters={filters} let:items>     
        {#each items as item}
            <div class="record">
                <div class="id">
                    <a href="{$url('../../../subscribers/:id', { id: item.id })}">
                        #{item.id}
                    </a>  
                </div>
                <div class="name">
                    <a href="{$url('../../../subscribers/:id', { id: item.id })}">
                        {item.name} 
                    </a>                        
                </div>    
                {#if item.channel !== null && item.channel.protocol === 'sms'}
                <div class="phone">                    
                    {item.phone}                    
                </div>   
                {/if}
                {#if item.channel !== null && item.channel.protocol === 'email'}
                <div class="email">                    
                    {item.email}                    
                </div>   
                {/if}
                <div class="protocol">                        
                    <Pill>
                        { item.channel.name }
                    </Pill>
                </div>    
                <div class="status">     
                    {#if item.pivot.unsubscribed_at !== null}
                        <Pill red>
                            Unsubscribed
                        </Pill>
                    {:else if item.channel.protocol === 'sms' && item.phone_confirmed_at === null}
                        <Pill orange>
                            Pending
                        </Pill>
                    {:else}
                        <Pill green>
                            Subscribed
                        </Pill>
                    {/if}
                </div>                      
            </div>
        {:else}
            <div class="no-results">
                No notifications found{filters.search !== null && filters.search.len > 0 ? ' for "' + filters.search + '"' : ''}.
            </div>
        {/each}
        </Crud>
    </div>
</div>  

<div class="grid" transition:slide>
    <div class="subTitle">
        Notifications 
        <div class="new">
            <Btn pill tag="a" green href={$url('../../../notifications/create')}>New</Btn>
        </div>
    </div>
    <div class="main-col">
        <Crud records={notificationRecords} pagination={notificationPagination} loading={notificationLoading} statuses={notificationStatuses} bind:filters={notificationFilters} let:items>     
        {#each items as item}
            <div class="record">
                <div class="id">
                    <a href="{$url('../../../notifications/:id', { id: item.id })}">
                        #{item.id}
                    </a>  
                </div>
                <div class="name">
                    <a href="{$url('../../../notifications/:id', { id: item.id })}">
                        {item.name} 
                    </a>                        
                </div>                    
                <div class="status">                         
                    <Pill green>
                        Subscribed
                    </Pill>                    
                </div>                      
            </div>
        {:else}
            <div class="no-results">
                No notifications found.
            </div>
        {/each}
        </Crud>
    </div>
</div> 
{/if}

<style>
header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

header .title {
    font-size: 23px;
    font-weight: 300;        
}

header .new {
    margin-left: 10px;        
}

header .search form {
    display: flex;
}

.subTitle {
    display: flex;
    align-items: center;
    font-size: 21px;
    font-weight: 300;        
}

.subTitle :global(.pill) {
    margin-left: 10px;
    font-size: 0.8em;
}

.loader {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid {
    display: grid;
}

.message {
    font-size: 18px;
    font-weight: 300;    
}

.record .status {
    width: 14ch;        
}

.record .status :global(.pill) {
    width: 100%;
}</style>

