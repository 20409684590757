<script>
    import LoaderIcon from '../../../components/LoaderIcon.svelte'
    import Btn from '../../../components/Btn.svelte'    
    import Crud from '../../../components/Crud.svelte'
    import Pill from '../../../components/Pill.svelte'
    import { slide } from 'svelte/transition'
    import ApiService from '../../../services/api.service.js'
    import TextInput from '../../../components/form/TextInput.svelte'
    import debounce from 'lodash/debounce'
    import { loadFilters, saveFilters, capitalise, titleCase, pluralise } from '../../../utils/helpers.js'
    import { url } from "@sveltech/routify";
    import Modal from '../../../components/Modal.svelte'
    import Card from '../../../components/Card.svelte'  
    
    const title = 'plan'
    const apiRoute = 'site-membership/plans'
    const filterKey = 'plans'
    let loading = false
    let initalised = false
    let pagination = false
    let statuses = [ 
        { key: null, value: 'All' }, 
        { key: 'draft', value: 'Drafts' },
        { key: 'scheduled', value: 'Scheduled' },        
        { key: 'published', value: 'Published' },
        { key: 'deleted', value: 'Deleted' } 
    ]

    let filters = loadFilters(filterKey, {
        page: 1,
        per_page: 10,
        sort_by: null, 
        sort_direction: null,
        search: null,
        status: null
    })

    let search = filters.search
    let records = []
    let deleteModal = false
    let restoreModal = false
    let deleteing = false
    let restoring = false

    $: saveFilters(filterKey, filters)    

    $: getEntities(filters)

    async function getEntities (options = {}) {
        if (loading === true) return
        let { page } = options
        loading = true 

        try {
            let { data } = await ApiService.get(apiRoute + '/', filters)

            records = data.data

            if (data.last_page < filters.page) {                
                filters.page = 1
                return
            }

            if (data.total === 0) {                
                pagination = false
            } else {                
                pagination = {
                    current: data.current_page,
                    first_page: 1,
                    last_page: data.last_page
                }
            }

            initalised = true
        } catch (error) {
            console.error(error)
        } finally {
            loading = false
        }
    }

    async function bin() {
        if (deleteing === true || deleteModal === false) return
        deleteing = true

        try {            
            let response = await ApiService.delete(apiRoute + '/' + deleteModal.id)
            
            getEntities()
            deleteModal = false
        } catch (error) {
            console.error(error)
        } finally {
            deleteing = false            
        }
    }

    async function restore() {
        if (restoring === true || restoreModal === false) return
        restoring = true

        try {            
            let response = await ApiService.get(apiRoute + '/' + restoreModal.id + '/restore')
            
            getEntities()
            restoreModal = false
        } catch (error) {
            console.error(error)
        } finally {
            restoring = false            
        }
    }
</script>

<header>
    <div class="title">
        {titleCase(pluralise(title))}
    </div>
    <div class="new">
        <Btn pill tag="a" href={$url('../:id', { id: 'add' })} green>New</Btn>
    </div>
    <div class="spacer"></div>
    <div class="search">
        <form on:submit|preventDefault={() => filters.search = search}>
            <TextInput placeholder="Search for {pluralise(title)}" name="search" bind:value={search} noValidation />
            <Btn blue type="submit">
                Search
            </Btn>
        </form>
    </div>
</header>

{#if loading === true && initalised === false}
    <div class="loader" transition:slide>
        <LoaderIcon />
    </div>
{/if}

{#if initalised === true}
<div class="grid" transition:slide>
    <div class="main-col">
        <Crud {records} {pagination} {loading} {statuses} bind:filters={filters} let:items>     
        {#each items as item}
            <div class="record">
                <div class="id">
                    <a href="{$url('../:id', { id: item.id })}">
                        #{item.id}
                    </a>  
                </div>
                <div class="name">
                    <a href="{$url('../:id', { id: item.id })}">
                        {item.name}
                    </a>                        
                </div>    
                <div class="status">
                    <Pill green={item.status.key === 'published'} red={item.status.key === 'deleted'} orange={item.status.key === 'scheduled'} >
                        { item.status.value }
                    </Pill> 
                </div>  
                <div class="edit">
                    <Btn tag="a" href={$url('../:id', { id: item.id })} blue block>
                        Edit
                    </Btn>  
                </div>                            
                {#if item.deleted_at === null}
                <div class="delete">
                    <Btn red block on:click={() => deleteModal = item}>
                        Delete
                    </Btn>  
                </div>
                {:else}
                <div class="restore">
                    <Btn green block  on:click={() => restoreModal = item}>
                        Restore
                    </Btn>  
                </div>                 
                {/if}                   
            </div>
        {:else}
            <div class="no-results">
                No results found{filters.search !== null && filters.search.len > 0 ? ' for "' + filters.search + '"' : ''}.
            </div>
        {/each}
        </Crud>
    </div>
</div>  
{/if}

{#if deleteModal !== false}
    <Modal>
        <Card title="Delete {titleCase(title)}">
            <div class="delete-modal">
                {#if loading === false}
                <div class="message">
                    Are you sure you want to delete "{deleteModal.name}"?
                </div>
                {:else}
                    <LoaderIcon />
                {/if}
            </div>
            <div class="actions" slot="actions">
                <Btn block red on:click={() => deleteModal = false}>
                    No
                </Btn> 
                <Btn block green on:click={bin}>
                    Yes
                </Btn> 
            </div>
        </Card>
    </Modal>
{/if}

{#if restoreModal !== false}
    <Modal>
        <Card title="Restore {titleCase(title)}">
            <div class="restore-modal">
                {#if loading === false}
                <div class="message">
                    Are you sure you want to restore "{restoreModal.name}"?
                </div>
                {:else}
                    <LoaderIcon />
                {/if}
            </div>
            <div class="actions" slot="actions">
                <Btn block red on:click={() => restoreModal = false}>
                    No
                </Btn> 
                <Btn block green on:click={restore}>
                    Yes
                </Btn> 
            </div>
        </Card>
    </Modal>
{/if}

<style>
header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

header .title {
    font-size: 23px;
    font-weight: 300;        
}

header .new {
    margin-left: 10px;        
}

header .search form {
    display: flex;
}

.loader {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid {
    display: grid;
}

.message {
    font-size: 18px;
    font-weight: 300;    
}

.delete-modal,
.restore-modal {
    display: flex;
    min-height: 6em;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.delete-modal .message,
.restore-modal .message  {    
    padding: 0 20px;
}

.actions {
    display: flex;
}

.actions .btn {
    width: 50%;
}</style>

