<script>
	import { slide } from 'svelte/transition'
	import { onMount } from 'svelte'
	import Sortable from 'sortablejs'
	import { clickOutside } from '../../../utils/click-outside.js'
	import Banner from '../blocks/Banner.svelte'
	import Image from '../blocks/Image.svelte'
	import ContentSection from '../blocks/ContentSection.svelte'
	import ContentSectionImage from '../blocks/ContentSectionImage.svelte'
	import Table from '../blocks/Table.svelte'
	import TextInput from '../../../components/form/TextInput.svelte'
	import WidgetBlocks from '../blocks/WidgetBlocks.svelte'
	import Button from '../blocks/Button.svelte'
	import Heading from '../blocks/Heading.svelte'
	import Services from '../blocks/Services.svelte'
	import Btn from '../../../components/Btn.svelte'
	import { v4 as uuid } from 'uuid'
	import JsonEditor from './JsonEditor.svelte'

    export let options = {}

    $: keys = getOptions(options)

    let key
    let show = []

    function getOptions(options) {
        if (options === undefined || options === null) return []
        let output = []
        for (let key in options) {
            output.push(key)
        }

        return output
    }

    function addOption () {
        if (key === '' || key === undefined || key === null) return
        if (options === undefined || options === null) options = {}
        options[key] = {}

        options = options
        show.push(key)
        show = show
        key = undefined
    }

    function removeOption (name) {
        delete options[name]
        options = options
    }

    function toggle (name) {
        if (show.includes(name)) {
            show = show.filter(i => i !== name)
        } else {
            show.push(name)
            show = show
        }
    }
    
</script>
<div class="layout">
	<div class="blocks">
		{#each keys as name}
		<div class="block">
			<div class="header">
				<div class="name">
					{name}
				</div>
				
				<div class="circle remove" on:click={() => removeOption(name)} title="remove block">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>
				</div>
				<div class="circle expander" class:open={show.includes(name)} on:click={() => toggle(name)}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z"/></svg>
				</div>
			</div>
			{#if show.includes(name)}
			<div class="body" transition:slide>				
				<JsonEditor bind:json={options[name]} />
			</div>		
			{/if}
		</div>		
		{/each}

        	
        <form class="add" on:submit|preventDefault={addOption}>
            <TextInput bind:value={key} noValidation />

            <Btn type="submit">
                Add Option
            </Btn>
        </form>            		
	</div>
	
</div>
<style>
.blocks {
	background: #FFF;
	border: 1px solid #ddd;
}
.blocks .block {
	width: 100%;
	border-bottom: 1px solid #ddd;
}
.blocks .block .header {
	display: flex;
	background: #fafafa;
	padding: 10px 20px;		
	align-items: center;
	justify-content: space-between;
}
.blocks .block.error .header {
	background: #f44336;
	color: #FFF;
}
.blocks .block .header .number {
	background: #FFF;
	border-radius: 50%;
	height: 1.5em;
	width: 1.5em;
	line-height: 1em;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #ddd;
	cursor: move;
	color: #333;
}
.blocks .block .header .name {
	margin: 0 15px;
	flex-grow: 1;
	line-height: 1em;
}
.blocks .block .header .grabber svg {
	width: 1.5em;
	height: 1.5em;
}
.blocks .block .header .circle {
       position: relative;
	background: #FFF;
	border-radius: 50%;
	height: 1.5em;
	width: 1.5em;
	line-height: 1em;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #ddd;
	margin-left: 10px;
	cursor: pointer;	
	transition: all 150ms ease-in;
}
.blocks .block .header .circle:hover {
	background: #ececec;
}
.blocks .block .header .circle svg {
	width: 1em;
	height: 1em;
}
.blocks .block .header .circle.expander.open {
	transform: rotate(180deg)
}
.blocks .block .body {
	border-top: 1px solid #ddd;
	padding: 20px;
}
.blocks .message, .add {
	display: grid;
	background: #fafafa;
	padding: 10px 20px;		
	align-items: center;
       grid-template-columns: 20ch auto;
	justify-content: center;
	line-height: 1em;
	font-size: 1.2em;
	font-weight: 300;
	grid-row-gap: 10px;
}
.blocks .message :global(button) {
	position: relative;
	margin-bottom: 0;
   }
.popout {
	position: absolute;
	top: calc(100% - 10px);
	right: 0;
	background: #FFF;
	border: 1px solid #ddd;
	width: auto;
	white-space: nowrap;
	padding: 15px 15px;
	display: grid;
	grid-row-gap: 15px;
	font-size: 16px;
	font-weight: 400;
	color: #737373;
	cursor: pointer;
	z-index: 1000;
	text-align: left;
}
.popout .item:hover {
	color: #fd7e14;
}
.popout a {
	color: #737373;
}
.popout a:hover {
	color: #fd7e14;
}
.popout .loader {
	padding: 10px 50px;
}</style>