<script>
    import Card from '../../components/Card.svelte'
    import ErrorWell from '../../components/ErrorWell.svelte'
    import TextInput from '../../components/form/TextInput.svelte'
    import Btn from '../../components/Btn.svelte'
    import { validator, required, minLength } from '../../validators'
    import ApiService from '../../services/api.service'
    import { user } from '../../stores/user.store'
    import { goto } from '@sveltech/routify'

    let rules = {
        username: [
            { 
                rule: required,
            }
        ],
        password: [
            {
                rule: required
            }
        ]
    }

    let isValid = false
    let loading = false

    $: isValid = usernameErrors === false && passwordErrors === false

    let errorMessages = {
        required: 'This is a required field',
        minLength: 'This field must be longer than %length%'
    }

    let username = ''
    let usernameErrors = false
    let usernameTouched = false
    $: usernameErrors = validator(username, rules.username, errorMessages)

    let password = ''
    let passwordErrors = false
    let passwordTouched = false
    $: passwordErrors = validator(password, rules.password, errorMessages)

    let loginError = false

    async function attemptLogin (event) {
        usernameTouched = true
        passwordTouched = true
        if (isValid === false) return

        loading = true
        
        let result = await user.login(username, password) 
        
        if (result !== true) {
            loginError = result            
        } else {
            $goto('/')
        }
    
        loading = false        
    }
</script>

<div class="login ">
    <Card title="Login">
        <form on:submit|preventDefault={attemptLogin}>
            <div class="form-group">
                <TextInput label="Username" name="username" bind:value={username} errors={usernameErrors} placeholder="Username" touched={usernameTouched} />
            </div>           
            <div class="form-group">
                <TextInput type="password" label="Password" name="password" bind:value={password} errors={passwordErrors} placeholder="Password" touched={passwordTouched} />
            </div>  
            {#if loginError !== false}
                <ErrorWell error={loginError} />
            {/if}
            <div class="form-group">
                <Btn type="submit" block {loading}>
                    Login
                </Btn>
            </div>         
        </form>
    </Card>
</div>

<style>
.login {
    margin-top: 5vh;
    width: 25%;
}

form {
    display: grid;
    grid-row-gap: 15px;
}

:global(.btn) {
    width: 100%;
}</style>