<script>
    import Btn from '../../../components/Btn.svelte'
    import Card from '../../../components/Card.svelte'    
    import TextInput from '../../../components/form/TextInput.svelte'
    import MarkdownEditor from '../../../components/form/MarkdownEditor.svelte'
    import ApiService from '../../../services/api.service.js'
    import { validator, required, minLength } from '../../../validators'
    import { slide } from 'svelte/transition'
    import DatePicker from '../../../components/DatePicker.svelte'
    import { url, goto } from "@sveltech/routify";
    import { isAfter } from 'date-fns'
    import Modal from '../../../components/Modal.svelte'
    import LoaderIcon from '../../../components/LoaderIcon.svelte'
    import Image from '../../../modules/site-editor/blocks/Image.svelte'

    export let id

    let model = false    
    let previous = JSON.stringify(false)
    let now = new Date()
    let saving = false
    let deleteing = false
    let restoring = false
    let unpublishing = false
    let deleteModal = false
    let nameTouched = false
    let rules = {
        name: [
            { 
                rule: required
            },
            {
                rule: minLength,
                params: {
                    length: 2
                }
            }
        ]
    }

    let errorMessages = {
        required: 'This is a required field',
        minLength: 'This field must be longer than %length%'
    }

    $: create = id === 'add' || id === 'new'
    $: getModel(id)
    $: unsaved = previous != JSON.stringify(toSave(model))    
    $: loading = model === false || model === undefined
    $: nameErrors = validator(model.name, rules.name, errorMessages)
    $: isValid = nameErrors === false
    $: disabled = saving === true || deleteing === true || restoring === true || unpublishing === true
    
    async function getModel(id) {
        if (id === undefined) return        
        let url = 'site-editor/adverts/' + (create === true ? 'create' : id)

        try {
            let { data } = await ApiService.get(url)

            model = data
            setPrevious(model)
        } catch (error) {
            console.error(error)
        }
    }

    function toSave (object) {
        if (object === undefined) return undefined
        if (typeof object !== 'object') return object
        return {
            media: object.media !== undefined ? object.media : null,
            name: object.name,
            link: object.link,
            publish_at: object.published_at
        }
    }

    async function save() {
        if (disabled === true) return
        if (saving === true) return

        if (isValid === false) {
            forceValidation()        
            return
        }

        if (unpublishing !== true) saving = true

        let url = 'site-editor/adverts'
        if (create === false) url += '/' + id
        
        try {            
            let response = await ApiService.post(url, toSave(model))
            
            model = response.data
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            saving = false
        }
    }

    async function unpublish() {
        if (disabled === true) return
        unpublishing = true
        model.published_at = null
        await save()        
        unpublishing = false
    }

    async function bin() {
        if (disabled === true) return
        if (deleteing === true) return
        deleteing = true

        try {            
            let response = await ApiService.delete('site-editor/adverts' + '/' + id)
            
            model = response.data
            deleteModal = false
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            deleteing = false            
        }
    }

    async function restore() {
        if (disabled === true) return
        if (restoring === true) return
        restoring = true

        try {            
            let response = await ApiService.get('site-editor/adverts' + '/' + id + '/restore')
            
            model = response.data
            
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            restoring = false            
        }
    }

    function forceValidation () {
        nameTouched = true
    }

    function reset(id) {
        if (create === true) {
            $goto($url('../:id', { id }))
        }
        nameErrors = false
        nameTouched = false
        setPrevious(model)
    }

    function setPrevious(current) {
        setTimeout (() => {
            previous = JSON.stringify(toSave(current))
        }, 250)
    }
</script>
<div class="grid">

    <div class="main-col">
        <div class="content-card">
            <Card title="Basic Information" loading={loading === true}>                     
                <div class="form">
                    <div class="form-group">
                        <TextInput label="Title" name="name" bind:value={model.name} errors={nameErrors} touched={nameTouched} />
                    </div>  

                    <div class="form-group">
                        <TextInput label="Link" name="link" bind:value={model.link} />
                    </div>  

                    <div class="form-group">
                        <Image bind:content={model.media} />
                    </div>          
                </div>    
            </Card>
        </div>
    </div>

    <div class="sidebar">
        <div class="sidebar-card">           
            <div class="actions">     
                <Btn block green on:click={save} {disabled} loading={saving === true}>
                    Save
                </Btn>   
            </div>     
        </div>

        

        <div class="sidebar-card">
            <Card loading={loading === true}>
                <div class="form">       
                    {#if model.created_at !== null}
                    <div class="form-group">
                        <DatePicker label="Created" readonly value={model.created_at} />                           
                    </div>
                    {/if}
                    
                    {#if model.updated_at !== null}
                    <div class="form-group">
                        <DatePicker label="Updated" readonly value={model.updated_at} />
                    </div> 
                    {/if}  

                    <div class="form-group">
                        Status: {model.status === null ? 'Unsaved Draft' : model.status.value}
                    </div>    
                    
                    {#if model.status !== null}
                    <div class="form-group">
                        <DatePicker label={model.status && model.status.key === 'published' ? 'Published on' : 'Publish on'} bind:value={model.published_at} min={now} dateFormat="dd/MM/yyyy" />
                    </div>                     
                    {/if}

                    {#if model.deleted_at !== null}
                    <div class="form-group">
                        <DatePicker label="Deleted" readonly value={model.deleted_at} />
                    </div> 
                    {/if}  

                    
                </div>
                <div class="actions" slot="actions">                    
                    {#if model.status && model.status.key === 'published'}         
                    <Btn block orange on:click={unpublish} {disabled} loading={unpublishing}>
                        Unpublish 
                    </Btn>
                    {/if} 
                    {#if model.status !== null && model.deleted_at === null}
                    <Btn block red on:click={() => deleteModal = true} {disabled}>
                        Delete
                    </Btn> 
                    {/if}
                    {#if model.status !== null && model.deleted_at !== null}
                    <Btn block orange on:click={restore} {disabled} loading={restoring}>
                        Restore
                    </Btn> 
                    {/if}
                </div>
            </Card>
        </div>  
    </div>    
</div>

{#if unsaved === true}
    <div class="sidebar-card warning unsaved" transition:slide|local={{delay: 200}}>
        <Card>
            You have unsaved changed
        </Card>            
    </div>
{/if}

{#if deleteModal === true}
    <Modal>
        <Card title="Delete Advert">
            <div class="delete-modal">
                {#if loading === false}
                <div class="message">
                    Are you sure you want to delete this advert?
                </div>
                {:else}
                    <LoaderIcon />
                {/if}
            </div>
            <div class="actions" slot="actions">
                <Btn block red on:click={() => deleteModal = false}>
                    No
                </Btn> 
                <Btn block green on:click={bin}>
                    Yes
                </Btn> 
            </div>
        </Card>
    </Modal>
{/if}

<style>
.grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 40px;
    align-items: flex-start;
}

.main-col {
    display: grid;
    grid-row-gap: 40px;
    padding-bottom: 40px;
}

.content-card,
.sidebar-card {
    border: 1px solid #ddd;    
}

.sidebar-card {
    margin-bottom: 40px;
}

.actions {
    display: flex;
}

.actions .btn {
    width: 50%;
}

.form {
    display: grid;
    grid-row-gap: 20px;
}

.sidebar-card.warning {
    border-color: #ee7b00;
}

.sidebar-card.warning :global(.card .body) {    
    background: #ee7b00!important;
    color: #FFF;
}

.unsaved {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    text-align: center;
    z-index: 1;
}

.message {
    font-size: 18px;
    font-weight: 300;    
}

.delete-modal {
    display: flex;
    min-height: 6em;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.delete-modal .message {    
    padding: 0 20px;
}

.slug-prefix {
    padding: 5px 7px;
    background: #e6e6e6;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ccc;
    line-height: 1;
}

label {
    font-size: 17px;
    color: #2c3e50;
    margin-bottom: 0.25em;
}</style>