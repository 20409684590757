<script>
    import Btn from '../../../components/Btn.svelte'
    import Card from '../../../components/Card.svelte'    
    import TextInput from '../../../components/form/TextInput.svelte'
    import MarkdownEditor from '../../../components/form/MarkdownEditor.svelte'
    import ApiService from '../../../services/api.service.js'
    import { validator, required, minLength } from '../../../validators'
    import PageBuilder from '../../../modules/site-editor/components/PageBuilder.svelte'
    import TemplateSelector from '../../../modules/site-editor/components/TemplateSelector.svelte'
    import { slide } from 'svelte/transition'
    import DatePicker from '../../../components/DatePicker.svelte'
    import { url, goto } from "@sveltech/routify";
    import { isAfter } from 'date-fns'
    import Modal from '../../../components/Modal.svelte'
    import LoaderIcon from '../../../components/LoaderIcon.svelte'
    import Preview from '../../../modules/site-editor/components/Preview.svelte'
    import Image from '../../../modules/site-editor/blocks/Image.svelte'
    import JsonArray from '../../../modules/site-editor/components/JsonArray.svelte'
    import File from '../../../modules/site-editor/blocks/File.svelte'
    import LinkedModel from '../../../modules/site-editor/blocks/LinkedModel.svelte'
    import { site } from '../../../stores/sites.store.js'
    import Slug from '../../../modules/site-editor/components/Slug.svelte'

    export let id

    let cable = false    
    let previous = JSON.stringify(false)
    let now = new Date()
    let saving = false
    let previewing = false
    let deleteing = false
    let restoring = false
    let unpublishing = false
    let deleteModal = false
    let previewModal = false
    let previewUrl = false
    let nameTouched = false
    let rules = {
        name: [
            { 
                rule: required
            },
            {
                rule: minLength,
                params: {
                    length: 2
                }
            }
        ]
    }

    let errorMessages = {
        required: 'This is a required field',
        minLength: 'This field must be longer than %length%'
    }

    let blockErrors = []

    $: create = id === 'add' || id === 'new'
    $: getCable(id)
    $: unsaved = previous != JSON.stringify(toSave(cable))    
    $: loading = cable === false || cable === undefined
    $: nameErrors = validator(cable.name, rules.name, errorMessages)
    $: isValid = nameErrors === false
    $: checkBlocks(cable.template)
    $: disabled = saving === true || deleteing === true || restoring === true || unpublishing === true || blockErrors.length > 0    
    
    function checkBlocks() {
        if (cable === false) return
        if (cable.template.row !== null) return
        let errors = []
        for (let block of cable.blocks) {
            if (cable.template.blocks.find(b => b.id === block.block_id) === undefined) {
                errors.push(block.block_id)
            }
        }
        blockErrors = [
            ...errors
        ]
    }


    async function getCable(id) {
        if (id === undefined) return        
        let url = 'site-editor/cables/' + (create === true ? 'create' : id)

        try {
            let { data } = await ApiService.get(url)
            
            for (let item of data.template.properties) {
                let key = item.name
                if (data.properties[key] === undefined || data.properties[key] === null) {
                    if (item.component === 'Image') {
                        data.properties[key] = {}
                    } else if (item.component === 'File') {
                        data.properties[key] = {}
                    } else {
                        data.properties[key] = null
                    }
                }
            }

            cable = data

            setPrevious(cable)
        } catch (error) {
            console.error(error)
        }
    } 

    function toSave (object) {
        if (typeof object !== 'object') return object
        return {
            template: object.template.id,
            name: object.name,
            publish_at: object.published_at,
            properties: object.properties,
            linked: object.linked,
            slug: object.slug,
            blocks: object.blocks
        }
    }

    async function save() {
        if (disabled === true) return
        if (saving === true) return

        if (isValid === false) {
            forceValidation()        
            return
        }

        if (unpublishing !== true) saving = true

        let url = 'site-editor/cables'
        if (create === false) url += '/' + id

        try {            
            let {data} = await ApiService.post(url, toSave(cable))
            
            for (let item of data.template.properties) {
                let key = item.name
                
                if (data.properties[key] === undefined) {                
                    data.properties[key] = null
                }
            }

            // data.properties = data.properties

            cable = data

            reset(cable.id)
        } catch (error) {
            console.error(error)
        } finally {
            saving = false
        }
    }

    async function unpublish() {
        if (disabled === true) return
        unpublishing = true
        cable.published_at = null
        await save()        
        unpublishing = false
    }

    async function bin() {
        if (disabled === true) return
        if (deleteing === true) return
        deleteing = true

        try {            
            let response = await ApiService.delete('site-editor/cables' + '/' + id)
            
            cable = response.data
            deleteModal = false
            reset(cable.id)
        } catch (error) {
            console.error(error)
        } finally {
            deleteing = false            
        }
    }

    async function restore() {
        if (disabled === true) return
        if (restoring === true) return
        restoring = true

        try {            
            let response = await ApiService.get('site-editor/cables' + '/' + id + '/restore')
            
            cable = response.data
            
            reset(cable.id)
        } catch (error) {
            console.error(error)
        } finally {
            restoring = false            
        }
    }

    async function preview() {
        if (disabled === true) return
        if (previewing === true) return
        previewing = true

        try {
            let { data } = await ApiService.post('site-editor/preview', {
                'name': cable.name,
                'blocks': cable.blocks,
                'properties': cable.properties,
                'linked': cable.linked
            })

            previewModal = true
            previewUrl = data.site + '/cables/' + cable.slug + '/?cable&preview=' + data.uuid
        } catch {

        } finally {
            previewing = false
        }
    }

    function forceValidation () {
        nameTouched = true
    }

    function reset(id) {
        if (create === true) {
            $goto($url('../:id', { id }))
        }
        nameErrors = false
        nameTouched = false
        setPrevious(cable)
    }
    
    function setPrevious(current) {
        setTimeout (() => {
            let obj = toSave(current)
            
            previous = JSON.stringify(obj)
        }, 250)
    }

    function updateFilterOption (id, option) {
        let index = cable.linked.filters.findIndex(f => f.id === id)
        if (index === -1) return
        if (cable.linked.filters[index] === undefined) return        
        if (cable.linked.filters[index].selected.includes(option)) {
            cable.linked.filters[index].selected = cable.linked.filters[index].selected.filter(o => o !== option)
        } else {
            cable.linked.filters[index].selected = [
                ...cable.linked.filters[index].selected,
                option
            ]
        }
    }
</script>
<div class="grid">

    <div class="main-col">
        <div class="content-card">
            <Card title="Basic Information" loading={loading === true}>                     
                <div class="form">
                    <div class="form-group">
                        <TextInput label="Title" name="name" bind:value={cable.name} errors={nameErrors} touched={nameTouched} />
                    </div>  

                    <div class="form-group">
                        <Slug bind:slug={cable.slug} url={$site.domain} prefix="cables" />
                    </div>  

                    {#each cable.template.properties as item, key}          
                    <div class="form-group">                    
                        {#if item.component === 'Image'}
                            <Image label={item.label} bind:content={cable.properties[item.name]} bind:properties={cable.properties[item.name].properties} />
                        {:else if item.component === 'File'}
                            <File label={item.label} bind:content={cable.properties[item.name]} />
                        {:else if item.component === 'JsonArray'}
                            <JsonArray label={item.label} bind:content={cable.properties[item.name]} />
                        {:else if item.component === 'LinkedModel'}
                            <LinkedModel 
                                label={item.label}
                                bind:selected={cable.properties[item.name]} 
                                unselected="No {item.label}s selected" 
                                placeholder="Search for {item.label}"
                                model={item.options.model}
                                limit={item.options.limit}
                            />
                        {/if}
                    </div>   
                    {/each}
                </div>    
            </Card>
        </div>

        <div class="content-card">
            <Card title="Page Builder" loading={loading === true}>
                <PageBuilder 
                    row={cable.template.row_block_id} 
                    title={cable.title} 
                    errors={blockErrors} 
                    blockTypes={cable.template.blocks} 
                    bind:blocks={cable.blocks} 
                />                
            </Card>
        </div>

        <div class="content-card">
            <Card title="Widgets" loading={loading === true}>
                <LinkedModel 
                    bind:selected={cable.linked.widgets} 
                    unselected="No widget blocks selected" 
                    placeholder="Search for widget blocks"
                    model="widget-blocks"
                />
            </Card>
        </div>

        <div class="content-card">
            <Card title="Related Products" loading={loading === true}>
                <LinkedModel 
                    bind:selected={cable.linked.related} 
                    unselected="No cables selected" 
                    placeholder="Search for cables"
                    model="cables"
                    exclude={[cable.id]}
                />
            </Card>
        </div>

        <div class="content-card filters">
            <Card title="Filters" loading={loading === true}>
                <LinkedModel 
                    bind:selected={cable.linked.filters} 
                    unselected="No filters selected" 
                    placeholder="Search for filters"
                    model="filters"
                    let:item let:nameLabel let:remove
                >
                    <div class="name">
                        {item[nameLabel]}
                    </div>

                    <div class="options">
                        {#if item.type === 'boolean'}
                            <div class="option" on:click={() => updateFilterOption(item.id, true)}>
                                <div class="box">
                                    {#if item.selected.includes(true)}
                                    <svg class="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
                                    {/if}
                                </div> 
                            </div>
                        {:else}
                            {#each item.options as option}
                                <div class="option" on:click={() => updateFilterOption(item.id, option.value)}>
                                    <div class="box">
                                        {#if item.selected.includes(option.value)}
                                        <svg class="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
                                        {/if}
                                    </div>  
                                    <div class="value">
                                        {option.value}
                                    </div>                            
                                </div>
                            {/each}
                        {/if}
                    </div>

                    <div class="remove" on:click={() => remove(item)}>                
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>                
                    </div>
                </LinkedModel>
            </Card>
        </div>
    </div>

    <div class="sidebar">
        <div class="sidebar-card">           
            <div class="actions">          
                <Btn block blue on:click={preview} {disabled}>
                    Preview
                </Btn> 

                <Btn block green on:click={save} {disabled} loading={saving === true}>
                    Save
                </Btn>   
            </div>     
        </div>

        

        <div class="sidebar-card">
            <Card loading={loading === true}>
                <div class="form">       
                    {#if cable.created_at !== null}
                    <div class="form-group">
                        <DatePicker label="Created" readonly value={cable.created_at} />                           
                    </div>
                    {/if}
                    
                    {#if cable.updated_at !== null}
                    <div class="form-group">
                        <DatePicker label="Updated" readonly value={cable.updated_at} />
                    </div> 
                    {/if}  

                    <div class="form-group">
                        Status: {cable.status === null ? 'Unsaved Draft' : cable.status.value}
                    </div>    
                    
                    {#if cable.status !== null}
                    <div class="form-group">
                        <DatePicker label={cable.status && cable.status.key === 'published' ? 'Published on' : 'Publish on'} bind:value={cable.published_at} min={now} dateFormat="dd/MM/yyyy" />
                    </div>                     
                    {/if}

                    {#if cable.deleted_at !== null}
                    <div class="form-group">
                        <DatePicker label="Deleted" readonly value={cable.deleted_at} />
                    </div> 
                    {/if}  

                    
                </div>
                <div class="actions" slot="actions">                    
                    {#if cable.status && cable.status.key === 'published'}         
                    <Btn block orange on:click={unpublish} {disabled} loading={unpublishing}>
                        Unpublish 
                    </Btn>
                    {/if} 
                    {#if cable.status !== null && cable.deleted_at === null}
                    <Btn block red on:click={() => deleteModal = true} {disabled}>
                        Delete
                    </Btn> 
                    {/if}
                    {#if cable.status !== null && cable.deleted_at !== null}
                    <Btn block orange on:click={restore} {disabled} loading={restoring}>
                        Restore
                    </Btn> 
                    {/if}
                </div>
            </Card>
        </div>  

        <div class="sidebar-card">           
            <Card title="Template" loading={loading === true}>
                <TemplateSelector templates={cable.templates} bind:template={cable.template} />
            </Card>     
        </div>

        <div class="sidebar-card">
            <Card title="Cable Range" loading={loading === true}>
                <LinkedModel 
                    bind:selected={cable.linked.ranges} 
                    unselected="No cable ranges selected" 
                    placeholder="Search for cable ranges"
                    model="cable-ranges"
                    ordered
                />
            </Card>
        </div>

        <div class="sidebar-card">
            <Card title="Brands" loading={loading === true}>
                <LinkedModel 
                    bind:selected={cable.linked.brands} 
                    unselected="No brands selected" 
                    placeholder="Search for brands"
                    model="brands"
                />
            </Card>
        </div>

        <div class="sidebar-card">
            <Card title="Approvals" loading={loading === true}>
                <LinkedModel 
                    bind:selected={cable.linked.approvals} 
                    unselected="No approvals selected" 
                    placeholder="Search for approvals"
                    model="approvals"
                />
            </Card>
        </div>
    </div>    
</div>

{#if unsaved === true}
    <div class="sidebar-card warning unsaved" transition:slide|local={{delay: 200}}>
        <Card>
            You have unsaved changed
        </Card>            
    </div>
{/if}

{#if deleteModal === true}
    <Modal>
        <Card title="Delete Cable">
            <div class="delete-modal">
                {#if loading === false}
                <div class="message">
                    Are you sure you want to delete this cable?
                </div>
                {:else}
                    <LoaderIcon />
                {/if}
            </div>
            <div class="actions" slot="actions">
                <Btn block red on:click={() => deleteModal = false}>
                    No
                </Btn> 
                <Btn block green on:click={bin}>
                    Yes
                </Btn> 
            </div>
        </Card>
    </Modal>
{/if}

{#if previewModal === true}
    <Preview url={previewUrl} loading={previewing} on:close={() => previewModal = false } />
{/if}
<style>
.grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 40px;
    align-items: flex-start;
}

.main-col {
    display: grid;
    grid-row-gap: 40px;
    padding-bottom: 300px;
}

.content-card,
.sidebar-card {
    border: 1px solid #ddd;    
}

.sidebar-card {
    margin-bottom: 40px;
}

.actions {
    display: flex;
}

.actions .btn {
    width: 50%;
}

.form {
    display: grid;
    grid-row-gap: 20px;
}

.sidebar-card.warning {
    border-color: #ee7b00;
}

.sidebar-card.warning :global(.card .body) {    
    background: #ee7b00!important;
    color: #FFF;
}

.unsaved {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    text-align: center;
    z-index: 1;
}

.message {
    font-size: 18px;
    font-weight: 300;    
}

.delete-modal {
    display: flex;
    min-height: 6em;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.delete-modal .message {    
    padding: 0 20px;
}

label {
    font-size: 17px;
    color: #2c3e50;
    margin-bottom: 0.25em;
}

.box {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
}

.options {
    display: flex;
    row-gap: 7px;
    grid-auto-flow: column;
    justify-items: flex-start;
    justify-content: flex-start;
    column-gap: 25px;
    flex-wrap: wrap;
}

.option {
    display: grid;
    grid-template-columns: 20px auto;
    column-gap: 5px;
    cursor: pointer;
    align-items: center;
}

.filters :global(.linked-model .selected .item) {
    grid-template-columns: 20ch auto fit-content(2em)!important;
    align-items: center;
    row-gap: 15px;
}

.filters :global(.linked-model .selected) {
    row-gap: 20px;
}</style>