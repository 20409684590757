<script>
    import Btn from '../../components/Btn.svelte'
    import Card from '../../components/Card.svelte'    
    import TextInput from '../../components/form/TextInput.svelte'
    import MarkdownEditor from '../../components/form/MarkdownEditor.svelte'
    import ApiService from '../../services/api.service.js'
    import { validator, required, minLength, email, matches, sometimes } from '../../validators'
    import PageBuilder from '../../modules/site-editor/components/PageBuilder.svelte'
    import TemplateSelector from '../../modules/site-editor/components/TemplateSelector.svelte'
    import { slide } from 'svelte/transition'
    import DatePicker from '../../components/DatePicker.svelte'
    import { url, goto } from "@sveltech/routify";
    import { isAfter } from 'date-fns'
    import Modal from '../../components/Modal.svelte'
    import LoaderIcon from '../../components/LoaderIcon.svelte'
    import Preview from '../../modules/site-editor/components/Preview.svelte'
    import LinkedModel from '../../modules/site-editor/blocks/LinkedModel.svelte'
    import { site } from '../../stores/sites.store.js'
    import { titleCase, pluralise } from '../../utils/helpers.js'   
    import { writable } from 'svelte/store'
    import { setContext } from 'svelte'
    import ErrorWell from '../../components/ErrorWell.svelte'

    const title = 'My Account'
    const apiRoute = 'auth'

    const model = writable(false)

    setContext('model', $model)

    let previous = JSON.stringify(false)
    let saving = false

    let firstNameTouched = false
    let lastNameTouched = false
    let emailTouched = false
    let currentPasswordTouched = false
    let passwordTouched = false
    let passwordConfirmTouched = false
    let passwordChangeError = false

    let rules = {
        first_name: [
            { 
                rule: 'required'
            },
            {
                rule: 'minLength',
                params: {
                    length: 2
                }
            }
        ],
        last_name: [
            { 
                rule: 'required'
            },
            {
                rule: 'minLength',
                params: {
                    length: 2
                }
            }
        ],
        email: [
            {
                rule: 'email'
            }
        ],
        password: [
            {
                rule: 'sometimes'
            },
            {
                rule: 'minLength',
                params: {
                    length: 8
                }
            }
        ],
        password_confirm: [           
            {
                rule: 'sometimes'
            }, 
            {
                rule: 'matches',
                params: {
                    key: 'password',
                    label: 'new password',
                    value: $model.password
                }
            }
        ]
    }

    let blockErrors = []

    let errorMessages = {
        required: 'This is a required field',
        email: 'This must be a valid email',
        minLength: 'This field must be longer than %length%',
        matches: 'This field must match %label%'
    }

    $: getModel()
    $: unsaved = previous != JSON.stringify(toSave($model))    
    $: loading = $model === false || $model === undefined
    $: firstNameErrors = validator($model.first_name, rules.first_name, errorMessages)
    $: lastNameErrors = validator($model.last_name, rules.last_name, errorMessages)
    $: emailErrors = validator($model.email, rules.email, errorMessages)
    $: currentPasswordErrors = validator($model.current_password, rules.password, errorMessages)
    $: passwordErrors = validator($model.password, rules.password, errorMessages)
    $: passwordConfirmErrors = validator([$model.password_confirm, $model.password], rules.password_confirm, errorMessages)
    $: isValid = firstNameErrors === false && lastNameErrors === false && emailErrors === false && currentPasswordErrors === false && passwordErrors === false && passwordConfirmErrors === false
    $: disabled = saving === true

    async function getModel() {     
        let url = apiRoute

        try {
            let { data } = await ApiService.get(url)

            $model = data

            previous = JSON.stringify(toSave($model))
        } catch (error) {
            console.error(error)
        }
    }

    function toSave (object) {
        if (object === undefined) return undefined
        if (typeof object !== 'object') return object
        return {
            first_name: object.first_name,
            last_name: object.last_name,
            email: object.email,
            current_password: object.current_password,
            password: object.password,
            password_confirm: object.password_confirm
        }
    }

    async function save() {
        if (saving === true) return

        if (disabled === true) return

        if (isValid === false) {
            forceValidation()        
            return
        }

        saving = true
        passwordChangeError = false

        let url = apiRoute

        try {            
            let response = await ApiService.post(url, toSave($model))
            
            $model = response.data
            reset($model.id)
        } catch (error) {
            if(error.response.status === 422) {
                passwordChangeError = error.response.data.message
            } else {
                console.error(error)
            }
        } finally {
            saving = false
        }
    }

    function forceValidation () {
        firstNameTouched = true
        lastNameTouched = true
        emailTouched = true
        currentPasswordTouched = true
        passwordTouched = true
        passwordConfirmTouched = true
    }

    function reset(id) {
        firstNameTouched = false
        lastNameTouched = false
        emailTouched = false
        currentPasswordTouched = false
        passwordTouched = false
        passwordConfirmTouched = false
        previous = JSON.stringify(toSave($model))
    }
</script>
<div class="grid">

    <div class="main-col">
        <div class="content-card">
            <Card title="Basic Information {isValid}" loading={loading === true}>                     
                <div class="form">
                    <div class="form-group">
                        <TextInput label="First Name" name="first_name" bind:value={$model.first_name} errors={firstNameErrors} touched={firstNameTouched} />
                    </div>  

                    <div class="form-group">
                        <TextInput label="Last Name" name="last_name" bind:value={$model.last_name} errors={lastNameErrors} touched={lastNameTouched} />
                    </div>  

                    <div class="form-group">
                        <TextInput label="Email" name="email" bind:value={$model.email} errors={emailErrors} touched={emailTouched} />
                    </div>  
                </div>    
            </Card>
        </div>

        <div class="content-card">
            <Card title="Change Password" loading={loading === true}>                     
                <div class="form">
                    <div class="form-group">
                        <TextInput autocomplete={false} type="password" label="Current Password" name="current_password" bind:value={$model.current_password} errors={currentPasswordErrors} touched={currentPasswordTouched} />
                    </div>  

                    <div class="form-group">
                        <TextInput autocomplete={false} type="password" label="New Password" name="password" bind:value={$model.password} errors={passwordErrors} touched={passwordTouched} />
                    </div> 

                    <div class="form-group">
                        <TextInput autocomplete={false} type="password" label="Confirm New Password" name="password_confirm" bind:value={$model.password_confirm} errors={passwordConfirmErrors} touched={passwordConfirmTouched} />
                    </div>

                    {#if passwordChangeError !== false}
                    <div class="well well-error" transition:slide|local>
                        <ErrorWell error={passwordChangeError} />
                    </div>
                    {/if}   
                </div>    
            </Card>
        </div>
    </div>

    <div class="sidebar">
        <div class="sidebar-card">           
            <div class="actions"> 
                <Btn block green on:click={save} {disabled} loading={saving === true}>
                    Save
                </Btn>   
            </div>     
        </div>

        <div class="sidebar-card">
            <Card loading={loading === true}>
                <div class="form">       
                    {#if $model.created_at !== null}
                    <div class="form-group">
                        <DatePicker label="Created" readonly value={$model.created_at} />                           
                    </div>
                    {/if}
                    
                    {#if $model.updated_at !== null}
                    <div class="form-group">
                        <DatePicker label="Updated" readonly value={$model.updated_at} />
                    </div> 
                    {/if}   
                    
                    {#if $model.deleted_at !== null}
                    <div class="form-group">
                        <DatePicker label="Deleted" readonly value={$model.deleted_at} />
                    </div> 
                    {/if}                      
                </div>                
            </Card>
        </div>  
    </div>    
</div>

{#if unsaved === true}
    <div class="sidebar-card warning unsaved" transition:slide|local={{delay: 200}}>
        <Card>
            You have unsaved changed
        </Card>            
    </div>
{/if}

<style>
.grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 40px;
    align-items: flex-start;
}

.main-col {
    display: grid;
    grid-row-gap: 40px;
    padding-bottom: 40px;
}

.content-card,
.sidebar-card {
    border: 1px solid #ddd;    
}

.sidebar-card {
    margin-bottom: 40px;
}

.actions {
    display: flex;
}

.actions .btn {
    width: 50%;
}

.form {
    display: grid;
    grid-row-gap: 20px;
}

.sidebar-card.warning {
    border-color: #ee7b00;
}

.sidebar-card.warning :global(.card .body) {    
    background: #ee7b00!important;
    color: #FFF;
}

.unsaved {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    text-align: center;
    z-index: 1;
}

.message {
    font-size: 18px;
    font-weight: 300;    
}

.delete-modal {
    display: flex;
    min-height: 6em;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.delete-modal .message {    
    padding: 0 20px;
}</style>