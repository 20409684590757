<script>
  import { createEventDispatcher, onMount } from "svelte";
  import ApiService from "../../../services/api.service";
  import TextInput from "../../../components/form/TextInput.svelte";
  import debounce from "lodash/debounce";

  export let model;
  export let title = '';
  export let noResults = 'No items found.'
  export let selected = [];

  const dispatch = createEventDispatcher();

  let searching = false;
  let searchTerm = null;
  let items = []
  let more = false;
  let page = 1;

  const debounceSearch = debounce(() => {
    fetch();
  }, 500);

  $: debounceSearch(searchTerm);

  async function fetch() {
    if (searching === true) return;

    searching = true;

    try {
      let response = await ApiService.get(`site-editor/${model}`, {
        search: searchTerm === "" || searchTerm === null ? undefined : searchTerm,
        per_page: 50,
        page
      });

      // let data = await response.json();

      items = response.data.data;
      more = response.data.current_page < response.data.last_page;
      page = response.data.current_page;
    } catch (e) {
      console.error(e)
      console.error("Error");
    } finally {
      searching = false;
    }
  }

  onMount(() => {
    fetch();
  });
</script>

<div class="layout">
    <div class="header">
        <div class="title">
          {title} browser
        </div>
        <div class="search">
          <TextInput disabled={searching} name="search" bind:value={searchTerm} noValidation placeholder="Search" />
        </div>
        <div class="close" on:click={() => dispatch('close')}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>
      </div>
    </div>
    <div class="results">
        {#each items as item}
            <div class="tile" on:click={() => dispatch('selected', item)}>
              <slot name="tile" {item}>
                  {item.name}
              </slot>
              {#if selected.includes(item)}
                <div class="selected"></div>
              {/if}
            </div>
        {:else}
            <div class="message">
                {noResults}
            </div>
        {/each}
    </div>

    <div class="sidebar">

    </div>
</div>

<style>
.header {
  background: #000;
  display: grid;
  justify-content: normal;
  align-items: center;
  color: #FFF;
  grid-template-columns: auto auto max-content;
}
.header .title {
  padding: 15px;
}
.header .search {
  margin-right: 15px;
  justify-self: end;
}
.close {
  width: 30px;
  height: 30px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  margin-right: 15px;
  justify-self: end;
}
.close svg {
  width: 1em;
  height: 1em;
}
.close svg path {
  fill: #fff;
}
.close:hover {
  background: #fd7e14;
}
.results {
  padding: 15px;
  display: grid;
  row-gap: 15px;
  column-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.tile {
  cursor: pointer;
  position: relative;  
  border: 1px solid #e9e9e9;
  padding: 5px 10px;
  transition: all 150ms ease-in;
}
.tile .selected {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 3px solid #fd7e14;
}
.tile:hover {  
  border-color: #58595b;  
}</style>