<script>
    import Btn from '../../../components/Btn.svelte'
    import Card from '../../../components/Card.svelte'    
    import TextInput from '../../../components/form/TextInput.svelte'
    import MarkdownEditor from '../../../components/form/MarkdownEditor.svelte'
    import ApiService from '../../../services/api.service.js'
    import { validator, required, minLength } from '../../../validators'
    import PageBuilder from '../../../modules/site-editor/components/PageBuilder.svelte'
    import TemplateSelector from '../../../modules/site-editor/components/TemplateSelector.svelte'
    import { slide } from 'svelte/transition'
    import DatePicker from '../../../components/DatePicker.svelte'
    import { url, goto } from "@sveltech/routify";
    import { isAfter } from 'date-fns'
    import Modal from '../../../components/Modal.svelte'
    import LoaderIcon from '../../../components/LoaderIcon.svelte'
    import Preview from '../../../modules/site-editor/components/Preview.svelte'
    import { site } from '../../../stores/sites.store.js'
    import LinkedModel from '../../../modules/site-editor/blocks/LinkedModel.svelte'

    export let id

    let model = false    
    let previous = JSON.stringify(false)
    let now = new Date()
    let saving = false
    let previewing = false
    let deleteing = false
    let restoring = false
    let unpublishing = false
    let deleteModal = false
    let previewModal = false
    let previewUrl = false
    let nameTouched = false
    let rules = {
        name: [
            { 
                rule: required
            },
            {
                rule: minLength,
                params: {
                    length: 2
                }
            }
        ]
    }

    let blockErrors = []

    let errorMessages = {
        required: 'This is a required field',
        minLength: 'This field must be longer than %length%'
    }

    $: create = id === 'add' || id === 'new'
    $: getModel(id)
    $: unsaved = previous != JSON.stringify(toSave(model))    
    $: loading = model === false || model === undefined
    $: nameErrors = validator(model.name, rules.name, errorMessages)
    $: isValid = nameErrors === false && blockErrors.length === 0
    $: checkBlocks(model.template)
    $: disabled =  saving === true || deleteing === true || restoring === true || unpublishing === true || blockErrors.length > 0
    $: console.log(model.properties)
    function checkBlocks() {
        if (model === false) return
        if (model.template.row !== null) return
        let errors = []
        for (let block of model.blocks) {
            if (model.template.blocks.find(b => b.id === block.block_id) === undefined) {
                errors.push(block.block_id)
            }
        }
        blockErrors = [
            ...errors
        ]
    }

    async function getModel(id) {
        if (id === undefined) return        
        let url = 'site-editor/widget-blocks/' + (create === true ? 'create' : id)

        try {
            let { data } = await ApiService.get(url)
            
            if((Array.isArray(data.properties) && data.properties.length === 0) || data.properties === null) data.properties = {}
            
            for (let item of data.template.properties) {
                let key = item.name
                if (data.properties[key] === undefined) data.properties[key] = null
            }

            model = data

            setPrevious(model)
        } catch (error) {
            console.error(error)
        }
    }

    function toSave (object) {
        if (object === undefined) return undefined
        if (typeof object !== 'object') return object
        return {
            template: object.template.id,
            properties: object.properties,
            name: object.name,
            publish_at: object.published_at,
            slug: object.slug,
            blocks: object.blocks
        }
    }

    async function save() {
        if (saving === true) return

        if (disabled === true) return

        if (isValid === false) {
            forceValidation()        
            return
        }

        if (unpublishing !== true) saving = true

        let url = 'site-editor/widget-blocks'
        if (create === false) url += '/' + id

        try {            
            let {data} = await ApiService.post(url, toSave(model))

            for (let item of data.template.properties) {
                let key = item.name
                
                if (data.properties[key] === undefined) {                
                    data.properties[key] = null
                }
            }
            
            model = data
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            saving = false
        }
    }

    async function unpublish() {
        unpublishing = true
        model.published_at = null
        await save()        
        unpublishing = false
    }

    async function bin() {
        if (disabled === true) return
        if (deleteing === true) return
        deleteing = true

        try {            
            let response = await ApiService.delete('site-editor/widget-blocks' + '/' + id)
            
            model = response.data
            deleteModal = false
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            deleteing = false            
        }
    }

    async function restore() {
        if (disabled === true) return
        if (restoring === true) return
        restoring = true

        try {            
            let response = await ApiService.get('site-editor/widget-blocks' + '/' + id + '/restore')
            
            model = response.data
            
            reset(model.id)
        } catch (error) {
            console.error(error)
        } finally {
            restoring = false            
        }
    }

    function forceValidation () {
        nameTouched = true
    }

    function reset(id) {
        if (create === true) {
            $goto($url('../:id', { id }))
        }
        nameErrors = false
        nameTouched = false
        setPrevious(model)
    }

    function setPrevious(current) {
        setTimeout (() => {
            previous = JSON.stringify(toSave(current))
        }, 250)
    }
</script>
<div class="grid">

    <div class="main-col">
        <div class="content-card">
            <Card title="Basic Information" loading={loading === true}>                     
                <div class="form">
                    <div class="form-group">
                        <TextInput label="Title" name="name" bind:value={model.name} errors={nameErrors} touched={nameTouched} />
                    </div>   
                    
                    {#each model.template.properties as item, key}          
                    <div class="form-group">                    
                        {#if item.component === 'LinkedModel'}
                            <LinkedModel 
                                label={item.label}
                                bind:selected={model.properties[item.name]}
                                unselected="No item selected" 
                                placeholder="Search for item"
                                model="links"                    
                                limit={item.limit}
                            />
                        {/if}
                    </div>   
                    {/each}
                </div>    
            </Card>
        </div>

        <div class="content-card">
            <Card title="Widget Builder" loading={loading === true}>
                <PageBuilder 
                    row={model.template.row_block_id} 
                    title={model.name} 
                    errors={blockErrors} 
                    blockTypes={model.template.blocks} 
                    bind:blocks={model.blocks} 
                />                
            </Card>
        </div>
    </div>

    <div class="sidebar">
        <div class="sidebar-card">           
            <div class="actions"> 
                <Btn block green on:click={save} {disabled} loading={saving === true}>
                    Save
                </Btn>   
            </div>     
        </div>

        <div class="sidebar-card">
            <Card loading={loading === true}>
                <div class="form">       
                    {#if model.created_at !== null}
                    <div class="form-group">
                        <DatePicker label="Created" readonly value={model.created_at} />                           
                    </div>
                    {/if}
                    
                    {#if model.updated_at !== null}
                    <div class="form-group">
                        <DatePicker label="Updated" readonly value={model.updated_at} />
                    </div> 
                    {/if}  

                    <div class="form-group">
                        Status: {model.status === null ? 'Unsaved Draft' : model.status.value}
                    </div>    
                    
                    {#if model.status !== null}
                    <div class="form-group">
                        <DatePicker label={model.status && model.status.key === 'published' ? 'Published on' : 'Publish on'} bind:value={model.published_at} min={now} dateFormat="dd/MM/yyyy" />
                    </div>                     
                    {/if}

                    {#if model.deleted_at !== null}
                    <div class="form-group">
                        <DatePicker label="Deleted" readonly value={model.deleted_at} />
                    </div> 
                    {/if}  

                    
                </div>
                <div class="actions" slot="actions">                    
                    {#if model.status && model.status.key === 'published'}         
                    <Btn block orange on:click={unpublish} {disabled} loading={unpublishing}>
                        Unpublish 
                    </Btn>
                    {/if} 
                    {#if model.status !== null && model.deleted_at === null}
                    <Btn block red on:click={() => deleteModal = true} {disabled}>
                        Delete
                    </Btn> 
                    {/if}
                    {#if model.status !== null && model.deleted_at !== null}
                    <Btn block orange on:click={restore} {disabled} loading={restoring}>
                        Restore
                    </Btn> 
                    {/if}
                </div>
            </Card>
        </div>  
    </div>    
</div>

{#if unsaved === true}
    <div class="sidebar-card warning unsaved" transition:slide|local={{delay: 200}}>
        <Card>
            You have unsaved changed
        </Card>            
    </div>
{/if}

{#if deleteModal === true}
    <Modal>
        <Card title="Delete Widget Block">
            <div class="delete-modal">
                {#if loading === false}
                <div class="message">
                    Are you sure you want to delete this widget block?
                </div>
                {:else}
                    <LoaderIcon />
                {/if}
            </div>
            <div class="actions" slot="actions">
                <Btn block red on:click={() => deleteModal = false}>
                    No
                </Btn> 
                <Btn block green on:click={bin}>
                    Yes
                </Btn> 
            </div>
        </Card>
    </Modal>
{/if}

{#if previewModal === true}
    <Preview url={previewUrl} loading={previewing} on:close={() => previewModal = false } />
{/if}
<style>
.grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 40px;
    align-items: flex-start;
}

.main-col {
    display: grid;
    grid-row-gap: 40px;
    padding-bottom: 40px;
}

.content-card,
.sidebar-card {
    border: 1px solid #ddd;    
}

.sidebar-card {
    margin-bottom: 40px;
}

.actions {
    display: flex;
}

.actions .btn {
    width: 50%;
}

.form {
    display: grid;
    grid-row-gap: 20px;
}

.sidebar-card.warning {
    border-color: #ee7b00;
}

.sidebar-card.warning :global(.card .body) {    
    background: #ee7b00!important;
    color: #FFF;
}

.unsaved {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    text-align: center;
    z-index: 1;
}

.message {
    font-size: 18px;
    font-weight: 300;    
}

.delete-modal {
    display: flex;
    min-height: 6em;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.delete-modal .message {    
    padding: 0 20px;
}</style>