<script>
    import ApiService from '../../../services/api.service'
    import { onMount } from 'svelte'
    import LoaderIcon from '../../../components/LoaderIcon.svelte'
    import MediaTile from './MediaTile.svelte'
    import Btn from '../../../components/Btn.svelte'
    import { mediaLibrary } from '../stores/media-library.store.js'
    import debounce from 'lodash/debounce'

    export let searchTerm = null

    let managing = false

    let files = false
    let total = 0
    let page = 1
    let more = false
    let searching = false   

    $: disabled = $mediaLibrary === false || $mediaLibrary === 1 ? false : $mediaLibrary.selected.length >= $mediaLibrary
    $: runSearch(searchTerm)

    const runSearch = debounce(() => {
        search()
    }, 500)
    

    function toggleSelected (item) {
        if ($mediaLibrary.selected.includes(item)) {
            mediaLibrary.unselect(item)
        }  else if (disabled === false) {
            if ($mediaLibrary.max === 1) {
                mediaLibrary.setSelected([item])
            } else {
                mediaLibrary.select(item)
            }
        }
    }

    async function search () {
        if (searching === true) return
        
        searching = true

        try {            
            let response = await ApiService.get('site-editor/media/list', {
                type: $mediaLibrary.type,
                search: searchTerm === '' || searchTerm === null ? undefined : searchTerm
            })
            
            files = response.data.data.data
            page = response.data.meta.page
            more = response.data.data.to < response.data.data.total
            total = response.data.data.total
        } catch {
            console.error('Error')
        } finally {
            searching = false
        }
    }

    function thumbnailCrop (url, hash) {
        let params = []
		
        params.push('w=' + 300)
        params.push('hash=' + hash)

        return url + (params.length > 0 ? '?' : '') + params.join('&')
    }

    async function deleteFiles () {

    }
</script>

{#if files === false || searching === true}
    <div class="loader">
        <LoaderIcon />
    </div>
{:else}
    <div class="layout">
        <div class="files">
            {#each files as item}
                <MediaTile
                    thumbnail={thumbnailCrop(item.url, item.hash)}
                    name={item.name}
                    selected={$mediaLibrary.selected.map(s => s.id).includes(item.id)}
                    on:click={() => toggleSelected(item)}
                    disabled={disabled === true && $mediaLibrary.selected.map(s => s.id).includes(item.id) === false}
                />
            {:else}
                <div class="no-results">
                    No media items found.
                </div>
            {/each}
        </div>
        <div class="sidebar">
            {#if $mediaLibrary.selected.length === 0}
                <div class="no-selection">
                    Nothing selected.
                </div>
            {:else if $mediaLibrary.selected.length === 1}                
                <div class="thumbnail">
                    <img src={thumbnailCrop($mediaLibrary.selected[0].url, $mediaLibrary.selected[0].hash)} alt={$mediaLibrary.selected[0].name} />
                </div>

                <div class="name">
                    {$mediaLibrary.selected[0].name}
                </div>

                <div class="metas">
                    {#each $mediaLibrary.selected[0].meta as meta (meta)}
                        <div class="meta">
                            {meta.label}: {meta.value}
                        </div>
                    {:else}
                        <div class="meta">
                            No meta data
                        </div>
                    {/each}
                </div>
            {:else}
                <div class="no-selection">
                    You have selected {$mediaLibrary.selected.length} files.
                </div>
            {/if}

            <div class="spacer"></div>

            <div class="actions">
                {#if $mediaLibrary.selected.length > 0}   
                    {#if managing}             
                        <Btn red block on:click={deleteFiles}>
                            Delete selected
                        </Btn>
                    {:else}
                        <Btn green block on:click={mediaLibrary.attach}>
                            Attach
                        </Btn>
                    {/if}
                {/if}
            </div>           
        </div>
    </div>
{/if}

<style>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.layout {
    display: grid;
    grid-template-columns: auto 250px;
    grid-template-rows: 100%;
    height: 100%;
}

.files {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  padding: 15px;
  box-sizing: border-box;
  overflow-y: auto;
  grid-auto-rows: max-content;
}

.files :global(.tile img) {
    max-height: 150px;
}

.sidebar {
  background: #eee;
  align-self: stretch;
  padding: 15px;
  color: #333;
  font-weight: 300;
  display: flex;
  flex-direction: column;
}

.sidebar .spacer {
  flex-grow: 1;
}

.sidebar .thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e5e5e5;
  padding: 8px;
  width: 218px;
  height: 218px;
  background: #FFF;
  margin-bottom: 25px;
  box-sizing: border-box;
}

.sidebar .thumbnail img {
  max-width: 100%;
  max-height: 218px;
}

.sidebar .name {
  font-size: 18px;
  margin-bottom: 20px;
}

.sidebar .metas {
  font-size: 16px;
  display: grid;
  grid-row-gap: 10px;
  color: #848383;
}

.sidebar .actions {
  margin-top: 15px;
}

.no-selection {
  font-size: 16px;
}</style>