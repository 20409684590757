<script>
    import TextInput from '../.././../components/form/TextInput.svelte'
    import Image from '../blocks/Image.svelte'

    export let title = undefined;

    export let misc = {
        id: null
    }

    export let padding = {
        top: null,
        bottom: null,
        right: null,
        left: null
    }

    export let margin = {
        top: null,
        bottom: null,
        right: null,
        left: null
    }

    export let background = {
        color: null,
        image: null,
        position: null,
        attachment: null,
        size: null,
        repeat: null,
        overlay: null
    }

    export let size = {
        height: null,
        maxHeight: null,
        minHeight: null,
        width: null,
        maxWidth: null,
        minWidth: null
    }

    export let typography = {
        color: null,
        size: null,
        family: null,
        weight: null,
        align: null,
        letterSpacing: null,
        textDecoration: null,
        lineHeight: null
    }

    export let border = {
        default: null,
        top: null,
        bottom: null,
        left: null,
        right: null
    }

    export let borderRadius = {
        default: null,
        top: null,
        bottom: null,
        left: null,
        right: null
    }

    export let position = {
        position: null,
        zIndex: null,
        top: null,
        bottom: null,
        left: null,
        right: null
    }

    export let layout = {
        columns: null,
        rows: null,
        justifyContent: null,
        alignContent: null,
        justifyItems: null,
        alignItems: null,
        justifySelf: null,
        alignSelf: null,
        columnGap: null,
        rowGap: null
    }

    let sections = [
        {
            name: 'padding',
            label: 'Padding'
        },
        {
            name: 'margin',
            label: 'Margin'
        },
        {
            name: 'background',
            label: 'Background'
        },
        {
            name: 'size',
            label: 'Size'
        },
        {
            name: 'typography',
            label: 'Typography'
        },
        {
            name: 'border',
            label: 'Border'
        },
        {
            name: 'borderRadius',
            label: 'Border Radius'
        },
        {
            name: 'position',
            label: 'Position'
        },
        {
            name: 'layout',
            label: 'Layout'
        },
        {
            name: 'misc',
            label: 'Misc.'
        }
    ]

    $: active = hasSettings({ padding, margin, background, size, typography, border, borderRadius, position, layout, misc})
    $: selected = selected ? selected : active.length > 0 ? active[0] : 'padding'

    function hasSettings (style) {
        let output = []
        for (let key in style) {
            for (let prop in style[key]) {
                if (style[key][prop]) {
                    if (key === 'background' && prop === 'image') {
                        if (style[key][prop].image && style[key][prop].image.media) {
                            output.push(key)
                        }
                    } else {
                        output.push(key)
                    }
                    break
                }
            }
        }

        return output
    }
</script>

<div class="grid">    
    <div class="selector">
        {#if title}
        <div class="title">
            {title}
        </div>
        {/if}
        {#each sections as item}
        <div class="item" class:current={selected === item.name} on:click={() => selected = item.name}>
            <div class="name">
                {item.label}
            </div>
            {#if active.includes(item.name)}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 16 6 C 21.535156 6 26 10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 10.464844 10.464844 6 16 6 Z M 16 13 C 14.34375 13 13 14.34375 13 16 C 13 17.65625 14.34375 19 16 19 C 17.65625 19 19 17.65625 19 16 C 19 14.34375 17.65625 13 16 13 Z"/></svg>
            {/if}
        </div>
        {/each}
    </div>

    {#if selected === 'padding'}
    <div class="section padding">
        <div class="col">
            <TextInput noValidation label="Top" bind:value={padding.top} />
        </div>
        <div class="col">
            <TextInput noValidation label="Right" bind:value={padding.right} />
        </div>
        <div class="col">
            <TextInput noValidation label="Bottom" bind:value={padding.bottom} />
        </div>
        <div class="col">
            <TextInput noValidation label="Left" bind:value={padding.left} />
        </div>
    </div>
    {/if}

    {#if selected === 'margin'}
    <div class="section margin">
        <div class="col">
            <TextInput noValidation label="Top" bind:value={margin.top} />
        </div>
        <div class="col">
            <TextInput noValidation label="Right" bind:value={margin.right} />
        </div>
        <div class="col">
            <TextInput noValidation label="Bottom" bind:value={margin.bottom} />
        </div>
        <div class="col">
            <TextInput noValidation label="Left" bind:value={margin.left} />
        </div>
    </div>
    {/if}

    {#if selected === 'background'}
    <div class="section background">
        <div class="col">
            <TextInput noValidation label="Colour" bind:value={background.color} />
        </div>        
        <div class="col">
            <TextInput noValidation label="Position" bind:value={background.position} />
        </div>
        <div class="col">
            <TextInput noValidation label="Attachment" bind:value={background.attachment} />
        </div>
        <div class="col">
            <TextInput noValidation label="Size" bind:value={background.size} />
        </div>
        <div class="col">
            <TextInput noValidation label="Repeat" bind:value={background.repeat} />
        </div>
        <div class="col">
            <TextInput noValidation label="Overlay" bind:value={background.overlay} />
        </div>
        <div class="col image">
            <Image label="Image" bind:content={background.image} />
        </div>
    </div>
    {/if}

    {#if selected === 'size'}
    <div class="section size">
        <div class="col">
            <TextInput noValidation label="Height" bind:value={size.height} />
        </div>
        <div class="col">
            <TextInput noValidation label="Width" bind:value={size.width} />
        </div>
        <div class="col">
            <TextInput noValidation label="Max height" bind:value={size.maxHeight} />
        </div>
        <div class="col">
            <TextInput noValidation label="Max Width" bind:value={size.maxWidth} />
        </div>
        <div class="col">
            <TextInput noValidation label="Min height" bind:value={size.minHeight} />
        </div>
        <div class="col">
            <TextInput noValidation label="Min Width" bind:value={size.minWidth} />
        </div>
    </div>
    {/if}

    {#if selected === 'typography'}
    <div class="section size">
        <div class="col">
            <TextInput noValidation label="Text colour" bind:value={typography.color} />
        </div>
        <div class="col">
            <TextInput noValidation label="Font size" bind:value={typography.size} />
        </div>
        <div class="col">
            <TextInput noValidation label="Font family" bind:value={typography.family} />
        </div>
        <div class="col">
            <TextInput noValidation label="Font weight" bind:value={typography.weight} />
        </div>
        <div class="col">
            <TextInput noValidation label="Text Align" bind:value={typography.align} />
        </div>
        <div class="col">
            <TextInput noValidation label="Text decoration" bind:value={typography.textDecoration} />
        </div>
        <div class="col">
            <TextInput noValidation label="Letter spacing" bind:value={typography.letterSpacing} />
        </div>
        <div class="col">
            <TextInput noValidation label="Line Height" bind:value={typography.lineHeight} />
        </div>        
    </div>
    {/if}

    {#if selected === 'border'}
    <div class="section border">
        <div class="col">
            <TextInput noValidation label="Default" bind:value={border.default} />
        </div>    
        <div class="col"></div>
        <div class="col">
            <TextInput noValidation label="Top" bind:value={border.top} />
        </div>
        <div class="col">
            <TextInput noValidation label="Right" bind:value={border.right} />
        </div>
        <div class="col">
            <TextInput noValidation label="Bottom" bind:value={border.bottom} />
        </div>
        <div class="col">
            <TextInput noValidation label="Left" bind:value={border.left} />
        </div>
    </div>
    {/if}

    {#if selected === 'borderRadius'}
    <div class="section border">
        <div class="col">
            <TextInput noValidation label="Default Radius" bind:value={borderRadius.default} />
        </div>    
        <div class="col"></div>
        <div class="col">
            <TextInput noValidation label="Top left" bind:value={borderRadius.topLeft} />
        </div>
        <div class="col">
            <TextInput noValidation label="Top Right" bind:value={borderRadius.topRight} />
        </div>
        <div class="col">
            <TextInput noValidation label="Bottom Left" bind:value={borderRadius.bottomLeft} />
        </div>
        <div class="col">
            <TextInput noValidation label="Bottom Right" bind:value={borderRadius.bottomRight} />
        </div>
    </div>
    {/if}

    {#if selected === 'position'}
    <div class="section position">
        <div class="col">
            <TextInput noValidation label="Position" bind:value={position.position} />
        </div>        
        <div class="col">
            <TextInput noValidation label="Z-Index" bind:value={position.zIndex} />
        </div>  
        <div class="col">
            <TextInput noValidation label="Top" bind:value={position.top} />
        </div>
        <div class="col">
            <TextInput noValidation label="Right" bind:value={position.right} />
        </div>
        <div class="col">
            <TextInput noValidation label="Bottom" bind:value={position.bottom} />
        </div>
        <div class="col">
            <TextInput noValidation label="Left" bind:value={position.left} />
        </div>
    </div>
    {/if}

    {#if selected === 'layout'}
    <div class="section layout">
        <div class="col">
            <TextInput noValidation label="Grid Template Columns" bind:value={layout.columns} />
        </div>
        <div class="col">
            <TextInput noValidation label="Grid Template Rows" bind:value={layout.rows} />
        </div>
        <div class="col">
            <TextInput noValidation label="Justify Content" bind:value={layout.justifyContent} />
        </div>
        <div class="col">
            <TextInput noValidation label="Align Content" bind:value={layout.alignContent} />
        </div>
        <div class="col">
            <TextInput noValidation label="Justify Items" bind:value={layout.justifyItems} />
        </div>
        <div class="col">
            <TextInput noValidation label="Align Items" bind:value={layout.alignItems} />
        </div>
        <div class="col">
            <TextInput noValidation label="Justify Self" bind:value={layout.justifySelf} />
        </div>
        <div class="col">
            <TextInput noValidation label="Align Self" bind:value={layout.alignSelf} />
        </div>
        <div class="col">
            <TextInput noValidation label="Column Gap" bind:value={layout.columnGap} />
        </div>
        <div class="col">
            <TextInput noValidation label="Row Gap" bind:value={layout.rowGap} />
        </div>
    </div>
    {/if}

    {#if selected === 'misc'}
    <div class="section misc">
        <div class="col">
            <TextInput noValidation label="Anchor ID" bind:value={misc.id} />
        </div>  
        <div class="col">&nbsp;</div>      
    </div>
    {/if}
</div>

<style>
.grid {
    display: grid;
    grid-template-columns: fit-content(10ch) auto;
    grid-column-gap: 25px;
    width: auto;
}

.grid .title {
    border-bottom: 1px solid #ddd;
    margin: 0 -25px 0 -15px;
    padding: 0 25px 10px 15px;
    font-weight: 500;
}

.selector {
    display: grid;
    grid-row-gap: 10px;
    align-content: flex-start;
    border-right: 1px solid #ddd;
    padding: 15px 25px 15px 0;
    margin: -15px 0;
}

.selector .item {
    display: grid;
    grid-template-columns: auto 1em;
    transition: 150ms all ease-in;
}

.selector .item:hover {
    color: #000;
}

.selector .item.current {
    color: #fd7e14;
}

.selector .item.current svg {
    fill: #fd7e14;
}

.section {
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: auto auto;
    align-content: flex-start;
}

.section :global(.input-group) {
    width: 175px;
}

.section :global(.input-group input) {
    max-width: 100%;
}

.section.background .image {
    grid-column: 1 / 3;
}</style>