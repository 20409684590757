<script>
    import Crud from '../../../components/Crud.svelte'
    import TextInput from '../../../components/form/TextInput.svelte'
    import Btn from '../../../components/Btn.svelte'
    import { createEventDispatcher } from 'svelte'
    import ApiService from '../../../services/api.service.js'
    import { v4 as uuid } from 'uuid'

    export let parent = null
    export let noCustom = false
    export let placeholder = 'Search for menu item'

    const dispatch = createEventDispatcher()	

    let term
    let records = []
    let loading = false
    let pagination = false
    const apiRoute = 'site-editor/menus/items'

    let statuses = []

    let filters = {
        page: 1,
        per_page: 10,
        sort_by: null, 
        sort_direction: null,
        search: null,
        status: null
    }

    let customLink = false

    $: search(filters)

    async function search (options) {
        if (loading === true) return
        let { page } = options
        loading = true 

        try {
            let { data } = await ApiService.get(apiRoute + '/', filters)

            records = data.data
            statuses = data.models

            if (data.last_page < filters.page) {                
                filters.page = 1
                return
            }

            if (data.total === 0) {                
                pagination = false
            } else {                
                pagination = {
                    current: data.current_page,
                    first_page: 1,
                    last_page: data.last_page
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            loading = false
        }
    }
    
    function add (item) {        
        let url = null
        if (item.class === "Modules\SiteEditor\Entities\Media" && item.type === 'file') {            
            url = item.download
        } else if (item.url) {
            url = item.url
        }

        dispatch('add', {
            item: {
                id: item.id,
                class: item.class,
                name: item.name,
                url
            },
            parent
        })
    }

    function itemDefault () {
        return {
            id: uuid(),
            class: 'Modules\\Modules\\Entities\\CustomLink',
            name: '',
            url: ''
        }
    }
</script>

<div class="add-modal">
    {#if customLink !== false}
    <div class="custom">
        <TextInput label="Name" placeholder="Name" name="name" bind:value={customLink.name} noValidation />
        <TextInput label="URL" placeholder="https://example.com" name="url" bind:value={customLink.url} noValidation />
        <Btn green on:click={() => add(customLink)}>
            Add
        </Btn>
    </div>
    {:else}
    <div class="search">
        <form on:submit|preventDefault={() => filters.search = term}>
            <TextInput {placeholder} name="search" bind:value={term} noValidation />
            <Btn blue type="submit">
                Search
            </Btn>
        </form>
        {#if noCustom === false}
        <div class="custom">
            <Btn green on:click={() => customLink = itemDefault() }>
                Custom
            </Btn>
        </div>
        {/if}
    </div>

    <div class="results">
        <Crud {records} {pagination} {loading} {statuses} paginationShow={3} bind:filters={filters} let:items={results}>     
        {#each results as item}
            <div class="record">
                <div class="name">
                    {(filters.status === null ? item.label + ': ' : '') + item.name}              
                </div>                    
                <div class="edit">
                    <Btn green block on:click={() => add(item)}>
                        Add
                    </Btn>  
                </div>                 
            </div>
        {:else}
            <div class="no-results">
                No results found{filters.search !== null && filters.search !== undefined && filters.search.len > 0 ? ' for "' + filters.search + '"' : ''}.
            </div>
        {/each}
        </Crud>
    </div>
    {/if}
</div>

<style>

.search {
    display: flex;
    margin-bottom: 15px;
}
.search form {
    display: flex;
    flex-grow: 1;
}
.record {
    grid-template-columns: auto fit-content(4ch)!important;
}
.record .name {
    height: auto!important;
}
:global(.crud .toolbar) {
    display: grid;
    grid-column: auto auto;
}</style>