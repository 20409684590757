<script>
    import { onMount } from 'svelte'
	import Sortable from 'sortablejs'
    import { v4 as uuid } from 'uuid'
    import MenuItem from './MenuItem.svelte'
	import { createEventDispatcher } from 'svelte'
	import { menuItems } from '../stores/menu.js'

    export let parent = null
	export let showAddItem = false

    const dispatch = createEventDispatcher()

	let itemsEl
	
	$: items = $menuItems.filter(i => i.parent_id === parent)

    function reorder (newItems) {
        let i = 1
        
		for (let index in newItems) {
			newItems[index].order = i
			i++
		}

        items = newItems
        
        return items
    }
    
    onMount(() => {
		let itemsList = Sortable.create(itemsEl, {
			draggable: '.item.menu',
			handle: '.item .grabber',
			store: {
				/**
				 * Get the order of elements. Called once during initialization.
				 * @param   {Sortable}  sortable
				 * @returns {Array}
				 */
				get: function (sortable) {					
					return reorder(items);
				},

				/**
				 * Save the order of elements. Called onEnd (when the item is dropped).
				 * @param {Sortable}  sortable
				 */
				set: function (sortable) {					
					let newOrder = sortable.toArray()
					let newItems = []

					for (let key of newOrder) {			
						newItems.push(items.find(b => b.id === key))
					}

					reorder(newItems)
				}
			}
		})
    })
    
    function addModal () {		
        dispatch('addItem', parent)
    }
</script>

<div class="items" bind:this={itemsEl}>
    {#each items as item(item.id)}
        <MenuItem {item} on:openSettings on:addChild />
    {/each}
    {#if showAddItem}
    <div class="item add" on:click={addModal}>
		<div class="name">
			Add new item
		</div>
		<div class="icon add">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 15 5 L 15 15 L 5 15 L 5 17 L 15 17 L 15 27 L 17 27 L 17 17 L 27 17 L 27 15 L 17 15 L 17 5 Z"/></svg>
		</div>
	</div>
    {/if}
</div>

<style>
.items {
	display: grid;
	grid-row-gap: 15px;
    transition: height 150ms ease;
}

.item.add {
	border: 1px solid #ddd; 
	display: grid;
	grid-template-columns: 1.5em auto 1.5em 1.5em;
	background: #fafafa;
	padding: 10px 10px;		
	align-items: center;
	width: 40%;
	grid-column-gap: 10px;
	cursor: pointer;
}

.item.add .name {
	grid-column: 1 / 4;
}</style>