<script>
    import LoaderIcon from '../../../components/LoaderIcon.svelte'    
    import Btn from '../../../components/Btn.svelte'
    import { onMount } from 'svelte'
    import { tweened } from 'svelte/motion'
    import { cubicOut } from 'svelte/easing'
    import { createEventDispatcher } from 'svelte'
    const dispatch = createEventDispatcher()

    export let rejected = false
    export let name
    export let file
    export let type = 'media'
    export let uploading = false
    export let completed = false
    export let progress = 0
    export let conflict = false

    let preview = null
    let progressBar = null

    $: status = checkStatus(uploading, conflict, completed, rejected)
    $: setProgress(progress)
    $: setProgress(uploading)

    onMount(() => {
        previewFile()
        setProgress(progress)
    })

    function checkStatus () {
        if (rejected !== false) {
            return 'Rejected: ' + rejected
        }
        else if (uploading === true) {
            return 'Uploading'
        } else if (conflict !== false && conflict.status !== 'duplicate') {
            return 'Conflict'
        } else if (completed === true) {
            return 'Completed'
        } else {
            return 'Pending'
        }
    }

    function setProgress() {
        if (progressBar !== null) {
            progressBar.style.width = (completed === true ? 100 : progress) + '%'
        }
    }

    function previewFile () {
        if (type === 'application/pdf') return
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
            preview = reader.result
        }
    }

    function replaceFile () {
        dispatch('replaceFile', {
            file
        })
    }

    function newFile () {
        dispatch('newFile', {
            file
        })
    }
</script>
<div class="file" class:rejected={rejected} class:accepted={rejected === false}>
    <div class="thumbnail">
        {#if type === 'file'}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 6 3 L 6 29 L 26 29 L 26 9.59375 L 25.71875 9.28125 L 19.71875 3.28125 L 19.40625 3 Z M 8 5 L 18 5 L 18 11 L 24 11 L 24 27 L 8 27 Z M 20 6.4375 L 22.5625 9 L 20 9 Z M 11 13 L 11 15 L 21 15 L 21 13 Z M 11 17 L 11 19 L 21 19 L 21 17 Z M 11 21 L 11 23 L 21 23 L 21 21 Z"/></svg>
        {:else if preview === null}
            <LoaderIcon />
        {:else}
            <img src={preview} alt={name} />
        {/if}        
    </div>

    <div class="info">
        <div class="name">
            {#if conflict !== false && conflict.status !== 'duplicate'}
                File already exists             
            {:else}
                {name}
            {/if}
        </div>

        <div class="status">
            {#if conflict !== false}
                {conflict.message}
            {:else}
                {status}
            {/if}            
        </div> 
        
        {#if uploading === false && completed === false && conflict === false && rejected === false}
            <LoaderIcon />
        {:else if uploading === true}
            <div class="progress">
                <div class="bar" bind:this={progressBar}></div>
            </div>    
        {:else if conflict !== false}
            <div class="actions">
                {#if conflict.status === 'duplicate-name'}
                    <Btn warning on:click={replaceFile}>
                        Update existing file &quot;{conflict.media.name}&quot;
                    </Btn>
                {/if}
                
            </div>   
        {/if}
    </div>  

    <div class="icon" class:rejected={rejected !== false || (conflict !== false && conflict.status !== 'duplicate')}>
        {#if rejected !== false}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>
        {:else if conflict !== false && conflict.status !== 'duplicate'}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 3.21875 L 15.125 4.71875 L 3.125 25.5 L 2.28125 27 L 29.71875 27 L 28.875 25.5 L 16.875 4.71875 Z M 16 7.21875 L 26.25 25 L 5.75 25 Z M 15 14 L 15 20 L 17 20 L 17 14 Z M 15 21 L 15 23 L 17 23 L 17 21 Z"/></svg>
        {:else if completed === true || (conflict !== false && conflict.status === 'duplicate') }
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
        {/if}
    </div>  
</div>

<style>
.file {
  display: grid;
  grid-template-columns: 125px auto 50px;
  grid-column-gap: 15px;
}

.thumbnail {
  width: 125px;
  height: 125px;
  background: #f7f7f7;
  border: #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  grid-column: 1/2;
}

.thumbnail img {
  max-width: 100%;
}

.thumbnail svg {
    width: 70px;
}

.thumbnail svg path {
    fill: #9e9e9e;
}

.info {
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
}

.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
}

.name {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
}

.status {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 10px;
  flex-grow: 1;
}

.progress {
  width: 100%;
  height: 15px;
  border: 1px solid #ececec;
}

.progress .bar {
  background: #4CAF50;
  height: 100%;
  width: 0;
  transition: width 300ms linear;
}

.icon {
  grid-column: 3/4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon svg {
  width: 100%;
  height: auto;
}

.icon svg path {
  fill: #4caf50;
}

.icon.rejected svg path {
  fill: #f44336;
}

.icon.warning svg path {
  fill: #ffa72d;
}</style>