<script>
    import Btn from '../../../components/Btn.svelte'
    import ImageEditor from '../components/ImageEditor.svelte'
    import { mediaLibrary } from '../stores/media-library.store.js'
    import { onMount } from 'svelte'

    export let content = {
        image: {
            media: null,
            alt: null,
            crop: null
        }
    };

    export let properties;

    let imageEditor = false

    $: content = isNull(content, {
        image: {
            media: null,
            alt: null,
            crop: null
        }
    })

    $: properties = isNull(properties, {
        container: false
    })

    function isNull (current, fallback) {
        return current === null ? fallback : current
    }
    
    $: setSelectedMedia($mediaLibrary.selected, $mediaLibrary.attached)

    $: preview = updatePreview(content.image) 

	
	function updatePreview () {
        if (content.image.media === null) return
        if (content.image.crop === null || content.image.crop === undefined) return content.image.media.url
        let crop = content.image.crop
		let params = []
		
        params.push('tl_px=' + crop.x + ',' + crop.y)
        params.push('w=' + crop.width)
        params.push('h=' + crop.height)

        return content.image.media.url + (params.length > 0 ? '?' : '') + params.join('&')
    }

    function openMediaLibrary () {
        mediaLibrary.setMaxSelection(1)
        mediaLibrary.clearSelected()
        mediaLibrary.open(true)
    }

    function setSelectedMedia (selected, attached) {
        if (attached === false) return

        content.image.media = selected[0]
        content.image.alt = selected[0].name

        mediaLibrary.clearSelected()
    }

    function openImageEditor () {
        imageEditor = content.image
    }

    function closeImageEditor (event) {
        content.image = event.detail
        imageEditor = false
    }
</script>

<div class="banner">
    {#if content.image.media === null}
    <div class="find">
        <Btn green on:click={openMediaLibrary}>
            Select Image
        </Btn>
    </div>
    {:else}
    <div class="thumbnail">
        <img src={preview} alt={content.image.alt} />
    </div>
    <div class="info">
        <div class="name">
            {content.image.media.name}
        </div>
        
        <div class="metas">
            {#each content.image.media.meta as item}
                <div class="meta">
                    {item.label}: {item.value}
                </div>
            {/each}
        </div>
        <div class="spacer"></div>
        <div class="actions">
            <Btn blue on:click={openImageEditor}>
                Crop
            </Btn>

            <Btn green on:click={openMediaLibrary}>
                Replace
            </Btn>
        </div>
    </div>
    {/if}
</div>

{#if imageEditor}
    <ImageEditor bind:image={imageEditor} on:close={closeImageEditor} />
{/if}

<style>
.banner {
    display: grid;
    grid-column-gap: 15px;
    grid-template-columns: 150px auto;
}

.thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f6f6;
    border: 1px solid #ddd;
}

.thumbnail img {
    max-width: 100%;
}

.info {
    display: flex;
    flex-direction: column;
}

.spacer {
    flex-grow: 1;
}

.name {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 10px;
}

.metas {
    font-size: 16px;
    color: #666;
    font-weight: 300;
    margin-bottom:  10px;
}

.actions {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 15px;
}</style>