<script>
    import LoaderIcon from '../../components/LoaderIcon.svelte'
    import Card from '../../components/Card.svelte'
    import { user } from '../../stores/user.store.js'
    import { goto } from '@sveltech/routify'

    let error = false
    user.logout()
        .then(() => $goto('/'))
        .catch((e) => {
            error = e
        })        
    
</script>

<div class="wrapper">
    <Card title="Logging out">
        <div class="content">
        {#if error === false}
            <LoaderIcon size="100px" />
        {:else}
            {error.message}            
        {/if}
        </div>
    </Card>
</div>

<style>
.wrapper {
    margin-top: 5vh;
    width: 25%;
}

.content {
    margin: 50px;
}</style>