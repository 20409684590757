<script>
    export let type = 'button'
    export let tag = 'button'
    export let href = undefined
    export let title = undefined
    export let loading = false
    export let disabled = false
    export let block = false
    export let green = false
    export let blue = false
    export let warning = false
    export let red = false
    export let pill = false
    export let orange = false
    export let flash = false
    import LoaderIcon from './LoaderIcon.svelte'
</script>

{#if tag === 'a'}
    <a class="btn" class:pill={pill} class:red={red} class:orange={orange} class:warning={warning} class:green={green} class:blue={blue} class:block={block} class:flash {disabled} {href} {title}>
        {#if loading === false}
            <slot></slot>
        {:else}
            <slot name="loader">
                <LoaderIcon />
            </slot>
        {/if} 
    </a>
{:else}
    <button class="btn" class:pill={pill} class:red={red} class:orange={orange} class:warning={warning} class:green={green} class:blue={blue} class:block={block} class:flash on:click {disabled} {type}>
        {#if loading === false}
            <slot></slot>
        {:else}
            <slot name="loader">
                <LoaderIcon color1="#FFF" color2="#FFF" color3="#FFF" />
            </slot>
        {/if} 
    </button>
{/if}

<style>
.btn {
    background: #58595b;
    color: #FFF;
    transition: all 150ms ease-in-out;
    padding: 12px 12px;
    border: none;
    border-radius: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    opacity: 1;
    font-size: 16px;
    line-height: 1em;
    box-sizing: border-box;
    position: relative;
    width: auto;
}    

.btn.pill {
    padding: 5px 10px;
    line-height: 1;
    border-radius: 1.1em;
}    

.btn.block {
    display: flex;
    width: 100%;
}    

.btn:hover {
    background: #ee7b00;
    color: #FFF;
}    

.btn:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}    

.btn.green {
    background: #4CAF50;        
}    

.btn.green:hover {
    background: #ee7b00;
    color: #FFF;
}    

.btn.blue {
    background: #2196f3;
}    

.btn.blue:hover {
    background: #ee7b00;
    color: #FFF;
}    

.btn.red {
    background: #f44336;
}    

.btn.red:hover {
    background: #ee7b00;
    color: #FFF;
}    

.btn.warning {
    background: #ffa72d;
}    

.btn.warning:hover {
    background: #ee7b00;
    color: #FFF;
}    

.btn.orange {
    background: #ee7b00;
    color: #FFF;
}    

.btn.orange:hover {
    background: #868686;
    color: #FFF;
}    

.btn.flash {
    animation: flash 750ms infinite;
}    

@keyframes flash {
    0% {
        background: #000;
    }
    50% {
        background: #f44336;
    }
    100% {
        background: #000;
    }
}    

@media (max-width: 767px) {
    .btn {
        padding: 10px 10px;
    }
}</style>