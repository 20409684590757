<svelte:options immutable={true}/>

<script>
    import Quill from 'quill'    
    import { onMount } from 'svelte'
    import ReadmoreBlot from './formats/Readmore.js'

    export let content = [{insert: "\n"}]
    export let properties = []
    export let reset = false
    export let readmore = false

    let editor 
    let element
        
    function hasProperty(key) {
        if (properties === undefined || properties === null || properties.length === 0) return false
        let quill = properties.find(p => p.key === 'quill')        
        if (quill === undefined) return false        

        return quill.include.includes(key)
    }

    let container = [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

            [{ 'align': [] }, 'blockquote', 'code-block', { 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            ['link'],


            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme        
    ]

    let toolbar = {
        container,
        handlers: {}
    }

    if (readmore) {      
        Quill.register(ReadmoreBlot);
        container.push(['readmore'])

        toolbar.handlers.readmore = function (value) {
            let range = this.quill.getSelection(true);
            this.quill.insertText(range.index, '\n', Quill.sources.USER);
            this.quill.insertEmbed(range.index + 1, 'readmore', true, Quill.sources.USER);
            this.quill.setSelection(range.index + 2, Quill.sources.SILENT);
        }
    }
        
    container.push(['clean'])

    function checkContent(content) {
        if (typeof content !== 'object') return content
        if (content.length === 0) {
            return {
                ops: [
                    {insert: "\n"}
                ]
            }
        }

        return {
            ...content,                
            ops: content.ops.map(o => {
                if (o.insert !== undefined && o.insert === null) o.insert = '\n'
                return o
            })
        }        
    }

    $: resetContent(reset)

    function resetContent () {
        if (editor === undefined) return
        editor.setContents(checkContent(content))
    }

    onMount(() => {                
        editor = new Quill(element, {
           placeholder: 'Compose an epic...',
           theme: 'snow',
           modules: {
                toolbar                
           }
        })
                
        editor.setContents(checkContent(content))

        editor.on('text-change', function (delta, oldDelta, source) {            
            content = checkContent(editor.getContents())
        })
    })    
</script>

<svelte:head>
    <link href="//cdn.quilljs.com/1.3.7/quill.core.css" rel="stylesheet">
    <link href="//cdn.quilljs.com/1.3.7/quill.snow.css" rel="stylesheet">
</svelte:head>

<div class="editor" bind:this={element}>
    
</div>

<style>
   .editor {
       min-height: 10em;
       height: auto;
   }

   .editor :global(hr){
       border: none;
       border-top: 1px solid #ccc;
       border-bottom: 1px solid #ccc;
       color: #111;
       letter-spacing: 0.5em;
       font-size: 1.2em;
       text-align: center;
       position: relative;
       height: 1.7em;
       margin: 0 0 0.75em 0;
   }

   .editor :global(hr:after) {
       content: 'Read more';
       background: #fff;
       display: flex;
       align-items: center;
       justify-content: center;
       width: 100%;
       height: 100%;
   }

   .editor :global(p) {
	margin-bottom: 0.75em;
}

   .editor :global(p[linebreak]) {
		margin-bottom: 0;
}

   :global(.ql-toolbar .ql-formats .ql-readmore) {
       background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 39 39'%3E%3Cpath stroke='%23000' stroke-width='2' d='M5.5 4.69h28M5.5 8.69h28M5.5 12.69h19'/%3E%3Ccircle cx='10.5' cy='19.69' r='2'/%3E%3Ccircle cx='19.5' cy='19.69' r='2'/%3E%3Ccircle cx='28.5' cy='19.69' r='2'/%3E%3Crect width='38' height='38' x='.5' y='.5' fill='none' stroke='%23000' rx='2'/%3E%3Cpath stroke='%23000' stroke-width='2' d='M5.5 25.69h28M5.5 29.69h28M5.5 33.69h19'/%3E%3C/svg%3E");
       background-repeat: no-repeat;
       background-size: contain;
   }</style>